import {
  Box,
  FadeIn,
  MultiColorIcon,
  Paragraph,
  useBrandTheme,
} from '@hl-portals/ui';

import { BbysSidebarLogo } from './BbysSidebarLogo';

const LeadInformationSidebar = () => {
  const brandTheme = useBrandTheme();

  return (
    <>
      <BbysSidebarLogo />
      <Box height="100%" maxWidth="520px" m="16px auto 0">
        <FadeIn
          flexDirection="column"
          animationDelay="0.1s"
          p={{ xs: '', md: '0px 40px 0px 80px' }}
        >
          <Paragraph variant="text" color="gray600">
            Submit your client to get an Equity Unlock Estimation and start
            their approval process - No cost or commitment required.
          </Paragraph>
        </FadeIn>
        <FadeIn
          position="absolute"
          bottom="0px"
          display={{ xs: 'none', md: 'flex' }}
          animationDelay="0.3s"
          width="100%"
          justifyContent="center"
        >
          <MultiColorIcon.House1
            colors={brandTheme.colors || {}}
            containerProps={{ maxHeight: '325px' }}
          />
        </FadeIn>
      </Box>
    </>
  );
};

export default LeadInformationSidebar;
