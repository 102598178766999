import { useSaveFlowRepInfo } from 'apps/equity-app/hooks/useSaveFlowRepInfo';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

const LOCAL_STORAGE_SAVE_LO_ASSISTANT_INFO_KEY =
  'lead-submission-save-lo-assistant-info';

export const AddAssistantInfo = () => {
  const { updateStoredValues, clearStoredValues, storedValues } =
    useSaveFlowRepInfo(LOCAL_STORAGE_SAVE_LO_ASSISTANT_INFO_KEY, {});

  const { logLeadSubmissionEvent, onUpdateSnapshot, coreEventData } =
    useLeadSubmissionContext();

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    loan_officer_assistant_name,
    loan_officer_assistant_email,
    loan_officer_assistant_phone,
    save_loan_officer_assistant_info,
  } = watch();

  const handleNext = () => {
    if (save_loan_officer_assistant_info) {
      updateStoredValues({
        loan_officer_assistant_name,
        loan_officer_assistant_email,
        loan_officer_assistant_phone,
        save_loan_officer_assistant_info,
      });
    } else {
      clearStoredValues();
    }

    const payload = {
      loan_officer_assistant_name: loan_officer_assistant_name ?? '',
      loan_officer_assistant_email: loan_officer_assistant_email ?? '',
      loan_officer_assistant_phone: loan_officer_assistant_phone ?? '',
      save_loan_officer_assistant_info:
        save_loan_officer_assistant_info ?? false,
    };

    logLeadSubmissionEvent({
      event_action: 'save_loan_officer_assistant_info',
      event_payload: { ...coreEventData, ...payload },
    });

    onUpdateSnapshot(payload);
  };

  const someFieldsHaveErrors: boolean = [
    errors?.loan_officer_assistant_name?.message,
    errors?.loan_officer_assistant_email?.message,
    errors?.loan_officer_assistant_phone?.message,
  ].some(Boolean);

  const areAnyFieldsTouched: boolean = [
    loan_officer_assistant_name,
    loan_officer_assistant_email,
    loan_officer_assistant_phone,
    save_loan_officer_assistant_info,
  ].some(Boolean);

  const areAllReqInputsValid: boolean = [
    loan_officer_assistant_name,
    loan_officer_assistant_email,
    loan_officer_assistant_phone,
  ].every(Boolean);

  const disable =
    someFieldsHaveErrors || (areAnyFieldsTouched && !areAllReqInputsValid);

  useEffect(() => {
    if (storedValues?.save_loan_officer_assistant_info) {
      setValue(
        'loan_officer_assistant_name',
        storedValues?.loan_officer_assistant_name
      );
      setValue(
        'loan_officer_assistant_email',
        storedValues?.loan_officer_assistant_email
      );
      setValue(
        'loan_officer_assistant_phone',
        storedValues?.loan_officer_assistant_phone
      );
      setValue(
        'save_loan_officer_assistant_info',
        storedValues?.save_loan_officer_assistant_info
      );
    }
  }, []);

  return (
    <>
      <Content>
        <Container
          title="Add an Assistant or Processor"
          subtitle="(Optional) You can also add it after completing the client’s application."
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Inputs.Text
                name="loan_officer_assistant_name"
                label="Full name"
                placeholder="Enter assistant or processor’s full name"
                mode="onBlur"
              />
              <Inputs.Email
                name="loan_officer_assistant_email"
                label="Email Address"
                placeholder="assistant@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name="loan_officer_assistant_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                mode="onBlur"
              />
              <Inputs.Checkbox name="save_loan_officer_assistant_info">
                <Paragraph variant="text" textAlign="left">
                  Save team member for future transactions
                </Paragraph>
              </Inputs.Checkbox>
            </Box>
          </Box>
          <Alert
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
            mt={{ xs: '24px', md: '40px' }}
          >
            We will include the team member in email communications so they can
            assist with the transaction.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disable} />
      </Footer>
    </>
  );
};
