import { Box, MultiColorIcon, Paragraph, useBrandTheme } from '@hl-portals/ui';

import { BbysSidebarLogo } from './BbysSidebarLogo';

const ProgramEligibilityCheckSidebar = () => {
  const brandTheme = useBrandTheme();

  return (
    <>
      <BbysSidebarLogo />
      <Box mt="16px" height="100%">
        <Box
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          gap="16px"
          flexDirection="column"
          mt="15%"
        >
          <MultiColorIcon.RadioSelect colors={brandTheme.colors} />
          <Paragraph
            variant="text-small"
            textTransform="uppercase"
            color="coolGray3"
          >
            Eligibility Criteria
          </Paragraph>
        </Box>
      </Box>
    </>
  );
};

export default ProgramEligibilityCheckSidebar;
