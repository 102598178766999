import styled, { css } from 'styled-components';

import { Box, BoxTypes, Paragraph } from '@hl-portals/ui';
import { useFormContext } from 'react-hook-form';

const SquaredRadioStyles = styled(Box)`
  --border-color: #dbdfe6;

  width: 100%
  flex: 1;

  .option {
    flex: 1;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    border-right: none;
    cursor: pointer;
  }

  .option:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }

  .option:last-of-type {
    border-right: 1px solid var(--border-color);
    border-radius: 0px 4px 4px 0px;
  }

  .option--active {
    font-weight: bold;
    border: 1px solid #46b6ff !important;
    background-color: #f2f9fe;
  }
`;

type SquaredRadioProps = {
  name: string;
  label?: string;
  note?: string;
  options: { label: string; value: string }[];
} & BoxTypes;

export const SquaredRadio = ({
  name,
  label,
  note,
  options,
  ...props
}: SquaredRadioProps) => {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  return (
    <Box as="label" flex="1" flexDirection="column" gap="16px" {...props}>
      {label && (
        <Box>
          <Paragraph>{label}</Paragraph>
          {note && <Paragraph>{note}</Paragraph>}
        </Box>
      )}
      <SquaredRadioStyles>
        {options.map((option) => {
          const isActive = option.value === value;
          const className = `option ${isActive ? 'option--active' : ''}`;

          return (
            <Box
              key={option.value}
              className={className}
              onClick={() => setValue(name, option.value)}
            >
              {option.label}
            </Box>
          );
        })}
      </SquaredRadioStyles>
    </Box>
  );
};
