import { useFlags } from 'flagsmith/react';

import { useRouter } from 'next/router';

import {
  BrandThemeProvider,
  SnapshotProvider,
  useBrandTheme,
} from '@hl-portals/ui';

import BbysLeadSubmissionPage from '../../../components/Bbys/LeadSubmission';
import { getFlow } from '../../../components/Bbys/LeadSubmission/constants';
import { LeadSubmission } from '../../../modules/lead-submission/pages/lead-submission';

const BbysSnapshotPage = (): React.ReactElement => {
  const router = useRouter();
  const flow = getFlow(router);
  const brandTheme = useBrandTheme();

  const page = (
    <SnapshotProvider
      type="LenderLeadSnapshot"
      initialData={{ submitted_step: '', flow }}
    >
      <BbysLeadSubmissionPage />
    </SnapshotProvider>
  );

  if (!brandTheme.colors?.primary) {
    return (
      <BrandThemeProvider theme={{ colors: {} }}>{page}</BrandThemeProvider>
    );
  } else {
    return page;
  }
};

export default function () {
  const {
    'equity-lead-submission-v3': { enabled },
  } = useFlags(['equity-lead-submission-v3']);

  if (enabled) {
    return <LeadSubmission />;
  }
  return <BbysSnapshotPage />;
}
