import { AutomatedBbysPropertyDenialChecks } from 'packages/hooks/src/useAutomatedBbysEligibilityCheck/automated-property_eligibity-lender';

import Link from 'next/link';

import {
  Alert,
  Box,
  BoxTypes,
  FadeIn,
  Icon,
  IndentedText,
  MultiColorIcon,
  Paragraph,
} from '@hl-portals/ui';

import { AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_DENIALS_LABELS } from '@hl-portals/hooks';

import { useStepUtils } from '../hooks';

const ExternalLink = ({ children, href }) => (
  <Link href={href} passHref target="_blank">
    <Box as="a" gap="8px">
      <Paragraph as="span" color="electricBlue">
        {children}
      </Paragraph>
      <Icon type="externalLink" fill="electricBlue" />
    </Box>
  </Link>
);

const Container = ({ children, ...props }: BoxTypes) => (
  <Box width="100%" maxWidth="1280px" m="0 auto" p="0 16px" {...props}>
    {children}
  </Box>
);

export const EligibilityDenialInformation = (): React.ReactElement => {
  const { formState } = useStepUtils('eligibility_denial_information');

  const checks = (formState.eligibility_result.denial_checks ||
    {}) as AutomatedBbysPropertyDenialChecks;
  const isUnavailableState = checks.ineligible_state_check;

  const fullAddress = [
    formState.property_address,
    formState.property_unit,
    `${formState.property_city} ${formState.property_state} ${
      formState.property_zip ?? ''
    }`,
  ]
    .filter(Boolean)
    .join(', ');

  const renderDenails = () => {
    if (isUnavailableState) {
      const label =
        AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_DENIALS_LABELS.ineligible_state_check(
          formState.property_state
        );

      return (
        <>
          <IndentedText indentation="8px">{label}</IndentedText>
          <Paragraph>
            We will notify you at{' '}
            <Paragraph as="span" fontWeight="700">
              {formState.loan_officer_email}{' '}
            </Paragraph>
            when Buy Before You Sell program is available in your area!
          </Paragraph>
        </>
      );
    }

    return Object.entries(checks).map(
      ([key, value]: [keyof AutomatedBbysPropertyDenialChecks, boolean]) => {
        if (!value) return <></>;

        const label = AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_DENIALS_LABELS[key];

        let labelStr = '';

        if (key === 'ineligible_zip_check') {
          labelStr = label(formState.property_postal_code);
        } else {
          labelStr = label();
        }

        return (
          <IndentedText key={labelStr} indentation="8px">
            {labelStr}
          </IndentedText>
        );
      }
    );
  };

  return (
    <FadeIn flexDirection="column">
      <Box bgcolor="#F2F9FE" mb="32px" p={{ xs: '80px 0', sm: '0' }}>
        <Container
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          gap="32px"
        >
          <Box width="100%" maxWidth="520px" flexDirection="column" gap="16px">
            <Paragraph variant="heading-3">
              {isUnavailableState
                ? 'Sorry, we are not available in your area'
                : 'Sorry, the property is not eligible'}
            </Paragraph>
            <Paragraph variant="text" color="coolGray1">
              The departing residence {fullAddress} doesn’t meet HomeLight’s Buy
              Before You Sell eligibility criteria
            </Paragraph>
          </Box>

          <Box display={{ xs: 'none', md: 'block' }}>
            <MultiColorIcon.House3 />
          </Box>
        </Container>
      </Box>

      <Container
        pb={{ xs: '80px', md: '0' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        gap="32px"
      >
        <Box
          maxWidth="620px"
          mt="-92px"
          p="32px 24px"
          flexDirection="column"
          borderRadius="12px"
          boxShadow="0px 14px 48px 0px #6C81AB33"
          bgcolor="white"
        >
          <Paragraph fontWeight="600" mb="12px">
            Why is the property not eligible?
          </Paragraph>

          <Box flexDirection="column" gap="12px">
            {renderDenails()}
            <Alert variant="info">
              <Paragraph as="span" variant="text-small" color="#273653">
                If this information is not accurate email us to discuss your
                situation in detail at
              </Paragraph>{' '}
              <Link href="mailto:lenders@homelight.com">
                lenders@homelight.com
              </Link>
            </Alert>
          </Box>
        </Box>

        <Box
          minWidth={{ xs: '100%', md: '400px' }}
          flexDirection="column"
          border="1px solid #DBDFE6"
          borderRadius="16px"
          overflow="hidden"
          bgcolor="white"
        >
          <Box p="24px 32px" gap="4px" borderBottom="1px solid #DBDFE6">
            <Icon type="questionCircleLine" />
            <Paragraph fontWeight="600">Need help?</Paragraph>
          </Box>
          <Box flexDirection="column" p="32px" gap="40px">
            <ExternalLink href="https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements">
              Eligibility Requirements
            </ExternalLink>
            <ExternalLink href="https://help.homelight.com/lender-frequently-asked-questions">
              FAQs
            </ExternalLink>
            <ExternalLink href="mailto:lenders@homelight.com">
              Contact us
            </ExternalLink>
          </Box>
        </Box>
      </Container>
    </FadeIn>
  );
};
