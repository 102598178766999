import styled from 'styled-components';

import { useMemo } from 'react';
import { useFlags } from 'flagsmith/react';

import { toast } from 'react-toastify';

import {
  Box,
  Button,
  FadeIn,
  Icon,
  IconTypeProp,
  Paragraph,
  useBrandTheme,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import { useStepUtils } from '../../hooks';

type NextStepItemProps = {
  icon: IconTypeProp;
  heading: InterpolatedContent;
  label?: string;
  description: InterpolatedContent;
  note?: InterpolatedContent;
  href?: string;
  hrefText?: string;
  type?: 'primary' | 'secondary';
  animationDelay?: string;
  showOpenLinkButton?: boolean;
};

const RoundedButton = styled(Button)`
  border-radius: 20px;
`;

const NextStepItem = ({
  icon,
  heading,
  description,
  label,
  note,
  href,
  type = 'primary',
  animationDelay = '0s',
  showOpenLinkButton = true,
  hrefText,
}: NextStepItemProps) => {
  const isPrimary = type === 'primary';

  const brandTheme = useBrandTheme();

  const copyLink = (url) => () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(url).then(() => {
        toast('Link successfully copied to clipboard!', {
          type: 'success',
        });
      });
    }
  };

  const iconColor = brandTheme?.colors?.primary || theme.colors.electricBlue;

  return (
    <FadeIn
      animationDelay={animationDelay}
      p="10px 10px 16px 10px"
      borderRadius="28px"
      gap="14px"
      bgcolor={theme.colors.white}
      flexDirection="column"
      boxShadow="0px 3px 16px 0px rgba(108, 129, 171, 0.20)"
      width="100%"
      minWidth={{ xs: '100%', md: '620px' }}
    >
      <Box flexDirection="column">
        <Box
          p="24px 28px"
          borderRadius="18px 18px 0px 0px"
          bgcolor={brandTheme.colors?.secondary || theme.colors.mistBlue}
          flexDirection="column"
          gap="20px"
        >
          <Box
            gap="12px"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Box alignItems="center" gap="12px">
              <Box
                width="42px"
                height="42px"
                justifyContent="center"
                alignItems="center"
                bgcolor={theme.colors.white}
                borderRadius="4px"
              >
                <Icon type={icon} fill={iconColor} color={iconColor} />
              </Box>
              <Paragraph variant="heading-4">{heading}</Paragraph>
            </Box>

            {label && (
              <Box
                bgcolor={theme.colors.lightBlue}
                p="3px 6px"
                borderRadius="16px"
              >
                <Paragraph
                  variant="text-xsmall"
                  color="blue700"
                  textTransform="uppercase"
                  fontWeight="600"
                  letterSpacing="1px"
                >
                  {label}
                </Paragraph>
              </Box>
            )}
          </Box>
          <Paragraph
            variant="text"
            color="gray700"
            maxWidth="540px"
            lineHeight="150%"
          >
            {description}
          </Paragraph>
        </Box>
        {note && (
          <Box
            p="12px"
            borderRadius="0px 0px 12px 12px"
            bgcolor={isPrimary ? theme.colors.warningLight : theme.colors.white}
            alignItems="center"
            gap="8px"
            color={isPrimary ? theme.colors.orange800 : theme.colors.yellow800}
          >
            <Icon
              type="infoSolid"
              fill={isPrimary ? theme.colors.orange600 : theme.colors.yellow600}
              color={
                isPrimary ? theme.colors.orange600 : theme.colors.yellow600
              }
              size={16}
            />
            {note}
          </Box>
        )}
      </Box>
      {href && (
        <Box gap="16px" justifyContent="flex-end" p="0 12px">
          {showOpenLinkButton ? (
            <>
              <Button
                iconRight={
                  <Icon type="externalLinkSolid" color="aaBlue" size={16} />
                }
                onClick={() => window.open(href, '_blank')}
                variant="text"
              >
                <Paragraph color="aaBlue" variant="text-small" fontWeight="400">
                  Open link
                </Paragraph>
              </Button>
              <RoundedButton
                onClick={copyLink(href)}
                size="large"
                p="0 24px"
                height="40px"
              >
                {hrefText || 'Copy link'}
              </RoundedButton>
            </>
          ) : (
            <>
              <RoundedButton
                onClick={() => window.open(href, '_blank')}
                size="large"
                p="0 24px"
                height="40px"
              >
                {hrefText || 'Open link'}
              </RoundedButton>
            </>
          )}
        </Box>
      )}
    </FadeIn>
  );
};

export const NextSteps = ({
  type,
}: {
  type: 'express' | 'light' | 'instant' | 'mortgage' | 'cash';
}) => {
  const {
    'remove-property-questionnaire-bbys': {
      enabled: removePropertyQuestionnaireBbys,
    },
  } = useFlags(['remove-property-questionnaire-bbys']);
  const { formState } = useStepUtils();
  const propertyUploadTaskLink = `${config.agentDashboardUrl}/public/tasks/bbys_submit_property_photos?token=${formState.submit_response?.token}`;
  const propertyQuestionnaireLink = useMemo(
    () =>
      `https://homelight.typeform.com/to/yVpL70jE?typeform-source=${window.location.host}#task_id=${formState.submit_response?.property_questionnaire_task_id}`,
    [formState.submit_response]
  );

  const agentExists = !!formState.agent_email;
  const lenderExists =
    !!formState.lender_email || !!formState.loan_officer_email;

  const stepsMap = {
    express: [
      {
        icon: 'images',
        heading: 'Property photos',
        label: 'Assigned to agent',
        description:
          "Upload recent photos of the departing residence to help us evaluate the property's condition.",
        note: agentExists
          ? `We notified the agent at ${formState.agent_email}`
          : 'Copy and share the link with the agent to upload photos',
        href: propertyUploadTaskLink,
        type: agentExists ? 'secondary' : 'primary',
      },
      {
        icon: 'tasks',
        heading: 'Property questionnaire',
        label: 'Assigned to agent',
        description:
          'Provide listing and renovation information about the property. We recommend completing this form after the agent has visited the property.',
        note: agentExists
          ? `We notified the agent at ${formState.agent_email}`
          : 'Copy and share the link with the agent to complete the property questionnaire',
        href: propertyQuestionnaireLink,
        type: agentExists ? 'secondary' : 'primary',
      },
    ],
    light: [
      {
        icon: 'search',
        heading: 'HomeLight is reviewing the application',
        description:
          'We have all the information we need and our team is reviewing the client application.',
        note: "We'll be in contact within 24 hours to go over next steps",
        type: 'secondary',
      },
    ],
    instant: [
      {
        icon: 'fileSignature',
        heading: 'Sign program agreement',
        label: 'Assigned to lender',
        description:
          'Work with your client to review and sign the program agreement.',
        note: "We'll be in contact within 24 hours to go over next steps",
        type: 'secondary',
      },
    ],
    mortgage: [
      {
        icon: 'landmarkMagnifyingGlass',
        heading: 'Financial details',
        label: 'Assigned to lender',
        description:
          "We need additional financial information from the lender to asses the client's eligibility and to determine the final equity unlock amount.",
        note: lenderExists
          ? `We notified the lender at ${
              formState.lender_email
                ? formState.lender_email
                : formState.loan_officer_email
            }`
          : "We'll contact you to connect your client with a lender",
        type: 'secondary',
      },
    ],
    cash: [
      {
        icon: 'images',
        heading: 'Upload Property photos',
        label: 'Assigned to agent',
        description:
          "Upload recent photos of the departing residence to help us evaluate the property's condition.",
        note: 'Complete this task to start the application review',
        href: propertyUploadTaskLink,
        hrefText: 'Upload photos',
        showOpenLinkButton: false,
        type: 'secondary',
      },
      {
        icon: 'tasks',
        heading: 'Property questionnaire',
        label: 'Assigned to agent',
        description:
          'Provide listing and renovation information about the property. We recommend completing this form after the agent has visited the property.',
        note: 'Complete this task to start the application review',
        href: propertyQuestionnaireLink,
        hrefText: 'Start questionnaire',
        showOpenLinkButton: false,
        type: 'secondary',
      },
    ],
  } as Record<any, NextStepItemProps[]>;

  if (removePropertyQuestionnaireBbys) {
    // Iterate over each step type
    for (const key in stepsMap) {
      if (Object.prototype.hasOwnProperty.call(stepsMap, key)) {
        const steps = stepsMap[key];
        // Filter out property questionnaire steps from the steps array
        stepsMap[key] = steps.filter(step => step.heading !== 'Property questionnaire');
      }
    }
  }

  return (
    <Box flexDirection="column" gap="32px" width="100%">
      <Paragraph variant="heading-4">Next Steps</Paragraph>
      {stepsMap[type].map((stepInfo, idx) => (
        <NextStepItem
          key={`${type}-${idx}`}
          {...stepInfo}
          animationDelay={`${0.2 + idx * 0.2}s`}
        />
      ))}
    </Box>
  );
};

export default NextSteps;
