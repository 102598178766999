import { CurrentFlow, SubmissionResponse } from '../../metadata/types';

type SubmissionAttributes = SubmissionResponse['data']['attributes'];

type GetSubmitResponseArgs = {
  result?: SubmissionAttributes;
  current_flow: CurrentFlow;
  finance_type: string;
};

export function getSubmitResponse({
  result = {} as SubmissionAttributes,
  current_flow,
  finance_type,
}: GetSubmitResponseArgs) {
  if (current_flow === 'lender') {
    return result;
  }

  if (current_flow === 'agent') {
    if (finance_type === 'cash') {
      return {
        success: true,
        type: 'all_cash_bbys',
        ...result,
      };
    }
    return {
      success: true,
      type: 'pre_lead',
    };
  }
}
