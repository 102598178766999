import Lottie from 'react-lottie-player';

import {
  Box,
  Icon,
  Paragraph,
  PopIn,
  Spinner,
  useBrandTheme,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

type MultiStepLoadingAnimationProps = {
  animationJson?: object;
  steps?: {
    label: string;
    status: 'loading' | 'done' | 'pending';
  }[];
};

export const MultiStepLoadingAnimation = ({
  animationJson,
  steps,
}: MultiStepLoadingAnimationProps) => {
  const brandTheme = useBrandTheme();

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      m="0 auto"
    >
      {animationJson && (
        <Box width="120px">
          <Lottie loop animationData={animationJson} play />
        </Box>
      )}
      {steps && (
        <Box gap="40px" flexDirection="column">
          {steps.map((step) => (
            <Box key={step.label} gap="8px" alignItems="center">
              {step.status === 'loading' && <Spinner md />}
              {step.status === 'pending' && (
                <Spinner md paused color={theme.colors.gray500} />
              )}
              {step.status === 'done' && (
                <PopIn
                  borderRadius="50%"
                  width="24px"
                  height="24px"
                  bgcolor={
                    brandTheme?.colors?.primary || theme.colors.electricBlue
                  }
                  justifyContent="center"
                  alignItems="center"
                  animationDelay="0"
                >
                  <Icon type="check" color="white" size={16} />
                </PopIn>
              )}
              <Paragraph
                variant="text-bold"
                opacity={
                  step.status === 'done'
                    ? '1'
                    : step.status === 'loading'
                    ? '.6'
                    : '.4'
                }
              >
                {step.label}
              </Paragraph>
            </Box>
          ))}
        </Box>
      )}
      <Paragraph variant="text" mt="130px" color="gray500">
        Please don't close the window.
      </Paragraph>
    </Box>
  );
};
