/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod';
import { useFlags } from 'flagsmith/react';
import { z } from 'zod';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useRouter } from 'next/router';

import {
  Box,
  Form,
  MultiStep,
  Spinner,
  useMultiStep,
  useSnapshotContext,
} from '@hl-portals/ui';

import Sidebar from './Sidebar';
import Step from './Step';
import { Step as StepType, getFlow, getStepInfo } from './constants';
import { getLoanOfficerQueryParams } from './helpers';
import { useQueryFormState } from './hooks';
import Header from './layout/Header';
import { getFlowSchema } from './steps';
import { EligibilityDenialInformation } from './steps/EligibilityDenialInformation';
import { RouterQuery } from './types';

const BbysLeadSubmissionPage = (): React.ReactElement => {
  const router = useRouter();
  const query = router.query as RouterQuery;

  const { partnerSlug, source_lead_id } = query;
  const flow = getFlow(router);
  const loanOfficerInfo = getLoanOfficerQueryParams(query);

  const multiStep = useMultiStep();
  const [formSchema, setFormSchema] = useState(flow);
  const { snapshot, uuid } = useSnapshotContext();
  const [loadedSnapshot, setLoadedSnapshot] = useState('');

  const {
    'bbys-new-venture-escrow-officers': { enabled: isEscrowOfficerEnabled },
  } = useFlags(['bbys-new-venture-escrow-officers']);

  const showEscrowOfficerSelect =
    isEscrowOfficerEnabled && partnerSlug === 'newventureescrow';

  type BBYSFormData = z.infer<typeof schema>;
  const schema = getFlowSchema(formSchema, showEscrowOfficerSelect);
  const firstStep = getStepInfo('Lead Information', flow) as StepType;

  const form = useForm<BBYSFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      flow,
      submitted_step: firstStep ? firstStep.key : '',
      source_lead_id,
      ...loanOfficerInfo,
    },
  });

  const formState = form.watch();

  useEffect(() => {
    if (!Object.keys(snapshot).length || !form) return;

    // update url query with snapshot values if relevant
    const { flow: snapshotFlow, partner_slug } = snapshot;

    if (partner_slug && partner_slug !== router.query.partnerSlug) {
      router.push({
        pathname: `/bbys/new/${partner_slug}`,
        query: {
          uuid: router.query.uuid,
          flow: router.query.flow || snapshotFlow,
        },
      });
      return;
    }

    if (snapshotFlow && snapshot.submitted_step && !router.query.flow) {
      router.query.flow = snapshotFlow;

      router.push({
        pathname: router.pathname,
        query: router.query,
      });
      return;
    }

    if (loadedSnapshot !== snapshot.snapshot_uuid) {
      setLoadedSnapshot(snapshot.snapshot_uuid as string);

      form.reset({
        ...snapshot,
        flow,
        submitted_step: snapshot.submitted_step || firstStep.key,
        snapshot_uuid: uuid,
        source_lead_id,
        eligibility_result: {
          denial_checks: snapshot?.denial_checks,
          ...snapshot.eligibility_result,
        },
        ...loanOfficerInfo,
        ...(partnerSlug ? { partner_slug: partnerSlug } : {}),
      });
    }
  }, [snapshot, loadedSnapshot, snapshot.snapshot_uuid, query]);

  const render = () => {
    if (formState.denied) {
      return <EligibilityDenialInformation />;
    }
    if (loadedSnapshot !== snapshot.snapshot_uuid) {
      <Spinner xl mt="120px" />;
    }
    return (
      <Box flexDirection={{ xs: 'column', md: 'row' }}>
        <Sidebar />
        <Step />
      </Box>
    );
  };

  useEffect(() => {
    if (formSchema !== formState.flow) {
      setFormSchema(formState.flow);
    }
  }, [formSchema, formState.flow]);

  useQueryFormState(form, [['partnerSlug', 'partner_slug'], ['flow']]);

  return (
    <Form methods={form} onSubmit={() => {}}>
      <MultiStep.Root {...multiStep}>
        <Header />
        {render()}
      </MultiStep.Root>
    </Form>
  );
};

export default BbysLeadSubmissionPage;
