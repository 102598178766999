import { z } from 'zod';

import { Box, Button, Icon, Inputs, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { customZodTypes } from '@hl-portals/helpers';

import { ERRORS } from '../helpers';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';

export const consultantInformationSchema = {
  lender_company_name: z.string({
    required_error: ERRORS.required('company name'),
  }),
  sales_consultant_name: customZodTypes.fullName(),
  sales_consultant_email: customZodTypes.email(),
  sales_consultant_phone: customZodTypes.phone(),
};

export const ConsultantInformation = (): React.ReactElement => {
  const { goNext, validate } = useStepUtils('consultant_information');

  const isValid = validate([
    'sales_consultant_name',
    'sales_consultant_email',
    'sales_consultant_phone',
  ]);

  return (
    <>
      <Step
        title="Lennar New Home Consultant"
        description="Add contact information for the Lennar Sales Representative that will be working on this deal. "
      >
        <Box
          bgcolor={theme.colors.gray50}
          p="12px"
          gap="8px"
          width="100%"
          alignItems="flex-start"
        >
          <Icon type="infoSolid" fill="electricBlue" mt="4px" />
          <Paragraph variant="text-small">
            We'll use this information to send email communications.
          </Paragraph>
        </Box>

        <Inputs.Text
          mode="onBlur"
          name="sales_consultant_name"
          label="Full Name"
          containerProps={{ flex: '1', width: '100%' }}
          placeholder="Enter sales consultant name"
          borderColor={theme.colors.coolGray4}
          data-test="sales_consultant_name-input"
        />
        <Inputs.Email
          mode="onBlur"
          name="sales_consultant_email"
          label="Email Address"
          containerProps={{ flex: '1', width: '100%' }}
          placeholder="Enter sales consultant email address"
          borderColor={theme.colors.coolGray4}
          data-test="sales_consultant_email-input"
        />
        <Inputs.Phone
          mode="onBlur"
          name="sales_consultant_phone"
          label="Phone Number"
          containerProps={{ flex: '1', width: '100%' }}
          placeholder="Enter sales consultant phone number"
          borderColor={theme.colors.coolGray4}
          data-test="sales_consultant_phone-input"
        />
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
        progressColor={'electricBlue'}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
