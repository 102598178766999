import {
  SUBMIT_LENDER_BBYS_ALL_CASH_LEAD,
  SUBMIT_LENDER_BBYS_LEAD,
  SUBMIT_LENDER_BBYS_PRE_LEAD,
} from '@hl-portals/constants';

import { CurrentFlow } from '../../metadata/types';

export function getSubmitUrl(current_flow: CurrentFlow, finance_type: string) {
  let url = '';

  if (current_flow === 'agent') {
    url = SUBMIT_LENDER_BBYS_PRE_LEAD;

    if (finance_type === 'cash' || finance_type === 'all_cash_bbys') {
      url = SUBMIT_LENDER_BBYS_ALL_CASH_LEAD;
    }
  } else {
    url = SUBMIT_LENDER_BBYS_LEAD;
  }

  return url;
}
