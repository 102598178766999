import { InputGroup, Inputs, Paragraph, Select } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { LENNAR_DIVISIONS } from '../../constants';
import { useStepUtils } from '../../hooks';

export const LennarExternalHomeConsultantInputs = () => {
  const {
    formState,
    formContext: { setValue },
  } = useStepUtils();

  return (
    <>
      <Inputs.Text
        mode="onBlur"
        name="sales_consultant_name"
        label="Full Name"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="John Doe"
        borderColor={theme.colors.coolGray4}
        data-test="sales_consultant_name-input"
      />
      <Inputs.Email
        mode="onBlur"
        name="sales_consultant_email"
        label="Email Address"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="you@email.com"
        borderColor={theme.colors.coolGray4}
        data-test="sales_consultant_email-input"
      />
      <Inputs.Phone
        mode="onBlur"
        name="sales_consultant_phone"
        label="Phone Number"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="(555)555-5555"
        borderColor={theme.colors.coolGray4}
        data-test="sales_consultant_phone-input"
      />

      <InputGroup label="Division" width="100%">
        <Select
          width="100%"
          customSelectedItemCss={{
            minWidth: '100% !important',
            borderRadius: '6px !important',
            border: '1px solid #DBDFE6 !important',
          }}
          onChange={(value) => setValue('division', value)}
          selectedValue={formState.division}
          height="48px"
          variant={{ xs: 'top', md: 'bottom' }}
          options={LENNAR_DIVISIONS.map((option) => ({
            value: option,
            text: option,
          }))}
          placeholder="Select Division"
          data-test="division-select"
        />
      </InputGroup>

      <Inputs.Checkbox name="remember_info">
        <Paragraph variant="text" textAlign="left">
          Save my information for future use
        </Paragraph>
      </Inputs.Checkbox>
    </>
  );
};
