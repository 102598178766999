import { Box } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

export const Divider = () => (
  <Box
    height="1px"
    bgcolor={theme.colors.coolGray4}
    width="100%"
    display={{ xs: 'none', md: 'flex' }}
  />
);

export default Divider;
