import { Flow } from '../../metadata/types';

export function getFlowFromPartner(partnerSlug?: string): Flow {
  if (!partnerSlug) return 'lenderSubmission';

  switch (partnerSlug.toLowerCase()) {
    case 'lennar':
      return 'lennarSubmission';

    case 'nhc':
      return 'externalLeadSubmitter';

    case 'mtgandbeyondagents':
      return 'agentSubmission';

    default:
      return 'lenderSubmission';
  }
}
