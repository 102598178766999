import { z } from 'zod';

import { useStepUtils } from '../../hooks';
import { CashPurchaseInfo } from './CashPurchaseInfo';
import { LenderInformation } from './LenderInformation';

export const agentFinancialInformationSchema = {
  lender_present: z.enum(['yes', 'no']),
  target_equity_unlock: z.string().optional(),
  confirm_mortgage_balance: z.string().optional(),
  additional_liens_exist: z.boolean().optional(),
  additional_liens: z
    .array(
      z.object({
        lien_type: z.enum(['heloc', 'solar_lien', 'reverse_mortgage', 'other']),
        lien_balance: z.string(),
      })
    )
    .optional(),
};

export const FinancialInformation = (): React.ReactElement => {
  const { formState } = useStepUtils();

  switch (formState.finance_type) {
    case 'cash':
      return <CashPurchaseInfo />;
    default:
      return <LenderInformation />;
  }
};
