import { useLocalStorage } from '@hl-portals/hooks';

export const useSaveFlowRepInfo = (key: string, value?: Record<any, any>) => {
  const [storedValues, setStoredValues, clearStoredValues] = useLocalStorage(
    key,
    value
  );

  const updateStoredValues = (value: Record<any, any>) => {
    setStoredValues({
      ...storedValues,
      ...value,
    });
  };

  return {
    updateStoredValues,
    clearStoredValues,
    storedValues,
  };
};
