import { useFlags } from 'flagsmith/react';
import { z } from 'zod';

import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useRouter } from 'next/router';

import {
  Box,
  Button,
  IconTypeProp,
  Inputs,
  Paragraph,
  SelectProps,
  SelectTabs,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { isFullName, isValidEmail, isValidPhone } from '@hl-portals/helpers';

import { useDropoutsCheck, usePartnerBrand } from '@hl-portals/hooks';

import { getFlow, getLenderFlow } from '../constants';
import { ERRORS } from '../helpers';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Step from '../layout/Step';
import { RouterQuery } from '../types';
import {
  LennarExternalHomeConsultantInputs,
  LennarInformationInputs,
} from './InputSets';

export const globalsSchema = {
  source_lead_id: z.string().optional(),
};

export const userInformationSchema = {
  lender_company_name: z.string({
    required_error: ERRORS.required('company name'),
  }),
  loan_officer_name: z
    .string({
      required_error: ERRORS.required('name'),
    })
    .refine((value) => isFullName(value), {
      message: ERRORS.required('full name'),
    }),
  loan_officer_email: z
    .string({
      required_error: ERRORS.required('email'),
    })
    .refine((value) => isValidEmail(value), ERRORS.valid('email')),
  loan_officer_phone: z
    .string({
      required_error: ERRORS.required('phone number'),
    })
    .refine((value) => isValidPhone(value), {
      message: ERRORS.valid('phone number'),
    }),
  agent_name: z
    .string({
      required_error: ERRORS.required('name'),
    })
    .refine((value) => isFullName(value), {
      message: ERRORS.required('full name'),
    }),
  agent_email: z
    .string({
      required_error: ERRORS.required('email'),
    })
    .refine((value) => isValidEmail(value), ERRORS.valid('email')),
  agent_phone: z
    .string({
      required_error: ERRORS.required('phone number'),
    })
    .refine((value) => isValidPhone(value), {
      message: ERRORS.valid('phone number'),
    }),
  sales_consultant_name: z
    .string({
      required_error: ERRORS.required('name'),
    })
    .refine((value) => isFullName(value), {
      message: ERRORS.required('full name'),
    }),
  sales_consultant_email: z
    .string({
      required_error: ERRORS.required('email'),
    })
    .refine((value) => isValidEmail(value), ERRORS.valid('email')),
  sales_consultant_phone: z
    .string({
      required_error: ERRORS.required('phone number'),
    })
    .refine((value) => isValidPhone(value), {
      message: ERRORS.valid('phone number'),
    }),
};

export const escrowOfficerSchema = {
  external_escrow_officer_name: z
    .string({
      required_error: ERRORS.required('escrow officer name'),
    })
    .min(1, {
      message: ERRORS.required('escrow officer name'),
    }),
  external_escrow_officer_email: z
    .string({
      required_error: ERRORS.required('escrow officer email'),
    })
    .min(1, {
      message: ERRORS.required('escrow officer email'),
    }),
};

type EscrowOfficerValue = Record<string, { email: string }>;

const EscrowOfficerSelect = () => {
  const { setValue, watch } = useFormContext();

  const {
    'bbys-new-venture-escrow-officers': { value },
  } = useFlags(['bbys-new-venture-escrow-officers']);

  const escrowOfficerOptions: SelectProps['options'] = useMemo(() => {
    const escrowOfficersData: EscrowOfficerValue = value
      ? JSON.parse(value.toString())
      : {};

    return Object.keys(escrowOfficersData).map((k) => ({
      text: k,
      value: `${k}:${escrowOfficersData[k].email}`,
    }));
  }, [value]);

  const escrowOfficerName = watch('external_escrow_officer_name');
  const escrowOfficerEmail = watch('external_escrow_officer_email');
  const escrowOfficerValue = `${escrowOfficerName}:${escrowOfficerEmail}`;

  return (
    <Inputs.Select
      label="Escrow Officer"
      containerProps={{ flex: '1', width: '100%' }}
      onChange={(value) => {
        const [name, email] = value.split(':');
        setValue('external_escrow_officer_name', name);
        setValue('external_escrow_officer_email', email);
      }}
      value={escrowOfficerValue}
      options={escrowOfficerOptions}
    />
  );
};

const LenderInputs = ({
  showEscrowOfficerSelect,
}: {
  showEscrowOfficerSelect?: boolean;
}) => (
  <>
    <Inputs.Text
      mode="onBlur"
      name="lender_company_name"
      label="Lender (Company Name)"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your company name"
      borderColor={theme.colors.coolGray4}
      data-test="lender_company_name-input"
    />

    <Inputs.Text
      mode="onBlur"
      name="loan_officer_name"
      label="Loan Officer Full Name"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your full name"
      borderColor={theme.colors.coolGray4}
      data-test="loan_officer_name-input"
    />

    <Inputs.Email
      mode="onBlur"
      name="loan_officer_email"
      label="Loan Officer Email Address"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your email address"
      borderColor={theme.colors.coolGray4}
      data-test="loan_officer_email-input"
    />

    <Inputs.Phone
      mode="onBlur"
      name="loan_officer_phone"
      label="Loan Officer Phone Number"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your phone number"
      borderColor={theme.colors.coolGray4}
      data-test="loan_officer_phone-input"
    />

    {showEscrowOfficerSelect && <EscrowOfficerSelect />}

    <Inputs.Checkbox name="remember_info">
      <Paragraph variant="text" textAlign="left">
        Save my information for future use
      </Paragraph>
    </Inputs.Checkbox>
  </>
);

const AgentInputs = ({
  showEscrowOfficerSelect,
}: {
  showEscrowOfficerSelect?: boolean;
}) => (
  <>
    <Inputs.Text
      mode="onBlur"
      name="agent_name"
      label="Full Name"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your full name"
      borderColor={theme.colors.coolGray4}
      data-test="agent_name-input"
    />

    <Inputs.Email
      mode="onBlur"
      name="agent_email"
      label="Email Address"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your email address"
      borderColor={theme.colors.coolGray4}
      data-test="agent_email-input"
    />

    <Inputs.Phone
      mode="onBlur"
      name="agent_phone"
      label="Phone Number"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter your phone number"
      borderColor={theme.colors.coolGray4}
      data-test="agent_phone-input"
    />

    {showEscrowOfficerSelect && <EscrowOfficerSelect />}

    <Inputs.Checkbox name="remember_info">
      <Paragraph variant="text" textAlign="left">
        Remember me
      </Paragraph>
    </Inputs.Checkbox>
  </>
);

const EmptyInputs = () => <></>;

const LOCAL_STORAGE_USER_KEY = 'hl-bbys-user-info-history';

export const UserInformation = (): React.ReactElement => {
  const {
    goNext,
    validate,
    formState,
    formContext: { setValue },
  } = useStepUtils('lender_information');
  const router = useRouter();
  const query = router.query as RouterQuery;
  const { partnerSlug } = query;

  const { data: partnerStyles } = usePartnerBrand(query.partnerSlug);

  const getDefaultFlowType = () => {
    if (router.query.partnerSlug) {
      if (router.query.partnerSlug && router.query.partnerSlug === 'lennar') {
        return router.query.flow === 'externalLeadSubmitter'
          ? 'externalLeadSubmitter'
          : 'lennarSubmission';
      } else if (router.query.partnerSlug === 'mtgandbeyondagents') {
        return 'agentSubmission';
      } else {
        return 'lenderSubmission';
      }
    }

    return formState.property_address
      ? formState.flow
      : (router.query.flow as string) || '';
  };

  const defaultFlowType = getDefaultFlowType();
  const [flowType, setFlowType] = useState(defaultFlowType);

  const {
    'bbys-new-venture-escrow-officers': { enabled },
  } = useFlags(['bbys-new-venture-escrow-officers']);

  const showEscrowOfficerSelect =
    enabled && query.partnerSlug === 'newventureescrow';

  const userTypeColor = useMemo(() => {
    if (partnerStyles) {
      return partnerStyles.primary_color;
    }
    if (flowType === 'agentSubmission' || flowType === 'lenderSubmission') {
      return 'electricBlue';
    }
    return 'gray60';
  }, [partnerStyles]);

  const { postDropoutCheck } = useDropoutsCheck(() => {
    setValue('dropout_check', true);
  });

  const USER_TYPE_OPTIONS = [
    {
      value: 'lenderSubmission',
      label: "I'm a Loan Officer",
      icon: 'bank' as IconTypeProp,
      iconProps: {
        color: userTypeColor,
      },
    },
    {
      value: 'agentSubmission',
      label: "I'm an Agent",
      icon:
        flowType === 'agentSubmission'
          ? 'userCardSolid'
          : ('userCard' as IconTypeProp),
      iconProps: {
        color: userTypeColor,
        size: flowType === 'agentSubmission' ? 24 : 20,
      },
    },
  ];

  const LENNAR_USER_TYPE_OPTIONS = [
    {
      value: 'lennarSubmission',
      label: "I'm a Loan Officer",
      icon: 'bank' as IconTypeProp,
      iconProps: {
        color: flowType === 'lennarSubmission' ? 'electricBlue' : 'gray60',
      },
    },
    {
      value: 'externalLeadSubmitter',
      label: "I'm a New Home Consultant",
      icon:
        flowType === 'externalLeadSubmitter'
          ? 'userCardSolid'
          : ('userCard' as IconTypeProp),
      iconProps: {
        color: flowType === 'externalLeadSubmitter' ? 'electricBlue' : 'gray60',
        size: flowType === 'externalLeadSubmitter' ? 24 : 20,
      },
    },
  ];

  const isValid = validate(
    ['lenderSubmission', 'lennarSubmission', 'externalLeadSubmitter'].includes(
      flowType
    )
      ? [
          flowType === 'externalLeadSubmitter' ? '' : 'lender_company_name',
          flowType === 'externalLeadSubmitter'
            ? 'sales_consultant_name'
            : 'loan_officer_name',
          flowType === 'externalLeadSubmitter'
            ? 'sales_consultant_email'
            : 'loan_officer_email',
          flowType === 'externalLeadSubmitter'
            ? 'sales_consultant_phone'
            : 'loan_officer_phone',
          ['lennarSubmission', 'externalLeadSubmitter'].includes(flowType)
            ? 'division'
            : '',
          flowType === 'lenderSubmission' && showEscrowOfficerSelect
            ? 'external_escrow_officer_name'
            : '',
          flowType === 'lenderSubmission' && showEscrowOfficerSelect
            ? 'external_escrow_officer_email'
            : '',
        ].filter(Boolean)
      : ['agent_name', 'agent_email', 'agent_phone']
  );

  useEffect(() => {
    if (router.query.partnerSlug === 'lennar' && !router.query.flow) {
      router.query.flow = 'lennarSubmission';
      router.push({
        pathname: router.pathname,
        query: router.query,
      });
    }
  }, [flowType]);

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_USER_KEY)) {
      const info = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
      setValue('lender_company_name', info.lender_company_name);
      setValue('loan_officer_name', info.loan_officer_name);
      setValue('loan_officer_email', info.loan_officer_email);
      setValue('loan_officer_phone', info.loan_officer_phone);
      setValue('division', info.division);

      setValue('agent_name', info.agent_name);
      setValue('agent_email', info.agent_email);
      setValue('agent_phone', info.agent_phone);

      setValue('sales_consultant_name', info.sales_consultant_name);
      setValue('sales_consultant_email', info.sales_consultant_email);
      setValue('sales_consultant_phone', info.sales_consultant_phone);
      setValue('remember_info', true);
    }
  }, []);

  useEffect(() => {
    if (
      query.lenderCompanyName ||
      query.loanOfficerName ||
      query.loanOfficerEmail ||
      query.loanOfficerPhone
    ) {
      if (query.partnerSlug === 'lennar') {
        setFlowType('lennarSubmission');
      } else {
        setFlowType('lenderSubmission');
      }
    }
  }, [query]);

  const rememberExternalLeadSubmitter = () => {
    return {
      sales_consultant_name: formState.sales_consultant_name,
      sales_consultant_email: formState.sales_consultant_email,
      sales_consultant_phone: formState.sales_consultant_phone,
      division: formState.division,
    };
  };

  const rememberLender = () => {
    return {
      lender_company_name: formState.lender_company_name,
      loan_officer_name: formState.loan_officer_name,
      loan_officer_email: formState.loan_officer_email,
      loan_officer_phone: formState.loan_officer_phone,
      division: formState.division,
    };
  };

  const startApplication = () => {
    if (formState.remember_info) {
      const info = [
        'lenderSubmission',
        'lennarSubmission',
        'externalLeadSubmitter',
      ].includes(flowType)
        ? flowType === 'externalLeadSubmitter'
          ? rememberExternalLeadSubmitter()
          : rememberLender()
        : {
            agent_name: formState.agent_name,
            agent_email: formState.agent_email,
            agent_phone: formState.agent_phone,
          };

      localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(info));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    }

    if (flowType !== 'externalLeadSubmitter' && !formState.dropout_check) {
      postDropoutCheck(router.query.uuid);
    }

    goNext();
  };

  const INPUTS = {
    agentSubmission: (
      <AgentInputs showEscrowOfficerSelect={showEscrowOfficerSelect} />
    ),
    lenderSubmission: (
      <LenderInputs showEscrowOfficerSelect={showEscrowOfficerSelect} />
    ),
    lennarSubmission: <LennarInformationInputs />,
    externalLeadSubmitter: <LennarExternalHomeConsultantInputs />,
  };

  const InputsComponent = INPUTS[flowType] || EmptyInputs;

  useEffect(() => {
    if (flowType) {
      setValue(
        'flow',
        ['lenderSubmission', 'lennarSubmission'].includes(flowType)
          ? getFlow(router)
          : flowType
      );
    }
  }, [flowType]);

  return (
    <>
      <Step
        title={
          <Box
            justifyContent={router.query.partnerSlug ? 'flex-start' : 'center'}
            width="100%"
          >
            <Paragraph variant="heading-4">First, tell us about you</Paragraph>
          </Box>
        }
      >
        {(!partnerSlug ||
          [
            'lennar',
            'mtgandbeyondagents',
            'newventureescrow',
            'orchard',
          ].includes(partnerSlug)) && (
          <SelectTabs
            name="flowType"
            onChange={(option) => {
              setFlowType(option);

              switch (option) {
                case 'lenderSubmission':
                  router.query.flow = getLenderFlow(router);
                  break;
                case 'agentSubmission':
                  router.query.flow = 'agentSubmission';
                  break;
                case 'lennarSubmission':
                  router.query.flow = getLenderFlow(router);
                  break;
                case 'externalLeadSubmitter':
                  router.query.flow = 'externalLeadSubmitter';
                  break;
              }

              router.push({
                pathname: router.pathname,
                query: router.query,
              });
            }}
            tabs={
              partnerSlug === 'lennar'
                ? LENNAR_USER_TYPE_OPTIONS
                : USER_TYPE_OPTIONS
            }
            selected={flowType}
          />
        )}

        {InputsComponent}

        {flowType && (
          <Button
            height="48px"
            disabled={!isValid}
            onClick={startApplication}
            tooltipProps={{ width: '100%' }}
            fontSize="16px"
            padding="0 24px !important"
            tooltip={isValid ? '' : 'Complete the required fields to continue'}
            width={{ xs: '100%', md: 'auto' }}
            data-test="start-application-button"
          >
            Start Application
          </Button>
        )}
      </Step>
      <FloatingFAQButton />
    </>
  );
};
