import dayjs from 'dayjs';

import { Box, FadeIn, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { formatCurrency } from '@hl-portals/helpers';

import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';

export const InstantApprovalSuccess = (): React.ReactElement => {
  const { formState } = useStepUtils('success');

  return (
    <Box flexDirection="column" maxWidth="1280px" margin="0 auto">
      <FloatingFAQButton />
      <FadeIn
        animationDelay={'0s'}
        flexDirection={{ xs: 'column', md: 'row' }}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        gap="48px"
      >
        <FadeIn
          flex="1"
          animationDelay={'.2s'}
          flexDirection="column"
          gap="32px"
          maxWidth="625px"
        >
          <Box flexDirection="column" gap="16px">
            <Paragraph
              variant="heading-5"
              textTransform="uppercase"
              color="gray500"
              textAlign="left"
              letterSpacing="2px"
            >
              Application Submitted
            </Paragraph>
            <Paragraph
              variant="heading-1"
              textAlign="left"
              fontSize={{ xs: '24px', md: '32px' }}
              maxWidth="450px"
            >
              Your client is{' '}
              <span style={{ color: theme.colors.green600 }}>approved</span> for
              Buy Before You Sell!
            </Paragraph>
          </Box>
          <Paragraph variant="text">
            The property {formState.full_address} is instantly approved for the
            requested amount of{' '}
            <strong>
              {formatCurrency({
                value: formState.submit_response?.target_unlock_amount || '',
              })}
            </strong>
            .
          </Paragraph>
          <Paragraph variant="text">
            We will send you the Buy Before You Sell Program Agreement{' '}
            <strong>within four business hours</strong>. Once reviewed, the next
            step is for your client to sign the agreement.
          </Paragraph>
          <Box flexDirection="column" gap="12px">
            <Paragraph variant="text-xsmall">
              Instant approval valid until{' '}
              {dayjs().add(30, 'days').format('MMMM DD, YYYY')}
            </Paragraph>
            <Paragraph variant="text-xsmall">
              The final Equity Unlock amount is subject to the accuracy of your
              provided information
            </Paragraph>
          </Box>
        </FadeIn>
        <FadeIn
          animationDelay={'.4s'}
          position="relative"
          flex="1"
          justifyContent={{ xs: 'flex-end', md: 'center' }}
          minHeight={{ xs: '150px', md: '224px' }}
          minWidth="320px"
        >
          <Box
            as="img"
            position="absolute"
            top={{ xs: '-450px', md: '-250px' }}
            pointerEvents="none"
            src="/illustrations/bbys-lead-submission/confetti_large.png"
          />
          <Box
            as="img"
            src="/illustrations/bbys-lead-submission/house_instant_approve.png"
            position={{ xs: 'absolute', md: 'static' }}
            bottom="0px"
            right="0px"
            pointerEvents="none"
            width={{ xs: '220px', md: 'auto' }}
          />
        </FadeIn>
      </FadeIn>
      <Box
        as="img"
        src="/illustrations/bbys-lead-submission/dashed_accent2.png"
        position="fixed"
        right={{ xs: '-180px', md: '0px' }}
        top={{ xs: '-300px', md: '0px' }}
        zIndex="-1"
      />
    </Box>
  );
};
