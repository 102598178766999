import styled from 'styled-components';

import { Box, Icon, useBrandTheme } from '@hl-portals/ui';

import { useFAQ, useStepUtils } from '../hooks';

const StyledBox = styled(Box)`
  ${({ theme }) => `
    transition: all .2s ease-out;
    border: 1px solid ${theme.colors.transparent};

    &:hover {
      border: 1px solid ${theme.colors.blue400};
    }
  `}
`;

export const FloatingFAQButton = ({ ...props }): React.ReactElement => {
  const { multiStep } = useStepUtils();
  const brandTheme = useBrandTheme();
  const { openFAQ } = useFAQ(multiStep.currentStep);

  return (
    <StyledBox
      position="fixed"
      zIndex="1"
      borderRadius="50px"
      padding="12px 24px"
      bgcolor="white"
      fontSize="16px"
      alignItems="center"
      fontWeight="600"
      gap="8px"
      bottom={{
        md: multiStep.isFirstStep || multiStep.isLastStep ? '40px' : '140px',
        lg: multiStep.isFirstStep || multiStep.isLastStep ? '80px' : '180px',
      }}
      right={{ md: '32px', lg: '80px' }}
      boxShadow="0px 14px 48px 0px rgba(108, 129, 171, 0.20)"
      cursor="pointer"
      onClick={openFAQ}
      display={{ xs: 'none', md: 'inherit' }}
      {...props}
    >
      See FAQs
      <Icon
        type="externalLinkSolid"
        size={16}
        color={brandTheme.colors?.primary || 'electricBlue'}
      />
    </StyledBox>
  );
};
