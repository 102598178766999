import { useFormContext } from 'react-hook-form';

import { Alert, Box } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

// DEBT: update BE to booleans
type UnderContract = 'yes' | 'no';

export const NewHomeUnderContract = () => {
  const { logLeadSubmissionEvent, onUpdateSnapshot, coreEventData } =
    useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { under_contract } = watch();

  const logEvent = (type: UnderContract) => {
    logLeadSubmissionEvent({
      event_action: 'under_contract',
      event_payload: {
        ...coreEventData,
        under_contract: type,
      },
    });
  };

  const handleSelect = (type: UnderContract) => {
    setValue('under_contract', type);
    logEvent(type);
    onUpdateSnapshot({ under_contract: type, pre_submission_file_keys: [] });
  };

  return (
    <>
      <Content>
        <Container title="Is your client under contract on a new home?">
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              <CardRadio
                title="Yes"
                onClick={() => handleSelect('yes')}
                isActive={under_contract === 'yes'}
                justifyContent="center"
              />
              <CardRadio
                title="No"
                onClick={() => handleSelect('no')}
                isActive={under_contract === 'no'}
                justifyContent="center"
              />
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            We use this information to determine the next steps and understand
            any deadlines related to the new home purchase.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => {
            logEvent(under_contract);
            onUpdateSnapshot({ under_contract });
          }}
          disabled={under_contract === undefined}
        />
      </Footer>
    </>
  );
};
