import * as Sentry from '@sentry/nextjs';

import { Box, Paragraph, Upload, useUploadContext } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

const ACCEPTABLE_FILE_TYPES = '.pdf,.doc,.docx,.xml';

export const NewHomeContractUpload = () => {
  const { logLeadSubmissionEvent, onUpdateSnapshot, coreEventData, isLoading } =
    useLeadSubmissionContext();

  const upload = useUploadContext();

  const hasFailedFiles = upload.files.some((f) => f.status === 'error');

  const onSubmitClick = async () => {
    try {
      const { success, files } = await upload.submit();
      const fileKeys: string[] = files?.map((file) => file.key) || [];

      if (success && fileKeys.length > 0) {
        logLeadSubmissionEvent({
          event_action: 'ir_contract_upload',
          event_payload: { ...coreEventData },
        });
      }

      onUpdateSnapshot({
        pre_submission_file_keys: success ? fileKeys : [],
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const submitBtnProps = {
    disabled: hasFailedFiles,
    isLoading: upload.isLoading || isLoading,
    onClick: () => onSubmitClick(),
  };

  return (
    <>
      <Content>
        <Container
          title="Upload the new home purchase contract"
          subtitle="(Optional) You can also add it after completing the client’s application."
        >
          <Box mb="20px" flexDirection="column" gap="16px">
            <Upload.Drop
              display={{ xs: 'block', sm: 'block' }}
              maxFileSize="10"
              accept={ACCEPTABLE_FILE_TYPES}
            />
            <Box flexDirection="column" mt="4px">
              {upload.files.length > 0 && (
                <>
                  <Paragraph fontSize="16px" color="coolGray2">
                    Your uploads
                  </Paragraph>
                  <Upload.List />
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton {...submitBtnProps} />
      </Footer>
    </>
  );
};
