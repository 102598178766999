import {
  Box,
  HeaderLogo,
  Paragraph,
  ResponsiveDisplay,
  useBrandTheme,
} from '@hl-portals/ui';

export const BbysSidebarLogo = () => {
  const theme = useBrandTheme();

  return (
    <ResponsiveDisplay
      mobile={
        <Box
          flexDirection="column"
          margin="0 auto"
          width="100%"
          maxWidth="520px"
        >
          <Paragraph
            variant="heading-3"
            color={theme?.colors?.primary || 'electricBlue'}
          >
            Buy Before You Sell
          </Paragraph>
          <Paragraph variant="heading-3">Application</Paragraph>
        </Box>
      }
      desktop={
        <Box
          flexDirection="column"
          display={{ xs: 'none', md: 'flex' }}
          gap="40px"
          p="80px 40px 0px 80px"
        >
          {theme?.logoUrl ? (
            <Box as="img" src={theme.logoUrl} width="160px" />
          ) : (
            <HeaderLogo
              shouldUseTextLogo
              target="_blank"
              url="https://lender.homelight.com/lenders/general"
            />
          )}
          <Box flexDirection="column">
            <Paragraph
              variant="heading-3"
              color={theme?.colors?.primary || 'electricBlue'}
            >
              Buy Before You Sell
            </Paragraph>
            <Paragraph variant="heading-3">Application</Paragraph>
          </Box>
        </Box>
      }
    />
  );
};
