import { z } from 'zod';

import {
  Button,
  Inputs,
  Paragraph,
  SimpleLink,
  useBrandTheme,
} from '@hl-portals/ui';

import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';

export const programEligibilityCheckSchema = {
  credit_score: z.boolean().optional(),
  no_va_fha_requirement: z.boolean().optional(),
  no_age_restricted_community: z.boolean().optional(),
  primary_residence: z.boolean().optional(),
};

export const ProgramEligibilityCheck = (): React.ReactElement => {
  const { formState, goNext, validate } = useStepUtils(
    'program_eligibility_check'
  );

  const { colors } = useBrandTheme();

  const liensPresent = formState.eligibility_result?.liens_present;
  const relevantKeys = ['credit_score', 'no_age_restricted_community'].concat(
    liensPresent ? ['no_va_fha_requirement', 'primary_residence'] : []
  );
  const isValid = validate(
    formState.flow === 'externalLeadSubmitter' ? [] : relevantKeys
  );

  return (
    <>
      <Step
        title="Program Eligibility Criteria"
        note={
          formState.flow === 'externalLeadSubmitter'
            ? ''
            : 'Check each box to proceed.'
        }
        description={
          formState.flow === 'externalLeadSubmitter'
            ? "Check the boxes to confirm your client meets the program's eligibility criteria"
            : "Confirm your client meets the program's eligibility criteria by checking all the boxes."
        }
        gap="24px"
      >
        <Inputs.Checkbox
          name="credit_score"
          p={{ xs: '16px 0', md: '16px 12px' }}
        >
          <Paragraph variant="text" textAlign="left">
            The client's credit score is 620 or above
          </Paragraph>
        </Inputs.Checkbox>

        {liensPresent && (
          <Inputs.Checkbox
            name="no_va_fha_requirement"
            p={{ xs: '16px 0', md: '16px 12px' }}
          >
            <Paragraph variant="text" textAlign="left">
              The client doesn't need a VA, FHA or other government loan for
              their new home purchase
            </Paragraph>
          </Inputs.Checkbox>
        )}

        <Inputs.Checkbox
          name="no_age_restricted_community"
          p={{ xs: '16px 0', md: '16px 12px' }}
        >
          <Paragraph variant="text" textAlign="left">
            The property is not located in an age-restricted community (Example:
            50+ living community)
          </Paragraph>
        </Inputs.Checkbox>

        {liensPresent && (
          <Inputs.Checkbox
            name="primary_residence"
            p={{ xs: '16px 0', md: '16px 12px' }}
          >
            <Paragraph variant="text" textAlign="left">
              The property is the client's primary residence
            </Paragraph>
          </Inputs.Checkbox>
        )}
        <Paragraph variant="text">
          To know more about Buy Before You Sell Eligibility Requirements{' '}
          <SimpleLink
            fontSize="16px"
            target="_blank"
            color={colors.primary || '#46B6FF'}
            href="https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements"
          >
            Click here.
          </SimpleLink>
        </Paragraph>
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
