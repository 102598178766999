import { FadeIn, useBrandTheme } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { Step, getStepInfo } from './constants';
import { useStepUtils } from './hooks';
import { StepComponentMap } from './steps';

const BbysLeadSubmissionSidebar = () => {
  const { multiStep, formState } = useStepUtils();
  const brandTheme = useBrandTheme();

  const currentStepInfo = getStepInfo(
    multiStep.currentStep,
    formState.flow
  ) as Step;

  const SidebarContentComponent =
    StepComponentMap[currentStepInfo?.key]?.sidebar;

  const showSidebar =
    currentStepInfo && currentStepInfo?.showSidebar && SidebarContentComponent;

  if (!SidebarContentComponent) return null;

  return (
    <FadeIn
      flexDirection="column"
      bgcolor={
        brandTheme.colors?.secondary
          ? brandTheme.colors?.secondary
          : theme.colors.mistBlue
      }
      p={{ xs: '32px 24px', md: '0px' }}
      width={{ xs: 'auto', md: '380px', lg: '406px' }}
      maxWidth={showSidebar ? 'auto' : '0px'}
      transition="all .2s ease-out"
      minHeight={{ xs: 'auto', md: '100vh' }}
      animationDelay="0s"
      position={{ xs: 'static', md: 'fixed' }}
      left="0"
      zIndex="1"
      height="100%"
      display={{ xs: multiStep.isFirstStep ? 'flex' : 'none', md: 'flex' }}
      key={multiStep.currentStep}
    >
      {showSidebar && <SidebarContentComponent />}
    </FadeIn>
  );
};

export default BbysLeadSubmissionSidebar;
