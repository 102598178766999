import { createContext, useContext } from 'react';

import { CoreEventData, LeadSubmissionEvent } from '@hl-portals/hooks';

import { FormData } from '../../metadata/forms';
import { FlowError } from '../../metadata/types';

type LeadSubmissionContextState = {
  uuid: string;
  coreEventData: CoreEventData;
  isLoading: boolean;
  isFetching: number;
  isMutating: number;
  isPropertyEligibilityChecksLoading: boolean;
  isCalculateEquityFetching: boolean;
  logLeadSubmissionEvent: ({
    event_action,
    event_payload,
  }: LeadSubmissionEvent) => void;
  onUpdateSnapshot: (optionalPayload?: Partial<FormData>) => void;
  onCheckStateEligibility: () => void;
  onFetchPropertyUUID: () => void;
  onSetFlowError: (
    error: FlowError,
    optionalPayload?: Partial<FormData>
  ) => void;
  onClearFlowError: () => void;
  onCheckPropertyEligibility: () => void;
  onSubmit: () => void;
  onFetchTitleFlex: () => void;
};

export const LeadSubmissionContext = createContext<
  LeadSubmissionContextState | undefined
>(undefined);

export const useLeadSubmissionContext = () => {
  const ctx = useContext(LeadSubmissionContext);
  if (!ctx) throw new Error('Missing LeadSubmissionProvider');
  return ctx;
};
