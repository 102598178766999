import { useFlags } from 'flagsmith/react';

import { LenderInformation } from './LenderInformation';
import { UserInformation } from './UserInformation';

export const LeadInformation = (): React.ReactElement => {
  const {
    'bbys-agent-sourced-leads': { enabled: agentSourcedLeadSubmissionEnabled },
  } = useFlags(['bbys-agent-sourced-leads']);

  return agentSourcedLeadSubmissionEnabled ? (
    <UserInformation />
  ) : (
    <LenderInformation />
  );
};
