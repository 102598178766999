import { z } from 'zod';
import {
  AgentInformation,
  CalculatingEquityUnlock,
  ClientFinancialDetails,
  ClientInformation,
  Confirmation,
  ConsultantInformation,
  DepartingResidenceInfo,
  EquityUnlockEstimation,
  LeadInformation,
  ProgramEligibilityCheck,
  Success,
  agentInformationSchema,
  clientFinancialInformationSchema,
  clientInformationSchema,
  confirmationSchema,
  consultantInformationSchema,
  departingResidenceSchema,
  globalsSchema,
  programEligibilityCheckSchema,
  userInformationSchema,
} from '.';
import {
  FinanceType,
  FinancialInformation,
  PreferredLender,
  agentFinancialInformationSchema,
  financeTypeSchema,
} from './Agent';
import { EligibilityDenialInformation } from './EligibilityDenialInformation';
import SIDEBAR_CONTENT from './sidebar';
import { escrowOfficerSchema } from './UserInformation';

export * from './AgentInformation';
export * from './CalculatingEquityUnlock';
export * from './ClientFinancialDetails';
export * from './ClientInformation';
export * from './Confirmation';
export * from './ConsultantInformation';
export * from './DepartingResidenceInfo';
export * from './EquityUnlockEstimation';
export * from './InstantApprovalSuccess';
export * from './LeadInformation';
export * from './LenderInformation';
export * from './ProgramEligibilityCheck';
export * from './Success';
export * from './UserInformation';

export const StepComponentMap = {
  lender_information: {
    component: LeadInformation,
    sidebar: SIDEBAR_CONTENT.LeadInformation,
  },
  departing_residence_info: {
    component: DepartingResidenceInfo,
    sidebar: SIDEBAR_CONTENT.DepartingResidenceInfo,
  },
  program_eligibility_check: {
    component: ProgramEligibilityCheck,
    sidebar: SIDEBAR_CONTENT.ProgramEligibilityCheck,
  },
  equity_unlock_estimation: {
    component: EquityUnlockEstimation,
    sidebar: SIDEBAR_CONTENT.EquityUnlockEstimation,
  },
  clients_financial_details: {
    component: ClientFinancialDetails,
    sidebar: SIDEBAR_CONTENT.ClientFinancialDetails,
  },
  finance_type: {
    component: FinanceType,
    sidebar: SIDEBAR_CONTENT.ClientFinancialDetails,
  },
  clients_information: {
    component: ClientInformation,
    sidebar: SIDEBAR_CONTENT.ClientInformation,
  },
  agent_information: {
    component: AgentInformation,
    sidebar: SIDEBAR_CONTENT.AgentInformation,
  },
  agent_financial_information: {
    component: FinancialInformation,
    sidebar: SIDEBAR_CONTENT.ClientFinancialDetails,
  },
  agent_preferred_lender: {
    component: PreferredLender,
    sidebar: SIDEBAR_CONTENT.ClientFinancialDetails,
  },
  consultant_information: {
    component: ConsultantInformation,
    sidebar: SIDEBAR_CONTENT.ConsultantInformation,
  },
  calculating_equity_unlock: {
    component: CalculatingEquityUnlock,
  },
  confirmation: {
    component: Confirmation,
  },
  success: {
    component: Success,
  },
  eligibility_denial_information: {
    component: EligibilityDenialInformation,
  },
};

export const getFlowSchema = (
  flow: string,
  showEscrowOfficerSelect?: boolean
) => {
  const flows = {
    lenderSubmission: {
      ...globalsSchema,
      ...userInformationSchema,
      ...departingResidenceSchema,
      ...programEligibilityCheckSchema,
      ...clientFinancialInformationSchema,
      ...clientInformationSchema,
      ...agentInformationSchema,
      ...confirmationSchema,
      ...(showEscrowOfficerSelect ? escrowOfficerSchema : {}),
    },
    agentSubmission: {
      ...globalsSchema,
      ...userInformationSchema,
      ...userInformationSchema,
      ...departingResidenceSchema,
      ...programEligibilityCheckSchema,
      ...financeTypeSchema,
      ...clientInformationSchema,
      ...agentFinancialInformationSchema,
      ...confirmationSchema,
    },
    lennarSubmission: {
      ...globalsSchema,
      ...userInformationSchema,
      ...userInformationSchema,
      ...departingResidenceSchema,
      ...programEligibilityCheckSchema,
      ...clientFinancialInformationSchema,
      ...clientInformationSchema,
      ...agentInformationSchema,
      ...consultantInformationSchema,
      ...confirmationSchema,
    },
    externalLeadSubmitter: {
      ...globalsSchema,
      ...userInformationSchema,
      ...userInformationSchema,
      ...departingResidenceSchema,
      ...programEligibilityCheckSchema,
      ...financeTypeSchema,
      ...clientInformationSchema,
      ...agentFinancialInformationSchema,
      ...confirmationSchema,
    },
  };

  return z.object({
    flow: z.string().default('lenderSubmission'),
    ...(flows[flow] || {}),
  });
};
