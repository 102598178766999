import { useFormContext } from 'react-hook-form';

import { Box, Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const NewHomeTargetEquityAmount = () => {
  const { coreEventData, logLeadSubmissionEvent, onUpdateSnapshot } =
    useLeadSubmissionContext();

  const { watch } = useFormContext();
  const { target_equity_unlock } = watch();

  const logEvent = (target_equity_unlock: string) => {
    logLeadSubmissionEvent({
      event_action: 'target_equity_unlock',
      event_payload: {
        ...coreEventData,
        target_equity_unlock,
      },
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (target_equity_unlock) {
        logEvent(target_equity_unlock);
        onUpdateSnapshot({ target_equity_unlock });
      }
      return;
    }
  };

  return (
    <>
      <Content>
        <Container
          title="What is your client’s target equity unlock amount?"
          subtitle="This is the amount needed for the Equity Unlock loan"
        >
          <Box mb="20px" flexDirection="column" gap="16px">
            <Inputs.Currency
              isFocused
              border="transparent"
              name="target_equity_unlock"
              maxWidth="410px"
              onKeyDown={handleKeyDown}
            />
            <Paragraph color="coolGray2" fontSize="16px" fontWeight="400">
              If your client does not need the equity loan enter 0
            </Paragraph>
          </Box>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => {
            logEvent(target_equity_unlock);
            onUpdateSnapshot({ target_equity_unlock });
          }}
          disabled={target_equity_unlock === undefined}
        />
      </Footer>
    </>
  );
};
