import { MultiColorIconKey } from '@hl-portals/ui';

// =========================================================================
// Steps

export const STEPS = {
  LEAD_INFORMATION: 'Lead Information',
  DEPARTING_RESIDENCE: 'Departing Residence',
  HOME_VALUE: 'Home Value',
  LIENS_SELECTION: 'Liens Selection',
  LIENS_BALANCES: 'Liens Balances',
  PROGRAM_ELIGIBILITY: 'Program Eligibility',
  EQUITY_UNLOCK_AMOUNT: 'Equity Unlock Amount',
  CLIENT_INFORMATION: 'Client Information',
  FINANCIAL_TYPE: 'Financial Type',
  FINANCIAL_INFORMATION: 'Financial Information',
  AGENT_INFORMATION: 'Agent Information',
  LOAN_OFFICER_INFORMATION: 'Loan Officer Information',

  // Phase 2:
  TRUST: 'Trust',
  AGE_RESTRICTED_COMMUNITY: 'Age Restricted Community', // Eligibility Check Part 1
  PRIMARY_RESIDENCE: 'Primary Residence', // Eligibility Check Part 2
  CREDIT_SCORE: 'Credit Score',
  CLIENT_FINANCE: 'Client Finance',
  NEW_HOME_UNDER_CONTRACT: 'New Home Under Contract',
  NEW_HOME_CLOSING_DATE: 'New Home Closing Date',
  NEW_HOME_PURCHASE_FUNDS: 'New Home Purchase Funds',
  NEW_HOME_CONTRACT_UPLOAD: 'New Home Contract Upload',
  NEW_HOME_PURCHASE_TIMELINE: 'New Home Purchase Timeline',
  NEW_HOME_TARGET_EQUITY_AMT: 'New Home Target Equity Amount',
  ADD_CLIENT_INFORMATION: 'Add Client Information',
  ADD_AGENT_INFO: 'Add Agent Info',
  ADD_ASSISTANT_INFO: 'Add Assistant Info',
  ADD_LOAN_OFFICER_INFO: 'Add Loan Officer Info',
  ADD_TRANSACTION_COORDINATOR_INFO: 'Add Transaction Coordinator Info',
  REVIEW_AND_SUBMIT: 'Review And Submit',
  SUCCESS: 'Success',
} as const;

type StepObject = typeof STEPS;
type StepKeys = keyof StepObject;
export type Step = StepObject[StepKeys];

// =========================================================================
// Step With Sidebar Image

type StepWithImage = Exclude<
  Step,
  'Equity Loading' | 'Review And Submit' | 'Success'
>;

export const STEP_SIDEBAR_IMAGE: Record<StepWithImage, MultiColorIconKey> = {
  'Lead Information': 'House1',
  'Departing Residence': 'House2',
  'Home Value': 'House2',
  'Program Eligibility': 'RadioSelect',
  'Liens Selection': 'House2',
  'Liens Balances': 'House2',
  'Equity Unlock Amount': 'Calculator',
  'Client Information': 'Users',
  'Financial Type': 'Bank',
  'Financial Information': 'Bank',
  'Agent Information': 'Users',
  'Loan Officer Information': 'Users',
  'Age Restricted Community': 'RadioSelect',
  'Primary Residence': 'RadioSelect',
  'Credit Score': 'RadioSelect',
  Trust: 'RadioSelect',
  'Client Finance': 'RadioSelect',
  'New Home Under Contract': 'House1',
  'New Home Closing Date': 'House1',
  'New Home Purchase Funds': 'Bank',
  'New Home Contract Upload': 'House1',
  'New Home Purchase Timeline': 'House1',
  'New Home Target Equity Amount': 'Bank',
  'Add Client Information': 'Users',
  'Add Agent Info': 'Users',
  'Add Assistant Info': 'Users',
  'Add Loan Officer Info': 'Users',
  'Add Transaction Coordinator Info': 'Users',
};

// =========================================================================
// Step to hide Header

export const STEPS_TO_HIDE_HEADER: Step[] = ['Success'];

// =========================================================================
// Step to hide Sidebar

export const STEPS_TO_HIDE_SIDEBAR: Step[] = ['Review And Submit', 'Success'];

// =========================================================================
// Lennar Divisions

export const LENNAR_DIVISIONS = [
  'AL - Gulf Coast',
  'AL - North Alabama',
  'AZ - Arizona Sales Center',
  'AZ - Phoenix',
  'AZ - Tucson',
  'CA - Bay Area-Concord',
  'CA - Central Valley',
  'CA - Coastal',
  'CA - Inland Empire',
  'CA - Sacramento',
  'CA - San Diego',
  'CO - Englewood',
  'FL - Jacksonville',
  'FL - Mid-Florida',
  'FL - Orlando',
  'FL - Palm Atlantic',
  'FL - SE FL South',
  'FL - SW Florida',
  'FL - Tampa',
  'GA - Atlanta',
  'ID - Boise',
  'IL - Chicago',
  'IN - Indianapolis',
  'Maryland',
  'MN - Minneapolis',
  'NC - Charlotte',
  'NC - Charlotte Sales Center',
  'NC - Raleigh',
  'NV - Las Vegas',
  'NV - Reno',
  'NY - New Jersey',
  'OR - Portland',
  'PA - Philadelphia Metro',
  'SC - Charleston',
  'TN - Nashville',
  'TX - Austin',
  'TX - Dallas',
  'TX - Houston',
  'TX - San Antonio',
  'Utah',
  'Virginia',
  'WA - Seattle',
  'Other',
];

export const LENNAR_DIVISION_OPTIONS = LENNAR_DIVISIONS.map((option) => ({
  value: option,
  text: option,
}));

// =========================================================================
// Pulte Markets

const PULTE_MARKETS = [
  'Arizona',
  'Central Florida',
  'Central Texas',
  'Charlotte',
  'Cleveland',
  'Coastal Carolinas',
  'Colorado',
  'Columbus',
  'Dallas',
  'East Carolina',
  'Georgia',
  'Houston',
  'Illinois',
  'Indianapolis-Louisville',
  'Las Vegas',
  'Michigan',
  'Mid-Atlantic',
  'Minnesota',
  'New England',
  'New Mexico',
  'Northeast Corridor',
  'Northeast Florida',
  'Northern California',
  'Pacific Northwest',
  'Raleigh',
  'San Antonio',
  'Southeast Florida',
  'Southern California',
  'Southwest Florida',
  'Tennessee',
  'West Florida',
];

export const PULTE_MARKET_OPTIONS = PULTE_MARKETS.map((option) => ({
  value: option,
  text: option,
}));

// =========================================================================
// Local Storage Key

export const LOCAL_STORAGE_KEY = 'lead-submission-remember-me';
export const LOCAL_STORAGE_ADDRESS_SEARCH_SETTINGS =
  'lead-submission-address-search-settings';

// =========================================================================
// Liens

export type LienType =
  | 'home_owned'
  | 'mortgage'
  | 'heloc'
  | 'second_mortgage'
  | 'reverse_mortgage'
  | 'solar_lease'
  | 'solar_loan'
  | 'other'
  | '';

export type Lien = {
  type: LienType;
  label: string;
  balance: string;
};

export type Liens = Lien[];

export const LIENS: Liens = [
  {
    type: 'home_owned',
    label: 'No, property is owned free & clear',
    balance: '0',
  },
  { type: 'mortgage', label: 'Mortgage', balance: '0' },
  { type: 'heloc', label: 'HELOC', balance: '0' },
  { type: 'second_mortgage', label: 'Second mortgage', balance: '0' },
  { type: 'reverse_mortgage', label: 'Reverse mortgage', balance: '0' },
  { type: 'solar_lease', label: 'Solar lease', balance: '0' },
  { type: 'solar_loan', label: 'Solar loan', balance: '0' },
  { type: 'other', label: 'Other', balance: '0' },
];

export const HOME_OWNED = LIENS[0].type;
export const LIENS_OPTIONS = LIENS.filter((lien) => lien.type !== HOME_OWNED);

// =========================================================================
// Min Home Value

export const MIN_HOME_VALUE = 375_000;

export const CASH = 'cash';
export const UNSURE = 'unsure';
export const MORTGAGE = 'mortgage';
export const MORTGAGE_VA = 'mortgage_va';
export const MORTGAGE_FHA = 'mortgage_fha';

export type FinanceOptions =
  | 'cash'
  | 'mortgage'
  | 'mortgage_fha'
  | 'mortgage_va'
  | 'unsure';

export type Finance = {
  type: FinanceOptions;
  label: string;
};

export type Finances = Finance[];

export const FINANCE_OPTIONS: Finances = [
  {
    type: CASH,
    label: 'All Cash',
  },
  {
    type: MORTGAGE,
    label: 'Mortgage - Conventional',
  },
  {
    type: MORTGAGE_FHA,
    label: 'Mortgage - FHA',
  },
  {
    type: MORTGAGE_VA,
    label: 'Mortgage - VA',
  },
  {
    type: UNSURE,
    label: 'Other',
  },
];

export type TimelineOptions = '1 month' | '2-3 months' | '3+ months' | 'unsure';

export type Timeline = {
  type: TimelineOptions;
  label: string;
};

export type Timelines = Timeline[];

export const TIMELINE_OPTIONS: Timelines = [
  {
    type: '1 month',
    label: '1 month',
  },
  {
    type: '2-3 months',
    label: '2-3 months',
  },
  {
    type: '3+ months',
    label: '3+ months',
  },
  {
    type: 'unsure',
    label: 'Unsure',
  },
];
