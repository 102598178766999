/* eslint-disable react/no-unescaped-entities */
import { useMemo } from 'react';
import { useFlags } from 'flagsmith/react';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  FadeIn,
  Icon,
  Paragraph,
  TransformedBox,
  useBrandTheme,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import { useScreenSize } from '@hl-portals/hooks';

import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';

export const ExpressApprovalSuccess = (): React.ReactElement => {
  const {
    'remove-property-questionnaire-bbys': {
      enabled: removePropertyQuestionnaireBbys,
    },
  } = useFlags(['remove-property-questionnaire-bbys']);
  const { formState } = useStepUtils('success');
  const { isDesktop } = useScreenSize();

  const brandTheme = useBrandTheme();

  const copyLink = (url) => () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(url).then(() => {
        toast('Link successfully copied to clipboard!', {
          type: 'success',
        });
      });
    }
  };

  const propertyUploadTaskLink = `${config.agentDashboardUrl}/public/tasks/bbys_submit_property_photos?token=${formState.submit_response?.token}`;

  const propertyQuestionnaireLink = useMemo(
    () =>
      `https://homelight.typeform.com/to/yVpL70jE?typeform-source=${window.location.host}#task_id=${formState.submit_response?.property_questionnaire_task_id}`,
    [formState.submit_response]
  );

  return (
    <Box flexDirection="column" maxWidth="1280px" margin="0 auto">
      <FloatingFAQButton />
      <Box
        flexDirection="column"
        maxWidth="625px"
        m="0 auto"
        position="relative"
        zIndex="10"
      >
        <FadeIn
          animationDelay={'0s'}
          flexDirection="column"
          gap="40px"
          alignItems="center"
        >
          <FadeIn animationDelay={'.2s'} flexDirection="column" gap="16px">
            <Paragraph
              variant="heading-5"
              textTransform="uppercase"
              color="gray500"
              textAlign={{ xs: 'left', md: 'center' }}
              letterSpacing="2px"
            >
              Application Submitted
            </Paragraph>
            <Paragraph
              variant="heading-1"
              textAlign={{ xs: 'left', md: 'center' }}
              fontSize={{ xs: '24px', md: '36px' }}
            >
              Here are the next steps
            </Paragraph>
            <Paragraph variant="text" textAlign="left" lineHeight="150%">
              To evaluate the departing residence and determine the equity
              unlock amount we will need additional information from the listing
              agent.
            </Paragraph>
          </FadeIn>
          <FadeIn
            animationDelay={'.3s'}
            boxShadow="0px 14px 48px 0px rgba(108, 129, 171, 0.20);"
            position="relative"
          >
            <Box
              bgcolor={theme.colors.orange500}
              width="4px"
              borderRadius="8px 0px 0px 8px"
            />
            <Box
              borderRadius="0px 8px 8px 0px"
              p="20px 24px"
              flexDirection="column"
              gap="12px"
              bgcolor="white"
            >
              <Paragraph
                variant="text-small"
                fontWeight="700"
                maxWidth={
                  formState.agent_email && isDesktop
                    ? 'calc(100% - 100px)'
                    : '100%'
                }
              >
                {formState.agent_email
                  ? `We've notified the agent at ${formState.agent_email} about these tasks.`
                  : 'Action required from listing agent'}
              </Paragraph>
              <Paragraph
                variant="text-small"
                maxWidth={
                  formState.agent_email && isDesktop
                    ? 'calc(100% - 100px)'
                    : '100%'
                }
              >
                <>
                  We'll provide you with an approval decision within{' '}
                  <strong>24 hours after the agent submits</strong> property
                  photos{removePropertyQuestionnaireBbys ? '' : ' and answers a simple property questionnaire'}
                </>
              </Paragraph>
              {formState.agent_email && isDesktop && (
                <Box
                  as="img"
                  src="/illustrations/bbys-lead-submission/mailbox.png"
                  position="absolute"
                  bottom="0px"
                  right="12px"
                />
              )}
            </Box>
          </FadeIn>
          <FadeIn
            animationDelay={'.6s'}
            flexDirection="column"
            gap="12px"
            width="100%"
          >
            <Paragraph variant="heading-4" fontWeight="600">
              Tasks
            </Paragraph>
            <Paragraph variant="text-small">
              Copy the links and share them with your agent at any time
            </Paragraph>
            <Box p="24px 0" justifyContent="space-between" alignItems="center">
              <Paragraph variant="text-bold">Upload Property Photos</Paragraph>
              <Box alignItems="center" gap="16px">
                <Paragraph
                  variant="text-small"
                  display={{ xs: 'none', md: 'inherit' }}
                >
                  Share link
                </Paragraph>
                <Button
                  variant="outlined"
                  color="tertiary-transparent"
                  onClick={copyLink(propertyUploadTaskLink)}
                  iconLeft={
                    <Icon
                      type="link"
                      fill={brandTheme.colors?.primary || 'electricBlue'}
                    />
                  }
                >
                  Copy Link
                </Button>
              </Box>
            </Box>
            <Box height="1px" bgcolor={theme.colors.frostGray} width="100%" />
            {!removePropertyQuestionnaireBbys && (
              <Box p="24px 0" justifyContent="space-between" alignItems="center">
                <Paragraph variant="text-bold">Property Questionnaire</Paragraph>
                <Box alignItems="center" gap="16px">
                  <Paragraph
                    variant="text-small"
                    display={{ xs: 'none', md: 'inherit' }}
                  >
                    Share link
                  </Paragraph>
                  <Button
                    variant="outlined"
                    color="tertiary-transparent"
                    onClick={copyLink(propertyQuestionnaireLink)}
                    iconLeft={
                      <Icon
                        type="link"
                        fill={brandTheme.colors?.primary || 'electricBlue'}
                      />
                    }
                  >
                    Copy Link
                  </Button>
                </Box>
              </Box>
            )}
          </FadeIn>
        </FadeIn>
      </Box>
      <Box
        as="img"
        src="/illustrations/bbys-lead-submission/dashed_accent.png"
        position="fixed"
        right={{ xs: '-180px', md: '0px' }}
        top={{ xs: '-300px', md: '0px' }}
        zIndex="-1"
      />
      <TransformedBox
        as="img"
        src="/illustrations/bbys-lead-submission/dashed_accent.png"
        position="fixed"
        left={{ xs: '-180px', md: '0px' }}
        bottom={{ xs: '-400px', md: '-300px' }}
        transform="rotate(160deg)"
        zIndex="-1"
      />
    </Box>
  );
};
