import { Box, Button, Paragraph, SimpleLink } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { formatCurrency } from '@hl-portals/helpers';

import { useModal } from '@hl-portals/hooks';

import { useStepUtils } from '../../hooks';
import Divider from '../../layout/Divider';
import { ConfirmationData, ConfirmationItem } from '../Confirmation';

export const ApplicationSummary = () => {
  const { formState } = useStepUtils();
  const { openModal, closeModal } = useModal();

  const seeMore = () => {
    openModal(
      <Box
        mt="32px"
        p="24px 44px"
        flexDirection="column"
        gap="24px"
        maxHeight="90vh"
        overflowY="scroll"
      >
        <ConfirmationData formState={formState} />
        <Paragraph variant="text" color="gray600">
          We sent a copy of the summary to your email
        </Paragraph>
        <Button size="large" onClick={() => closeModal()}>
          Close
        </Button>
      </Box>,
      { asDrawer: true }
    );
  };

  return (
    <Box flexDirection="column" gap="32px">
      <Paragraph variant="heading-4">Application Summary</Paragraph>
      <Box
        flexDirection="column"
        gap="16px"
        p="40px 24px 24px"
        bgcolor={theme.colors.white}
        borderRadius="28px"
        border={`1px solid ${theme.colors.coolGray5}`}
      >
        <ConfirmationItem label="Departing Residence">
          {formState.full_address}
        </ConfirmationItem>
        <Divider />

        <ConfirmationItem label="Estimated Home Value">
          {!formState.estimated_home_value ||
          parseInt(formState.estimated_home_value) === 0
            ? '$0, home is owned free and clear'
            : formatCurrency({
                value: formState.estimated_home_value || '',
              })}
        </ConfirmationItem>
        <Divider />

        <ConfirmationItem label="Target Equity Unlock">
          {formState.target_equity_unlock
            ? formatCurrency({
                value: formState.target_equity_unlock || '',
              })
            : 'Target Equity Unlock not provided'}
        </ConfirmationItem>
        <Divider />
        <SimpleLink
          color={theme.colors.aaBlue}
          onClick={seeMore}
          fontSize="16px"
          textAlign="center"
        >
          See more
        </SimpleLink>
      </Box>
    </Box>
  );
};

export default ApplicationSummary;
