import { Box, MultiColorIcon, Paragraph, useBrandTheme } from '@hl-portals/ui';

import { BbysSidebarLogo } from './BbysSidebarLogo';

const DepartingResidenceInfoSidebar = () => {
  const brandTheme = useBrandTheme();

  return (
    <>
      <BbysSidebarLogo />
      <Box mt="16px" height="100%">
        <Box
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          gap="16px"
          flexDirection="column"
          mt="15%"
        >
          <MultiColorIcon.House2
            colors={brandTheme.colors}
            containerProps={{ maxWidth: '260px' }}
          />
          <Paragraph
            variant="text-small"
            textTransform="uppercase"
            color="coolGray3"
          >
            Departing Residence Info
          </Paragraph>
        </Box>
      </Box>
    </>
  );
};

export default DepartingResidenceInfoSidebar;
