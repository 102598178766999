import { z } from 'zod';

import { useEffect, useState } from 'react';

import {
  Icon,
  RadioTabs,
  ResponsiveDisplay,
  SimpleLink,
  SlideDown,
} from '@hl-portals/ui';
import { Box, Button, FadeIn, Inputs, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { isFullName, isValidEmail, isValidPhone } from '@hl-portals/helpers';

import { ERRORS } from '../helpers';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';

export const clientInformationSchema = {
  primary_client_name: z
    .string({
      required_error: ERRORS.required('name'),
    })
    .refine((value) => isFullName(value), {
      message: ERRORS.required('full name'),
    }),
  primary_client_email: z
    .string({
      required_error: ERRORS.required('email'),
    })
    .refine((value) => isValidEmail(value), ERRORS.valid('email')),
  primary_client_phone: z
    .string({
      required_error: ERRORS.required('phone number'),
    })
    .refine((value) => isValidPhone(value), {
      message: ERRORS.valid('phone number'),
    }),
  additional_client_name: z
    .string({
      required_error: ERRORS.required('name'),
    })
    .refine((value) => isFullName(value), {
      message: ERRORS.required('full name'),
    })
    .optional(),
  additional_client_email: z
    .string({
      required_error: ERRORS.required('email'),
    })
    .refine((value) => isValidEmail(value), ERRORS.valid('email'))
    .optional(),
  additional_client_phone: z
    .string()
    .refine((value) => !value || isValidPhone(value), {
      message: ERRORS.valid('phone number'),
    }),
  under_contract: z.boolean().optional(),
};

const YES_NO_OPTIONS = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const ClientInformation = (): React.ReactElement => {
  const {
    formContext: {
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    },
    goNext,
    validate,
    formState,
  } = useStepUtils('client_information');

  const [additionalClient, setAdditionalClient] = useState(
    Boolean(formState.additional_client_name)
  );

  // HACK - for some reason these validations are run before
  //        the inputs are touched, so clearing errors on load
  //        for now
  useEffect(() => {
    if (errors.primary_client_name || errors.primary_client_email)
      clearErrors(['primary_client_name', 'primary_client_email']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = validate(
    [
      'primary_client_name',
      'primary_client_email',
      'primary_client_phone',
      'under_contract',
      additionalClient ? 'additional_client_name' : '',
      formState.under_contract === 'yes' ? 'close_of_escrow_date' : null,
    ].filter(Boolean)
  );

  const updateAdditionalClient = (additionalClientExists) => () => {
    setAdditionalClient(additionalClientExists);

    if (!additionalClientExists) {
      setValue('additional_client_name', null);
      setValue('additional_client_email', null);
    }
  };

  return (
    <>
      <Step
        title="Client Information"
        description="Please include all title holders on the departing residence."
      >
        <Box gap="24px" flexDirection="column" width="100%">
          <Paragraph variant="text" fontWeight="600">
            Primary client
          </Paragraph>
          <Inputs.Text
            mode="onBlur"
            name="primary_client_name"
            label="Full Name"
            containerProps={{ flex: '1', width: '100%' }}
            placeholder="Enter client's full name"
            borderColor={theme.colors.coolGray4}
            key="primary_client_name"
            data-test="primary_client_name-input"
          />

          <Inputs.Email
            mode="onBlur"
            name="primary_client_email"
            label="Email Address"
            containerProps={{ flex: '1', width: '100%' }}
            placeholder="Enter client's email address"
            borderColor={theme.colors.coolGray4}
            key="primary_client_email"
            data-test="primary_client_email-input"
            hint="We'll reach out to you before contacting the client. We never send spam."
          />

          <Inputs.Phone
            mode="onBlur"
            name="primary_client_phone"
            label="Phone Number"
            containerProps={{ flex: '1', width: '100%' }}
            placeholder="Enter your phone number"
            borderColor={theme.colors.coolGray4}
            data-test="primary_client_phone-input"
            hint="We'll reach out to you before contacting the client. We never send spam."
          />
        </Box>

        {additionalClient && (
          <FadeIn
            gap="24px"
            flexDirection="column"
            width="100%"
            animationDelay=".0s"
          >
            <Paragraph variant="text" fontWeight="600">
              Additional client
            </Paragraph>

            <SlideDown
              bgcolor={theme.colors.gray50}
              p="12px"
              gap="8px"
              width={{ xs: '100%', md: 'calc(100% + 26px)' }}
              alignItems="flex-start"
              display={{ xs: 'inherit', md: 'none' }}
            >
              <Icon type="infoSolid" fill="electricBlue" mt="4px" />
              <Paragraph variant="text-small">
                If the property has more than two title holders please let us
                know in the notes section at the end of the application
              </Paragraph>
            </SlideDown>
            <Inputs.Text
              mode="onBlur"
              name="additional_client_name"
              label="Full Name (2)"
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter client's full name"
              borderColor={theme.colors.coolGray4}
              data-test="additional_client_name-input"
            />

            <Inputs.Email
              mode="onBlur"
              name="additional_client_email"
              label="Email Address (2)"
              optional
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter client's email address"
              borderColor={theme.colors.coolGray4}
              data-test="additional_client_email-input"
              hint="We'll reach out to you prior contacting the client. We never send spam."
            />

            <Inputs.Phone
              mode="onBlur"
              name="additional_client_phone"
              label="Phone Number (2)"
              optional
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter client's phone number"
              borderColor={theme.colors.coolGray4}
              data-test="additional_client_phone-input"
              hint="We'll reach out to you prior contacting the client. We never send spam."
            />
          </FadeIn>
        )}

        <Box
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          gap="8px"
          width="100%"
        >
          {additionalClient ? (
            <>
              <SimpleLink
                onClick={updateAdditionalClient(false)}
                fontSize="16px"
                width="100%"
                textAlign="right"
                color={theme.colors.aaBlue}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Icon type="trash" color="aaBlue" size={14} mr="6px" />
                Remove Client
              </SimpleLink>
            </>
          ) : (
            <ResponsiveDisplay
              display="flex"
              gap="6px"
              alignItems="center"
              width="100%"
              justifyContent="flex-end"
              mobile={
                <>
                  <Icon type="addUser" fill="aaBlue" />
                  <SimpleLink
                    fontSize="16px"
                    data-test="add-client-button"
                    onClick={updateAdditionalClient(true)}
                    color={theme.colors.aaBlue}
                  >
                    Add Client
                  </SimpleLink>
                </>
              }
              desktop={
                <>
                  <Box
                    gap="6px"
                    alignItems="center"
                    onClick={updateAdditionalClient(true)}
                    cursor="pointer"
                  >
                    <Icon type="addUser" fill="aaBlue" />
                  </Box>
                  <SimpleLink
                    fontSize="16px"
                    data-test="add-client-button"
                    onClick={updateAdditionalClient(true)}
                    color={theme.colors.aaBlue}
                  >
                    Add Client
                  </SimpleLink>
                </>
              }
            />
          )}
        </Box>
        {additionalClient && (
          <SlideDown
            bgcolor={theme.colors.gray50}
            p="12px"
            gap="8px"
            width={{ xs: '100%', md: 'calc(100% + 26px)' }}
            alignItems="flex-start"
            display={{ xs: 'none', md: 'inherit' }}
          >
            <Icon type="infoSolid" fill="electricBlue" mt="4px" />
            <Paragraph variant="text-small">
              If the property has more than two title holders please let us know
              in the notes section at the end of the application
            </Paragraph>
          </SlideDown>
        )}

        <Box
          height="1px"
          bgcolor={theme.colors.coolGray4}
          width="100%"
          display={{ xs: 'none', md: 'flex' }}
        />

        <Box
          flexDirection="column"
          gap="32px"
          width="100%"
          data-test="under-contract-group"
        >
          <Box flexDirection="column" gap="16px">
            <Paragraph variant="heading-4">Contracts</Paragraph>
            <Paragraph variant="text-small">
              Is the client currently under contract on a new property?
            </Paragraph>
            <Box flexDirection="column" gap="8px" width="100%">
              <RadioTabs
                maxWidth="100%"
                name="under_contract"
                onChange={(option) => {
                  setValue('under_contract', option);
                  if (option === 'no') setValue('close_of_escrow_date', null);
                }}
                tabs={YES_NO_OPTIONS}
                selected={formState.under_contract}
              />
            </Box>
          </Box>
          {formState.under_contract === 'yes' && (
            <Inputs.Date
              containerProps={{
                maxWidth: { xs: '100%', md: '300px' },
              }}
              label="What is the Close of Escrow Date?"
              name="close_of_escrow_date"
              setError={setError}
            />
          )}
        </Box>
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
        progressColor={'electricBlue'}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
