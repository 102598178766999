import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

import {
  Box,
  Icon,
  Paragraph,
  PopIn,
  Spinner,
  useBrandTheme,
} from '@hl-portals/ui';

import { calculatorLoadingJson, theme } from '@hl-portals/constants';

const randomIntBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const EquityCalculationLoading = () => {
  const [loadingState, setLoadingState] = useState<
    Record<symbol | string, 'loading' | 'pending' | 'done'>
  >({
    eligibility: 'loading',
    valuation: 'pending',
    calculation: 'pending',
  });

  const brandTheme = useBrandTheme();

  const steps = [
    {
      label: 'Verifying property eligibility',
      status: loadingState.eligibility,
    },
    {
      label: 'Consulting valuation models',
      status: loadingState.valuation,
    },
    {
      label: 'Estimating Equity Unlock Amount',
      status: loadingState.calculation,
    },
  ];

  const startLoadingTimeouts = () => {
    setLoadingState({
      eligibility: 'loading',
      valuation: 'pending',
      calculation: 'pending',
    });

    setTimeout(() => {
      setLoadingState({
        eligibility: 'done',
        valuation: 'loading',
        calculation: 'pending',
      });

      setTimeout(() => {
        setLoadingState({
          eligibility: 'done',
          valuation: 'done',
          calculation: 'loading',
        });
      }, randomIntBetween(500, 1000));
    }, randomIntBetween(500, 1000));
  };

  useEffect(() => {
    startLoadingTimeouts();
  }, []);

  return (
    <Box
      width="100%"
      height="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      m="0 auto"
    >
      <Box width="120px">
        <Lottie loop animationData={calculatorLoadingJson} play />
      </Box>

      {steps && (
        <Box gap="40px" flexDirection="column">
          {steps.map((step) => (
            <Box key={step.label} gap="8px" alignItems="center">
              {step.status === 'loading' && <Spinner md />}
              {step.status === 'pending' && (
                <Spinner md paused color={theme.colors.gray500} />
              )}
              {step.status === 'done' && (
                <PopIn
                  borderRadius="50%"
                  width="24px"
                  height="24px"
                  bgcolor={
                    brandTheme?.colors?.primary || theme.colors.electricBlue
                  }
                  justifyContent="center"
                  alignItems="center"
                  animationDelay="0"
                >
                  <Icon type="check" fill="white" size={16} />
                </PopIn>
              )}
              <Paragraph
                variant="text-bold"
                opacity={
                  step.status === 'done'
                    ? '1'
                    : step.status === 'loading'
                    ? '.6'
                    : '.4'
                }
              >
                {step.label}
              </Paragraph>
            </Box>
          ))}
        </Box>
      )}
      <Paragraph variant="text" mt="130px" color="gray500">
        Please don't close the window.
      </Paragraph>
    </Box>
  );
};
