/* eslint-disable react/no-unescaped-entities */
import { Box, FadeIn, Paragraph } from '@hl-portals/ui';

import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import { useFlags } from 'flagsmith/react';

export const LightApprovalSuccess = (): React.ReactElement => {
  const {
    'remove-property-questionnaire-bbys': {
      enabled: removePropertyQuestionnaireBbys,
    },
  } = useFlags(['remove-property-questionnaire-bbys']);
  useStepUtils('success');

  return (
    <Box flexDirection="column" maxWidth="1280px" margin="0 auto">
      <FloatingFAQButton />
      <FadeIn
        animationDelay={'0s'}
        flexDirection={{ xs: 'column', md: 'row' }}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        gap="48px"
      >
        <FadeIn
          flex="1"
          animationDelay={'.2s'}
          flexDirection="column"
          gap="32px"
          maxWidth="625px"
        >
          <Box flexDirection="column" gap="16px">
            <Paragraph
              variant="heading-5"
              textTransform="uppercase"
              color="gray500"
              textAlign="left"
              letterSpacing="2px"
            >
              Application Submitted
            </Paragraph>
            <Paragraph
              variant="heading-1"
              textAlign="left"
              fontSize={{ xs: '24px', md: '32px' }}
              maxWidth="450px"
            >
              HomeLight is reviewing the application
            </Paragraph>
          </Box>
          <Paragraph variant="text">
            It's time to relax! We have all the information we need, we will not
            require property photos{removePropertyQuestionnaireBbys ? '' : ' or a questionnaire'} from the listing agent.
          </Paragraph>
          <Paragraph variant="text">
            Our team is already evaluating the property, we'll reach out within
            <strong> 24 hours</strong> with more information.
          </Paragraph>
        </FadeIn>
        <FadeIn
          animationDelay={'.4s'}
          position="relative"
          flex="1"
          justifyContent={{ xs: 'flex-end', md: 'center' }}
          minHeight={{ xs: '150px', md: '224px' }}
          minWidth="320px"
        >
          <Box
            as="img"
            src="/illustrations/bbys-lead-submission/reviewing_application.png"
            position={{ xs: 'absolute', md: 'static' }}
            bottom="0px"
            right="0px"
            pointerEvents="none"
            width={{ xs: '220px', md: 'auto' }}
            mt={{ xs: '25px', md: '0' }}
          />
        </FadeIn>
      </FadeIn>
      <Box
        as="img"
        src="/illustrations/bbys-lead-submission/dashed_accent2.png"
        position="fixed"
        right={{ xs: '-180px', md: '0px' }}
        top={{ xs: '-300px', md: '0px' }}
        zIndex="-1"
      />
    </Box>
  );
};
