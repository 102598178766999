import { z } from 'zod';

import { useEffect } from 'react';

import {
  Box,
  Button,
  Icon,
  Paragraph,
  RadioTabs,
  SimpleLink,
  SlideDown,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useModal } from '@hl-portals/hooks';

import { useStepUtils } from '../../hooks';
import { FloatingFAQButton } from '../../layout/FloatingFAQButton';
import Footer from '../../layout/Footer';
import Step from '../../layout/Step';
import { LenderInformationInputs } from '../InputSets';
import { RolesModal } from './RolesModal';

export const agentLenderInformationSchema = {
  lender_present: z.enum(['yes', 'no']),
};

const YES_NO_OPTIONS = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const LenderInformation = (): React.ReactElement => {
  const {
    formState,
    validate,
    formContext: { setValue },
    goNext,
  } = useStepUtils('agent_lender_information');

  useEffect(() => {
    if (
      formState.flow === 'externalLeadSubmitter' &&
      ['mortgage', 'unsure'].includes(formState.finance_type)
    ) {
      setValue('lender_present', 'yes');
    }
  }, []);

  const { openModal } = useModal();

  const isValid = validate(
    formState.lender_present === 'yes' ||
      (formState.flow === 'externalLeadSubmitter' &&
        ['mortgage', 'unsure'].includes(formState.finance_type))
      ? [
          'lender_company_name',
          'loan_officer_name',
          'loan_officer_email',
          'loan_officer_phone',
        ]
      : ['lender_present']
  );

  const showModal = () => {
    openModal(<RolesModal />, {
      wrapperClassName: 'roles-modal__wrapper',
      asDrawer: true,
    });
  };

  const onChangeOption = (option) => {
    setValue('lender_company_name', null);
    setValue('loan_officer_name', null);
    setValue('loan_officer_email', null);
    setValue('loan_officer_phone', null);
    setValue('agent_lender_present', null);

    setValue('lender_present', option);
  };

  return (
    <>
      {formState.flow !== 'externalLeadSubmitter' && (
        <Step
          title="Loan Officer Info"
          description="Is your client already working with a loan officer?"
        >
          <Box gap="16px" flexDirection="column" width="100%">
            <RadioTabs
              name="lender_present"
              onChange={onChangeOption}
              tabs={YES_NO_OPTIONS}
              selected={formState.lender_present}
            />
            {formState.lender_present === 'yes' && (
              <Box flexDirection="column" gap="32px">
                <LenderInformationInputs />
              </Box>
            )}
            <SimpleLink
              color={theme.colors.aaBlue}
              display="flex"
              onClick={showModal}
            >
              Why do I need a loan officer?
              <Icon type="info" fill="aaBlue" ml="4px" />
            </SimpleLink>
          </Box>
        </Step>
      )}

      {formState.flow === 'externalLeadSubmitter' &&
        ['mortgage', 'unsure'].includes(formState.finance_type) && (
          <Step
            title="Loan Officer Info"
            description="Please add the information of the loan officer"
          >
            <Box gap="16px" flexDirection="column" width="100%">
              <SlideDown
                bgcolor={theme.colors.gray50}
                p="12px"
                gap="8px"
                width={{ xs: '100%', md: 'calc(100% + 26px)' }}
                alignItems="flex-start"
              >
                <Icon type="infoSolid" fill="electricBlue" />
                <Paragraph variant="text-small">
                  We'll use this info to send task reminders and notifications
                </Paragraph>
              </SlideDown>
              <Box flexDirection="column" gap="32px" pt="24px">
                <LenderInformationInputs />
              </Box>

              <SimpleLink
                color={theme.colors.aaBlue}
                display="flex"
                onClick={showModal}
              >
                Why do I need a loan officer?
                <Icon type="info" fill="aaBlue" ml="4px" />
              </SimpleLink>
            </Box>
          </Step>
        )}

      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
        progressColor={'electricBlue'}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
