import { z } from 'zod';

import { useEffect } from 'react';

import { Box, Button, Inputs, Paragraph } from '@hl-portals/ui';

import { formatCurrency } from '@hl-portals/helpers';

import { leadSource } from '../constants';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';
import { AdditionalLiens } from './InputSets';

export const clientFinancialInformationSchema = {
  confirm_mortgage_balance: z.string().optional(),
  additional_liens_exist: z.boolean().optional(),
  additional_liens: z
    .array(
      z.object({
        lien_type: z.enum(['heloc', 'solar_lien', 'reverse_mortgage', 'other']),
        lien_balance: z.string(),
      })
    )
    .optional(),
  close_of_escrow_date: z.string().optional(),
  target_equity_unlock: z.string().optional(),
};

export const ClientFinancialDetails = (): React.ReactElement => {
  const {
    formContext: { setValue },
    formState,
    validate,
    goNext,
    snapshot,
  } = useStepUtils('client_financial_details');

  useEffect(() => {
    setValue(
      'confirm_mortgage_balance',
      formatCurrency({
        value: formState?.equity_unlock_result?.estimated_loan_payoff || '',
        raw: true,
      }),
      { shouldValidate: false }
    );
  }, [setValue, formState?.equity_unlock_result?.estimated_loan_payoff]);

  const isValid =
    validate(
      [
        'confirm_mortgage_balance',
        'additional_liens_exist',
        formState.additional_liens_exist === 'yes' ? 'additional_liens' : null,
      ].filter(Boolean)
    ) &&
    (parseInt(formState.target_amount_radio) || formState.target_equity_unlock);

  const getMortgageSrc = () => {
    if (snapshot.pre_lead?.id) {
      return leadSource[snapshot.lead_source];
    } else {
      return !formState.mortgage_balance
        ? 'our services'
        : leadSource[formState.flow];
    }
  };

  return (
    <>
      <Step title="Financial Information">
        <Inputs.Currency
          name="target_equity_unlock"
          label={
            <Paragraph variant="text-small">
              Specify the Equity Unlock amount your client(s) needs to close on
              their incoming residence
              <Paragraph as="span" variant="text-small" color="gray600">
                {' '}
                (Target Equity Unlock Amount)
              </Paragraph>
            </Paragraph>
          }
          placeholder="Enter target amount"
          testId="target_equity_unlock-input"
        />
        <Box flexDirection="column" gap="16px" width="100%">
          <Box flexDirection="column" gap="8px" width="100%">
            <Inputs.Currency
              name="confirm_mortgage_balance"
              data-test="confirm_mortgage_balance-input"
              label="Verify mortgage balance"
            />
            <Paragraph variant="text-xsmall" color="gray600">
              Value provided by {getMortgageSrc()}.
            </Paragraph>
          </Box>
        </Box>
        <AdditionalLiens />
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
