import { z } from 'zod';

import { useEffect, useMemo } from 'react';

import {
  Box,
  Button,
  Icon,
  Inputs,
  Paragraph,
  RadioTabs,
  SimpleLink,
  SlideDown,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { isFullName, isValidEmail, isValidPhone } from '@hl-portals/helpers';

import { useModal } from '@hl-portals/hooks';

import { ERRORS } from '../helpers';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';
import { RolesModal } from './Agent/RolesModal';

export const agentInformationSchema = {
  agent_present: z.enum(['yes', 'no']),
  agent_name: z
    .string()
    .optional()
    .refine((value) => (value ? isFullName(value) : true), {
      message: ERRORS.required('full name'),
    }),
  agent_email: z
    .string()
    .optional()
    .refine(
      (value) => (value ? isValidEmail(value) : true),
      ERRORS.valid('email')
    ),
  agent_phone: z
    .string()
    .optional()
    .refine(
      (value) => (value !== '(___) ___-____' ? isValidPhone(value) : true),
      {
        message: ERRORS.valid('phone number'),
      }
    ),
};

const YES_NO_OPTIONS = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const AgentInformation = (): React.ReactElement => {
  const {
    formState,
    validate,
    formContext: { setValue },
    goNext,
  } = useStepUtils('agent_information');

  useEffect(() => {
    if (
      (formState.agent_name &&
        formState.agent_email &&
        formState.agent_present !== 'yes') ||
      formState.flow === 'externalLeadSubmitter'
    ) {
      setValue('agent_present', 'yes');
    }
  }, [
    setValue,
    formState.agent_name,
    formState.agent_email,
    formState.agent_present,
    formState.flow,
  ]);

  const { openModal } = useModal();

  const showModal = () => {
    openModal(<RolesModal />, {
      wrapperClassName: 'roles-modal__wrapper',
      asDrawer: true,
    });
  };

  const isValid = useMemo(() => {
    if (formState.flow === 'externalLeadSubmitter') return true;

    let requiredFields = [];

    if (formState.agent_present === 'yes') {
      requiredFields = ['agent_name', 'agent_email'];

      if (formState.agent_phone) {
        requiredFields.push('agent_phone');
      }
    } else {
      requiredFields = ['agent_present'];
    }

    return validate(requiredFields);
  }, [
    formState.flow,
    formState.agent_present,
    formState.agent_phone,
    validate,
  ]);

  return (
    <>
      {formState.flow !== 'externalLeadSubmitter' && (
        <Step
          title="Agent Information"
          description="Is the client working with an agent for the sale of the departing residence?"
        >
          <Box gap="16px" flexDirection="column" width="100%">
            <RadioTabs
              maxWidth={{ xs: '100%', md: '300px' }}
              name="agent_present"
              onChange={(option) => setValue('agent_present', option)}
              tabs={YES_NO_OPTIONS}
              selected={formState.agent_present}
            />
            {['yes', 'no'].includes(formState.agent_present) && (
              <SlideDown
                bgcolor={theme.colors.gray50}
                p="12px"
                gap="8px"
                width={{ xs: '100%', md: 'calc(100% + 26px)' }}
                alignItems="flex-start"
              >
                <Icon type="infoSolid" fill="electricBlue" mt="4px" />
                <Paragraph variant="text-small">
                  {formState.agent_present === 'yes'
                    ? "We'll use the agent info to send task reminders and notifications"
                    : "Please note that the program requires a licensed real estate agent for the sale of the departing residence. You can continue with the submission and we'll ask for this information later."}
                </Paragraph>
              </SlideDown>
            )}
            {formState.agent_present === 'yes' && (
              <Box flexDirection="column" gap="32px">
                <Inputs.Text
                  mode="onBlur"
                  name="agent_name"
                  label="Agent's Full Name"
                  containerProps={{ flex: '1', width: '100%' }}
                  placeholder="Enter agent's full name"
                  borderColor={theme.colors.coolGray4}
                  key="agent_name"
                  data-test="agent_name-input"
                />

                <Inputs.Email
                  mode="onBlur"
                  name="agent_email"
                  label="Agent's Email Address"
                  containerProps={{ flex: '1', width: '100%' }}
                  placeholder="Enter agent's email address"
                  borderColor={theme.colors.coolGray4}
                  key="agent_email"
                  data-test="agent_email-input"
                />

                <Inputs.Phone
                  mode="onBlur"
                  name="agent_phone"
                  label="Agent's Phone Number"
                  containerProps={{
                    flex: '1',
                    width: '100%',
                    optional: true,
                  }}
                  placeholder="Enter agent's phone number"
                  borderColor={theme.colors.coolGray4}
                  data-test="agent_phone-input"
                />
              </Box>
            )}
          </Box>
        </Step>
      )}

      {formState.flow === 'externalLeadSubmitter' && (
        <Step
          title="Agent Information"
          description="Please add the information of the departing residence agent"
        >
          <Box gap="16px" flexDirection="column" width="100%">
            <SlideDown
              bgcolor={theme.colors.gray50}
              p="12px"
              gap="8px"
              width={{ xs: '100%', md: 'calc(100% + 26px)' }}
              alignItems="flex-start"
            >
              <Icon type="infoSolid" fill="electricBlue" />
              <Paragraph variant="text-small">
                We&apos;ll use this info to send task reminders and
                notifications
              </Paragraph>
            </SlideDown>
            {formState.agent_present === 'yes' && (
              <Box flexDirection="column" gap="32px" pt="24px">
                <Inputs.Text
                  mode="onBlur"
                  name="agent_name"
                  label="Agent's Full Name"
                  containerProps={{ flex: '1', width: '100%' }}
                  placeholder="Enter agent's full name"
                  borderColor={theme.colors.coolGray4}
                  key="agent_name"
                  data-test="agent_name-input"
                />

                <Inputs.Email
                  mode="onBlur"
                  name="agent_email"
                  label="Agent's Email Address"
                  containerProps={{ flex: '1', width: '100%' }}
                  placeholder="Enter agent's email address"
                  borderColor={theme.colors.coolGray4}
                  key="agent_email"
                  data-test="agent_email-input"
                />

                <Inputs.Phone
                  mode="onBlur"
                  name="agent_phone"
                  label="Agent's Phone Number"
                  containerProps={{
                    flex: '1',
                    width: '100%',
                    optional: true,
                  }}
                  placeholder="Enter agent's phone number"
                  borderColor={theme.colors.coolGray4}
                  data-test="agent_phone-input"
                />

                <SimpleLink
                  color={theme.colors.aaBlue}
                  display="flex"
                  onClick={showModal}
                >
                  Why do I need a agent?
                  <Icon type="info" fill="aaBlue" ml="4px" />
                </SimpleLink>
              </Box>
            )}
          </Box>
        </Step>
      )}

      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
        progressColor={'electricBlue'}
      >
        <Button
          height="48px"
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
          disabled={!isValid}
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
