import { useRouter } from 'next/router';

import {
  Box,
  Button,
  HeaderLogo,
  Icon,
  Paragraph,
  ResponsiveDisplay,
  WarningDialog,
  useBrandTheme,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useModal, useScreenSize } from '@hl-portals/hooks';

import { getFlow, getStepInfo } from '../constants';
import { startOver } from '../helpers';
import { FAQ_LINK, useFAQ, useStepUtils } from '../hooks';
import { ProgressBar } from './ProgressBar';

const MobileHeader = () => {
  const { multiStep, goBack, formState } = useStepUtils();
  const { openFAQ } = useFAQ(multiStep.currentStep);
  const brandTheme = useBrandTheme();
  const currentStepInfo = getStepInfo(multiStep.currentStep, formState.flow);
  const router = useRouter();
  const { openModal } = useModal();

  let headerTitle = 'Buy Before You Sell';

  if (currentStepInfo && !multiStep.isFirstStep && !multiStep.isLastStep) {
    headerTitle = currentStepInfo.title;
  }

  return (
    <>
      <Box position="absolute" left="16px">
        {!multiStep.isFirstStep && !multiStep.isLastStep && (
          <Icon flip type="arrowRight" onClick={goBack} />
        )}
      </Box>
      <Paragraph
        variant="text-small"
        textAlign="center"
        width="100%"
        fontWeight={700}
      >
        {headerTitle}
      </Paragraph>
      <Box position="absolute" right="4px">
        <Button
          variant="text"
          href={FAQ_LINK}
          onClick={openFAQ}
          iconRight={
            <Icon
              fill={brandTheme.colors?.primary || 'electricBlue'}
              type="externalLink"
              size={16}
            />
          }
        >
          FAQs
        </Button>
        {router.query.return && (
          <Icon
            type="times"
            onClick={() =>
              openModal(
                <WarningDialog
                  acceptFn={() =>
                    router.push(
                      window.decodeURIComponent(router.query.return as string)
                    )
                  }
                  acceptText="Leave"
                  declineText="Cancel"
                  warningText="Are you sure you want to leave?"
                  warningDescription="All progress will be lost"
                  buttonsProps={[{}, { color: 'urgent' }]}
                />,
                { asDrawer: true }
              )
            }
          />
        )}
      </Box>
      {!multiStep.isFirstStep && (
        <ProgressBar
          progress={multiStep.percentProgress}
          position="absolute"
          left="0"
          right="0"
          bottom="-6px"
          progressColor={'electricBlue'}
        />
      )}
    </>
  );
};

const Header = () => {
  const { multiStep, formContext } = useStepUtils();
  const { isDesktop } = useScreenSize();
  const router = useRouter();
  const flow = getFlow(router);

  const isLastStep = multiStep.isLastStep;
  const brandTheme = useBrandTheme();

  const submitNewClient = () => {
    formContext.reset({
      flow,
      submitted_step: '',
    });

    startOver(router)();
  };

  const { openModal } = useModal();

  return (
    <Box
      position={isLastStep && isDesktop ? 'static' : 'sticky'}
      top="0"
      left="0"
      right="0"
      flexDirection="column"
      p={{ xs: '16px', md: multiStep.isLastStep ? '12px 80px' : '0 80px' }}
      paddingBottom={multiStep.isFirstStep ? '16px' : '22px'}
      bgcolor={theme.colors.white}
      borderBottom={`1px solid ${theme.colors.stoneGray}`}
      zIndex="10"
    >
      <ResponsiveDisplay
        alignItems="center"
        justifyContent={'space-between'}
        width="100%"
        mobile={
          <>
            <MobileHeader />
          </>
        }
        desktop={
          router.query.return ? (
            <Box width="100%" justifyContent="flex-end">
              <Icon
                type="times"
                m="12px 0"
                size={24}
                fill="darkBlue"
                onClick={() =>
                  isLastStep
                    ? openModal(
                        <WarningDialog
                          acceptFn={() =>
                            router.push(
                              window.decodeURIComponent(
                                router.query.return as string
                              )
                            )
                          }
                          acceptText="Ok"
                          hideDeclineButton
                          warningText="Taking you to the Agent Portal"
                          warningDescription="You can always access  this information from your confirmation email"
                        />
                      )
                    : openModal(
                        <WarningDialog
                          acceptFn={() =>
                            router.push(
                              window.decodeURIComponent(
                                router.query.return as string
                              )
                            )
                          }
                          acceptText="Leave"
                          declineText="Cancel"
                          warningText="Are you sure you want to leave?"
                          warningDescription="All progress will be lost"
                          buttonsProps={[{}, { color: 'urgent' }]}
                        />
                      )
                }
              />
            </Box>
          ) : isLastStep ? (
            <>
              {brandTheme?.logoUrl ? (
                <Box as="img" src={brandTheme.logoUrl} width="160px" />
              ) : (
                <HeaderLogo
                  shouldUseTextLogo
                  target="_blank"
                  url="https://lender.homelight.com/lenders/general"
                />
              )}

              <Box>
                <Button
                  onClick={submitNewClient}
                  fontSize="16px"
                  height="48px"
                  p="0 24px !important"
                >
                  Submit New Client
                </Button>
              </Box>
            </>
          ) : null
        }
      />
    </Box>
  );
};

export default Header;
