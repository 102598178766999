import { RouterQuery } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFlowSteps = (steps, opt = {}) => {
  const {
    LenderInformation,
    DepartingResidenceInfo,
    ProgramEligibilityCheck,
    EquityUnlockEstimation,
    ClientInformation,
    ClientFinancialDetails,
    AgentInformation,
    Confirmation,
    Success,
  } = steps;

  return [
    LenderInformation,
    DepartingResidenceInfo,
    ProgramEligibilityCheck,
    EquityUnlockEstimation,
    ClientFinancialDetails,
    ClientInformation,
    AgentInformation,
    Confirmation,
    Success,
  ];
};

export const ERRORS = {
  required: (label) => `Please enter a ${label}`,
  valid: (label) => `Please enter a valid ${label}`,
};

export type SourceParams = {
  source: string;
  source_page_type: string;
  source_form: string;
};

export type BbysUuidSubmissionParams = SourceParams & {
  uuid: string;
};

export type LenderBBYSLeadSubmissionParams = SourceParams & {
  client_name: string;
  client_email: string;
  client_phone: string;
  agent_email?: string;
  agent_name?: string;
  agent_phone?: string;
  property_uuid: string;
  property_state: string;
  property_unit?: string;
  agent_valuation: string;
  agent_estimated_loan_payoff: string;
  target_unlock_amount: string;
  primary_residence: string;
  no_age_restricted_community: boolean;
  no_va_fha_requirement: string;
  credit_score: string;
  automated_bbys_check_success: boolean;
  lender_company: string;
  lender_name: string;
  lender_phone: string;
  lender_email: string;
  has_secondary_loans: boolean;
  is_client_under_contract_on_new_property: boolean;
  additional_client_name?: string;
  additional_client_email?: string;
  additional_client_phone?: string;
  secondary_loans?: {
    type: string;
    balance: string;
  }[];
  new_property_close_of_escrow_date: string;
  notes?: string;
  partner_slug: string;
  sales_consultant_email?: string;
  sales_consultant_name?: string;
  sales_consultant_phone?: string;
  lender_lead_snapshot_id: string;
  source_lead_id: string;
  division?: string;
  finance_type?: string;
  uuid: string;
  external_escrow_officer_name: string;
  external_escrow_officer_email: string;
};

export const getSubmitParams = (formState): LenderBBYSLeadSubmissionParams => {
  let source_form = 'public_lead_submission_page';

  if (formState.flow === 'agentSubmission') {
    source_form = 'public_agent_lead_submission_page';
  }

  return {
    source: 'web',
    source_page_type: 'equity_app',
    source_form,
    property_uuid: formState.property_uuid,
    property_state: formState.property_state,
    property_unit: formState.property_unit,
    agent_valuation: formState.eligibility_result?.agent_valuation,
    agent_estimated_loan_payoff:
      formState.eligibility_result?.agent_estimated_loan_payoff,
    target_unlock_amount: formState.target_equity_unlock?.replace(/\D/g, ''),
    lender_company: formState.lender_company_name,
    lender_name: formState.loan_officer_name,
    lender_phone: formState.loan_officer_phone,
    lender_email: formState.loan_officer_email,
    primary_residence: 'true',
    no_age_restricted_community: true,
    no_va_fha_requirement: 'true',
    credit_score: 'true',
    automated_bbys_check_success: (
      formState.automated_bbys_eligibility_check || false
    ).toString(),
    client_name: formState.primary_client_name,
    client_email: formState.primary_client_email,
    client_phone: formState.primary_client_phone,
    agent_email: formState.agent_email,
    agent_name: formState.agent_name,
    agent_phone: formState.agent_phone,
    has_secondary_loans: formState.additional_liens_exist === 'yes',
    is_client_under_contract_on_new_property:
      formState.under_contract === 'yes',
    additional_client_name: formState.additional_client_name,
    additional_client_email: formState.additional_client_email,
    additional_client_phone: formState.additional_client_phone,
    secondary_loans: formState.additional_liens?.length
      ? formState.additional_liens.map((al) => ({
          type: al.lien_type,
          balance: al.lien_balance,
        }))
      : null,
    new_property_close_of_escrow_date: formState.close_of_escrow_date,
    notes: formState.notes,
    partner_slug: formState.partner_slug,
    sales_consultant_email: formState.sales_consultant_email,
    sales_consultant_name: formState.sales_consultant_name,
    sales_consultant_phone: formState.sales_consultant_phone,
    lender_lead_snapshot_id: formState.snapshot_id,
    source_lead_id: formState.source_lead_id,
    division: formState.division,
    finance_type: formState.finance_type || 'mortgage',
    uuid: formState.snapshot_uuid,
    external_escrow_officer_name: formState.external_escrow_officer_name,
    external_escrow_officer_email: formState.external_escrow_officer_email,
  };
};

type GetLoanOfficerQueryParamsResult =
  | {
      lender_company_name?: string;
      loan_officer_name?: string;
      loan_officer_email?: string;
      loan_officer_phone?: string;
    }
  | {};

export const getLoanOfficerQueryParams = (
  routerQuery: RouterQuery
): GetLoanOfficerQueryParamsResult => {
  const {
    lenderCompanyName,
    loanOfficerName,
    loanOfficerEmail,
    loanOfficerPhone,
  } = routerQuery;

  if (
    lenderCompanyName ||
    loanOfficerName ||
    loanOfficerEmail ||
    loanOfficerPhone
  ) {
    return {
      lender_company_name: lenderCompanyName,
      loan_officer_name: loanOfficerName,
      loan_officer_email: loanOfficerEmail,
      loan_officer_phone: loanOfficerPhone,
    };
  }

  return {};
};

export const startOver = (router) => () => {
  const { step, partnerSlug, uuid, ...queryParams } = router.query;

  router.push({
    pathname: partnerSlug ? `/bbys/new/${partnerSlug}` : `/bbys/new`,
    query: { ...queryParams, step: 'Lead Information' },
  });
};

export const financeTypeMap = {
  mortgage: 'Mortgage',
  cash: 'All cash purchase',
  unsure: 'Unsure',
};
