import { useFlags } from 'flagsmith/react';
import { z } from 'zod';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Inputs, Paragraph } from '@hl-portals/ui';

import { getFullAddress, getFullAddressFromForm } from '@hl-portals/helpers';

import {
  useAutomatedBbysEligibilityCheck,
  usePropertyUUID,
} from '@hl-portals/hooks';

import { ERRORS } from '../helpers';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';

export const departingResidenceSchema = {
  full_address: z.string({
    required_error: ERRORS.required('property address'),
  }),
  property_address: z.string({
    required_error: ERRORS.required('property address'),
  }),
  property_state: z.string({
    required_error: ERRORS.required('property address'),
  }),
  property_city: z.string({
    required_error: ERRORS.required('property address'),
  }),
  property_postal_code: z.string({
    required_error: ERRORS.required('property address'),
  }),
  property_unit: z.string(),
  property_uuid: z.string(),
  estimated_home_value: z.string({
    required_error: ERRORS.required('estimated home value'),
  }),
  mortgage_balance: z.string().optional(),
  target_amount_radio: z.string().min(1),
  automated_bbys_eligibility_check: z.boolean().default(false),
};

export const DepartingResidenceInfo = (): React.ReactElement => {
  const {
    formContext: {
      setValue,
      clearErrors,
      setError,
      formState: { errors },
    },
    formState,
    goNext,
    updateSnapshot,
    snapshot,
  } = useStepUtils('departing_residence_information');

  const [loading, setLoading] = useState(false);
  const [hasAcknowledged, setHasAcknowledged] = useState(false);

  useEffect(() => {
    if (errors.property_address || errors.estimated_home_value)
      clearErrors(['property_address', 'estimated_home_value']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    'automated-eligibility-improvements': {
      enabled: automatedEligibilityImprovements,
    },
    'value-provided-by-agent-button': { enabled: valueProvidedByAgentButton },
  } = useFlags([
    'automated-eligibility-improvements',
    'value-provided-by-agent-button',
  ]);

  const { isLoading: isRunningChecks, mutate: runEligibilityChecks } =
    useAutomatedBbysEligibilityCheck({
      onSuccess: (result) => {
        const issues = Object.keys(result.eligibility_checks || {}).filter(
          (k) => !result.eligibility_checks[k]
        );

        const denials = Object.keys(result.denial_checks || {}).filter(
          (k) => result.denial_checks[k]
        );
        const warnings = Object.keys(result.warning_checks).filter(
          (k) => result.warning_checks[k]
        );

        if (automatedEligibilityImprovements) {
          const isDenied = denials.length > 0;

          setValue('automated_bbys_eligibility_check', !warnings.length);
          setValue('denied', isDenied);

          updateSnapshot({
            ...snapshot,
            denied: isDenied,
            denial_checks: result.denial_checks,
            submitted_step: 'departing_residence_information',
          });
        } else {
          setValue('automated_bbys_eligibility_check', !issues.length, {
            shouldValidate: false,
          });
        }

        setValue('eligibility_result', result, { shouldValidate: false });
        setHasAcknowledged(true);
        goNext();
      },
      onClose: (result) => {
        if (automatedEligibilityImprovements) {
          updateSnapshot({
            ...snapshot,
            eligibility_result: result,
            submitted_step: 'departing_residence_information',
          });
        }
        setValue('eligibility_result', result, { shouldValidate: false });
        setLoading(false);
        setHasAcknowledged(false);
      },
      onError: () => {
        toast('There was an error with property eligibility', {
          type: 'error',
        });
        setLoading(false);
      },
    });

  const { isFetching: isFetchingProperty, refetch: fetchPropertyUuid } =
    usePropertyUUID(getFullAddressFromForm(formState), {
      onSuccess: (uuid) => {
        setValue('property_uuid', uuid, { shouldValidate: false });

        runEligibilityChecks({
          lenderName: formState.loan_officer_name,
          lenderEmail: formState.loan_officer_email,
          lenderPhone: formState.loan_officer_phone,
          lenderCompany: formState.lender_company_name,
          uuid,
          state: formState.property_state,
          value: formState.estimated_home_value,
          mortgage: formState.mortgage_balance,
          targetAmount: formState.target_equity_unlock,
          targetAmountRadio: formState.target_amount_radio,
          flow: formState.flow,
          agentName: formState.agent_name,
          agentEmail: formState.agent_email,
          agentPhone: formState.agent_phone,
          salesConsultantName: formState.sales_consultant_name,
          salesConsultantEmail: formState.sales_consultant_email,
          salesConsultantPhone: formState.sales_consultant_phone,
        });
      },
      isPublic: true,
      enabled: false,
    });

  const isValid =
    formState.property_address && parseInt(formState.estimated_home_value);

  const params = {
    lenderName: formState.loan_officer_name,
    lenderEmail: formState.loan_officer_email,
    lenderPhone: formState.loan_officer_phone,
    lenderCompany: formState.lender_company_name,
    uuid: formState.property_uuid,
    state: formState.property_state,
    value: formState.estimated_home_value,
    mortgage: formState.mortgage_balance,
    targetAmount: formState.target_equity_unlock,
    flow: formState.flow,
    agentName: formState.agent_name,
    agentEmail: formState.agent_email,
    agentPhone: formState.agent_phone,
    salesConsultantName: formState.sales_consultant_name,
    salesConsultantEmail: formState.sales_consultant_email,
    salesConsultantPhone: formState.sales_consultant_phone,
  };

  const checkEligibility = () => {
    setLoading(true);

    if (!formState.property_uuid) fetchPropertyUuid();
    else if (
      JSON.stringify(formState.eligibility_check_params) ===
        JSON.stringify(params) &&
      hasAcknowledged
    ) {
      goNext();
    } else {
      setValue('eligibility_check_params', params);
      runEligibilityChecks(params);
    }
  };

  const isLoading = isFetchingProperty || isRunningChecks;

  const onEnter = ({
    hl_full_address,
    city,
    state_or_province,
    postal_code,
    full_address,
  }) => {
    clearErrors('full_address');

    setValue('property_address', hl_full_address, {
      shouldValidate: false,
    });
    setValue('full_address', full_address, { shouldValidate: false });
    setValue('property_city', city, { shouldValidate: false });
    setValue('property_state', state_or_province, {
      shouldValidate: false,
    });
    setValue('property_postal_code', postal_code, {
      shouldValidate: false,
    });
    setValue('property_uuid', null, { shouldValidate: false });
  };

  return (
    <>
      <Step
        title="Departing Residence Information"
        description="We'll use this information to estimate how much equity we can unlock from the property, please be as accurate as possible."
        loading={loading || isLoading}
      >
        <Inputs.Address
          name="full_address"
          label="Departing Residence Address"
          placeholder="Enter property address"
          dropdownPlaceholder="Type to search an address"
          emptyResultsText="No matching address found"
          containerProps={{ width: '100%' }}
          onClear={() =>
            onEnter({
              hl_full_address: null,
              city: null,
              state_or_province: null,
              postal_code: null,
              full_address: null,
            })
          }
          onBlur={() => {
            if (!formState.property_address) {
              setError('full_address', {
                type: 'onBlur',
                message: 'Please choose an address from the dropdown menu',
              });
            }
          }}
          onChange={() => {
            clearErrors('full_address');
          }}
          onEnter={onEnter}
          boxSizing="border-box"
          data-test="property_address-input"
        />
        <Inputs.Text
          name="property_unit"
          label="Unit / Apt / Suite"
          placeholder="Enter unit e.g. 14 A"
          onBlur={() => {
            if (formState.property_address) {
              const full_address = getFullAddress({
                propertyAddress: formState.property_address,
                propertyCity: formState.property_city,
                propertyUnit: formState.property_unit,
                propertyState: formState.property_state,
                propertyPostalCode: formState.property_postal_code,
              });

              setValue('full_address', full_address, { shouldValidate: false });
            }
          }}
          containerProps={{ flex: '1', width: '100%', note: '(If applicable)' }}
          data-test="property_unit-input"
        />
        <Inputs.Currency
          name="estimated_home_value"
          label="Estimated Home Value"
          placeholder="Enter home value"
          containerProps={{ flex: '1', width: '100%' }}
          boxSizing="border-box"
          hint="At what price do you expect the departing residence to sell for?"
          data-test="estimated_home_value-input"
        />
        <>
          {valueProvidedByAgentButton &&
            formState.flow !== 'agentSubmission' && (
              <>
                <Inputs.RadioGroup
                  name="value_provided_by_agent"
                  direction="row"
                  label={
                    <Paragraph variant="text-small">
                      Was the home value provided by the listing agent?
                    </Paragraph>
                  }
                >
                  <Inputs.RadioItem value="yes">Yes</Inputs.RadioItem>
                  <Inputs.RadioItem value="no">No</Inputs.RadioItem>
                </Inputs.RadioGroup>
              </>
            )}
        </>
        <Inputs.Currency
          name="mortgage_balance"
          label="Mortgage Balance"
          note="(Optional)"
          placeholder="Enter client's mortgage balance"
          containerProps={{ flex: '1', width: '100%' }}
          boxSizing="border-box"
          hint="If the home is owned free and clear, enter zero. If you are unsure, leave it blank."
          data-test="mortgage_balance-input"
        />
      </Step>
      <FloatingFAQButton />
      <Footer
        backButton
        key="step-footer"
        ml={{ xs: '0', md: '380px', lg: '406px' }}
      >
        <Button
          height="48px"
          disabled={!isValid || isLoading}
          onClick={checkEligibility}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
