import { PropertyEligibilityChecksResponse } from 'packages/hooks/src/useAutomatedBbysEligibilityCheck/automated-property_eligibity-lender';

import {
  DenialCheck,
  EligibilityBlocker,
  EligibilityCheck,
  WarningCheck,
} from '@hl-portals/hooks';

export function getEligibilityFormData(
  result: PropertyEligibilityChecksResponse
) {
  const {
    eligibility_checks,
    warning_checks,
    eligibility_blockers,
    denial_checks,
    agent_valuation,
  } = result;

  const issues = Object.keys(eligibility_checks || {}).filter(
    (k) => !eligibility_checks[k as EligibilityCheck]
  );

  const warnings = Object.keys(warning_checks || {}).filter(
    (k) => warning_checks[k as WarningCheck]
  );

  const blockers = Object.keys(eligibility_blockers || {}).filter(
    (k) => eligibility_blockers[k as EligibilityBlocker]
  );

  const denials = Object.keys(denial_checks || {}).filter(
    (k) => denial_checks[k as DenialCheck]
  );

  const isDenied = denials.length > 0;
  const isEligible = !isDenied;

  return {
    agent_valuation,
    is_property_eligible: isEligible,
    property_eligibility_issues: issues,
    property_eligibility_warnings: warnings,
    property_eligibility_blockers: blockers,
    property_eligibility_denials: denials,
    automated_bbys_eligibility_check: isEligible,
    automated_bbys_check_success: isEligible,
    eligibility_result: {
      eligibility_checks,
      eligibility_blockers,
      denial_checks,
      warning_checks,
    },
    denied: isDenied,
  };
}
