import { PropsWithChildren } from 'react';

import { Box, BoxTypes, Button, Icon, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { getNextStepInfo } from '../constants';
import { useStepUtils } from '../hooks';
import { ProgressBarProps } from './ProgressBar';

type FooterProps = {
  backButton?: boolean;
} & Omit<ProgressBarProps, 'progress'> &
  PropsWithChildren &
  BoxTypes;

const Footer = ({
  children,
  backButton = false,
  progressColor,
  ...props
}: FooterProps) => {
  const {
    goBack,
    multiStep: { currentStep },
    formState: { flow },
  } = useStepUtils();

  const nextStepInfo = getNextStepInfo(currentStep, flow);
  const showNextStep =
    nextStepInfo &&
    !nextStepInfo.hideStep &&
    nextStepInfo.title !== currentStep &&
    (nextStepInfo.footerTitle || nextStepInfo.title);

  return (
    <Box
      key="step-footer"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      flexDirection="column"
      bgcolor={theme.colors.white}
      borderTop={{ xs: `1px solid ${theme.colors.coolGray5}`, md: 'none' }}
      {...props}
    >
      <Box
        p={{
          xs: '24px',
          md: '24px 80px',
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        {backButton ? (
          <Button
            color="tertiary"
            iconLeft={<Icon type="angleLeft" />}
            fontSize="16px"
            onClick={goBack}
            data-test="back-button"
            display={{ xs: 'none !important', md: 'flex !important' }}
          >
            Back
          </Button>
        ) : (
          <Box></Box>
        )}
        {showNextStep && (
          <Paragraph
            color="gray600"
            variant="text-small"
            display={{ xs: 'none', md: 'flex' }}
            whiteSpace="nowrap"
          >
            Next: {nextStepInfo.footerTitle || nextStepInfo.title}
          </Paragraph>
        )}
        <Box
          gap="24px"
          alignItems="center"
          width={{ xs: '100%', md: 'auto' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
