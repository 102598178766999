import { useSaveFlowRepInfo } from 'apps/equity-app/hooks/useSaveFlowRepInfo';
// import { useCheckExsitingEmail } from 'apps/equity-app/shared/hooks/use-check-existing-email';
import { useFlags } from 'flagsmith/react';
import styled, { css } from 'styled-components';

import { useEffect } from 'react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

// import { signIn } from 'next-auth/react';
import {
  Box,
  BoxTypes,
  Button,
  FadeIn,
  Icon,
  Inputs,
  Paragraph,
  SelectProps,
  useBrandTheme,
} from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  getFlowFromPartnerAndCurrentFlow,
  getLocalStoragePayload,
} from '../../../helpers';
import { LOCAL_STORAGE_ADDRESS_SEARCH_SETTINGS } from '../../../metadata/constants';
import {
  LENNAR_DIVISION_OPTIONS,
  LOCAL_STORAGE_KEY,
  PULTE_MARKET_OPTIONS,
} from '../../../metadata/constants';
import { FormData, schema } from '../../../metadata/forms';
import { CurrentFlow } from '../../../metadata/types';
import { Container, Content } from '../../layout';

// =========================================================================
// Inputs Container

const InputsContainer = (props: BoxTypes) => (
  <FadeIn width="100%" flexDirection="column" gap="24px" {...props}>
    {props.children}
  </FadeIn>
);

// =========================================================================
// Escrow Officer Select

const EscrowOfficerSelect = () => {
  const { setValue, watch } = useFormContext();

  const escrowOfficerName = watch('external_escrow_officer_name');
  const escrowOfficerEmail = watch('external_escrow_officer_email');
  const escrowOfficerValue = `${escrowOfficerName}:${escrowOfficerEmail}`;

  const {
    'bbys-new-venture-escrow-officers': { value },
  } = useFlags(['bbys-new-venture-escrow-officers']);

  const escrowOfficerOptions: SelectProps['options'] = useMemo(() => {
    const escrowOfficersData: Record<string, { email: string }> = value
      ? JSON.parse(value.toString())
      : {};

    return Object.keys(escrowOfficersData).map((k) => ({
      text: k,
      value: `${k}:${escrowOfficersData[k].email}`,
    }));
  }, [value]);

  return (
    <Inputs.Select
      label="Escrow Officer"
      containerProps={{ flex: '1', width: '100%' }}
      onChange={(_value) => {
        const [name, email] = _value.split(':');
        setValue('external_escrow_officer_name', name);
        setValue('external_escrow_officer_email', email);
      }}
      value={escrowOfficerValue}
      options={escrowOfficerOptions}
    />
  );
};

// =========================================================================
// Remember Me Checkbox

const RememberMeCheckbox = () => {
  return (
    <Inputs.Checkbox name="remember_me">
      <Paragraph variant="text" textAlign="left">
        Save my information for future use
      </Paragraph>
    </Inputs.Checkbox>
  );
};

// =========================================================================
// Start Application Button

type StartApplicationButtonProps = {
  currentFlow: 'lender' | 'agent';
  onClickOverride?: () => void;
  isLoading?: boolean;
};

const baseLenderFields = {
  lender_company_name: true,
  loan_officer_name: true,
  loan_officer_email: true,
  loan_officer_phone: true,
};

const baseAgentFields = {
  agent_name: true,
  agent_email: true,
  agent_phone: true,
};

const baseSalesConsultantFields = {
  sales_consultant_name: true,
  sales_consultant_email: true,
  sales_consultant_phone: true,
};

const validations = {
  lennar: {
    lender: { ...baseLenderFields, division: true },
    agent: {
      ...baseSalesConsultantFields,
      division: true,
    },
  },
  newventureescrow: {
    lender: {
      ...baseLenderFields,
      external_escrow_officer_name: true,
      external_escrow_officer_email: true,
    },
    agent: {
      ...baseAgentFields,
      external_escrow_officer_name: true,
      external_escrow_officer_email: true,
    },
  },
  'pulte-mortgage': {
    lender: {
      ...baseLenderFields,
      market: true,
    },
    agent: {
      ...baseSalesConsultantFields,
      market: true,
    },
  },
  default: {
    lender: baseLenderFields,
    agent: baseAgentFields,
  },
};

const StartApplicationButton = ({
  currentFlow,
  onClickOverride,
  isLoading = false,
}: StartApplicationButtonProps) => {
  const { watch } = useFormContext();
  const {
    isLoading: isSubmissionLoading,
    coreEventData,
    onUpdateSnapshot,
    logLeadSubmissionEvent,
  } = useLeadSubmissionContext();

  const formData = watch();
  const { partner_slug, remember_me } = formData;

  const partner =
    partner_slug && Object.keys(validations).includes(partner_slug)
      ? partner_slug
      : 'default';

  const requiredFields = validations[partner][currentFlow];

  const { success } = schema.required(requiredFields).safeParse(
    Object.keys(requiredFields).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: watch(curr),
      }),
      {}
    )
  );

  const onGoNext = async () => {
    if (remember_me) {
      const localStoragePayload = getLocalStoragePayload(
        partner_slug,
        formData as FormData
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(localStoragePayload)
      );

      logLeadSubmissionEvent({
        event_action: 'save_my_information_for_future_use',
        event_payload: { ...coreEventData },
      });
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }

    if (onClickOverride) {
      onClickOverride();
      return;
    }
    onUpdateSnapshot();
  };

  return (
    <Button
      size="large"
      disabled={!success}
      isLoading={isLoading || isSubmissionLoading}
      onClick={onGoNext}
    >
      Start Application
    </Button>
  );
};

// =========================================================================
// Lender Inputs

// type LenderInputsProps = {
//   portalEnabled?: boolean;
// };

const LenderInputs = () => {
  // const [existingEmailError, setExistingEmailError] = useState(false);

  const { isLoading } = useLeadSubmissionContext();
  const { watch, formState } = useFormContext();

  const { errors } = formState;
  const { partner_slug } = watch();

  // const {
  //   refetch: checkExistingEmail,
  //   isLoading: isCheckExistingEmailLoading,
  // } = useCheckExsitingEmail(loan_officer_email, {
  //   enabled: false,
  //   onSuccess: (_result) => {
  //     if (_result.exists) {
  //       setExistingEmailError(true);
  //       return;
  //     }
  //     onUpdateSnapshot({
  //       event_action: 'save_my_information_for_future_use',
  //     });
  //   },
  // });

  const placeholder = {
    lenderCompanyName: errors?.lender_company_name?.message
      ? ''
      : 'Enter your company name',
    loanOfficerName: errors?.loan_officer_name?.message
      ? ''
      : 'Enter your full name',
    loanOfficerEmail: errors?.loan_officer_email?.message
      ? ''
      : 'Enter your email address',
    loanOfficerPhone: errors?.loan_officer_phone?.message
      ? ''
      : 'Enter your phone number',
  };

  return (
    <InputsContainer>
      <Inputs.Text
        name="lender_company_name"
        label="Lender (company name)"
        placeholder={placeholder.lenderCompanyName}
        mode="onBlur"
      />
      <Inputs.Text
        name="loan_officer_name"
        label="Full Name"
        placeholder={placeholder.loanOfficerName}
        mode="onBlur"
      />
      <Inputs.Email
        name="loan_officer_email"
        label="Email Address"
        placeholder={placeholder.loanOfficerEmail}
        mode="onBlur"
        // customError={
        //   existingEmailError && (
        //     <Box mt="6px" gap="8px">
        //       <Paragraph variant="text-small" color="#F93A2F">
        //         This email address is already register, please
        //       </Paragraph>
        //       <Paragraph
        //         variant="text-small"
        //         color="#1192E5"
        //         cursor="pointer"
        //         onClick={() =>
        //           signIn(undefined, { callbackUrl: window.location.href })
        //         }
        //       >
        //         Sign In
        //       </Paragraph>
        //     </Box>
        //   )
        // }
      />
      <Inputs.Phone
        name="loan_officer_phone"
        label="Phone Number"
        placeholder={placeholder.loanOfficerPhone}
        mode="onBlur"
      />
      {partner_slug === 'lennar' && (
        <Inputs.Select
          name="division"
          label="Division"
          options={LENNAR_DIVISION_OPTIONS}
        />
      )}
      {partner_slug === 'newventureescrow' && <EscrowOfficerSelect />}
      {partner_slug === 'pulte-mortgage' && (
        <Inputs.Select
          name="division"
          label="Market"
          options={PULTE_MARKET_OPTIONS}
        />
      )}
      <RememberMeCheckbox />
      <StartApplicationButton
        currentFlow="lender"
        // onClickOverride={portalEnabled ? checkExistingEmail : undefined}
        // isLoading={isCheckExistingEmailLoading || isLoading}
        isLoading={isLoading}
      />
    </InputsContainer>
  );
};

// =========================================================================
// Agent Inputs

const AgentInputs = () => {
  const { watch } = useFormContext();
  const partner = watch('partner_slug');

  const { name, email, phone } = useMemo(() => {
    if (partner === 'lennar' || partner === 'pulte-mortgage') {
      return {
        name: 'sales_consultant_name',
        email: 'sales_consultant_email',
        phone: 'sales_consultant_phone',
      };
    }
    return {
      name: 'agent_name',
      email: 'agent_email',
      phone: 'agent_phone',
    };
  }, [partner]);

  return (
    <InputsContainer>
      <Inputs.Text
        name={name}
        label="Full Name"
        placeholder="Enter your full name"
        mode="onBlur"
      />
      <Inputs.Email
        name={email}
        label="Email Address"
        placeholder="Enter your email address"
        mode="onBlur"
      />
      <Inputs.Phone
        name={phone}
        label="Phone Number"
        placeholder="Enter your phone number"
        mode="onBlur"
      />
      {partner === 'lennar' && (
        <Inputs.Select
          name="division"
          label="Division"
          options={LENNAR_DIVISION_OPTIONS}
        />
      )}
      {partner === 'newventureescrow' && <EscrowOfficerSelect />}
      {partner === 'pulte-mortgage' && (
        <Inputs.Select
          name="division"
          label="Market"
          options={PULTE_MARKET_OPTIONS}
        />
      )}
      <RememberMeCheckbox />
      <StartApplicationButton currentFlow="agent" />
    </InputsContainer>
  );
};

// =========================================================================
// Flow Selector

const Selector = styled(Box)<{ isActive?: boolean }>`
  ${({ isActive }) => css`
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 12px;
    border-radius: 12px;
    background-color: ${isActive ? 'white' : '#f8f8fb'};
    cursor: pointer;
    transition: all 200ms ease-in-out;
    ${isActive && 'font-weight: bold;'}
    ${isActive && 'box-shadow: rgba(108, 129, 171, 0.3) 0px 2px 4px 0px;'}
  `}
`;

const FlowSelector = (props: BoxTypes) => {
  const { watch, setValue } = useFormContext();

  const theme = useBrandTheme();
  const primary = theme?.colors?.primary || '#46b6ff';

  const partner = watch('partner_slug');
  const currentFlow = watch('current_flow');

  const agentLabel = useMemo(() => {
    if (partner === 'lennar') {
      return "I'm a New Home Consultant";
    }
    if (partner === 'pulte-mortgage') {
      return "I'm a Sales Consultant";
    }
    return "I'm an Agent";
  }, [partner]);

  const onClick = (cf: CurrentFlow) => {
    setValue('current_flow', cf);
    setValue('flow', getFlowFromPartnerAndCurrentFlow(partner, cf));
  };

  return (
    <Box
      width="100%"
      p="8px"
      gap="20px"
      borderRadius="12px"
      bgcolor="#f8f8fb"
      {...props}
    >
      <Selector
        isActive={currentFlow === 'lender'}
        onClick={() => onClick('lender')}
      >
        <Icon type="bank" color={primary} />
        I'm a Loan Officer
      </Selector>

      <Selector
        isActive={currentFlow === 'agent'}
        onClick={() => onClick('agent')}
      >
        <Icon type="userCard" color={primary} />
        {agentLabel}
      </Selector>
    </Box>
  );
};

// =========================================================================
// Lead Information

export const LeadInformation = () => {
  const { watch } = useFormContext();
  const currentFlow = watch('current_flow');
  const { clearStoredValues } = useSaveFlowRepInfo(
    LOCAL_STORAGE_ADDRESS_SEARCH_SETTINGS,
    {}
  );

  // const {
  //   'equity-portal-phase-1': { enabled: portalEnabled },
  // } = useFlags(['equity-portal-phase-1']);

  useEffect(() => {
    clearStoredValues();
  }, []);

  return (
    <Content overflowY={{ xs: 'visible', md: 'auto' }}>
      <Container
        title="First, tell us about you"
        alignTitle="center"
        mb={{ xs: '24px', md: '40px' }}
      >
        <FlowSelector mb="32px" />

        {currentFlow === 'lender' ? (
          // <LenderInputs portalEnabled={portalEnabled} />
          <LenderInputs />
        ) : (
          <AgentInputs />
        )}

        {/* {portalEnabled && (
          <Box mt="32px" justifyContent="center" gap="10px">
            <Paragraph>Already have an account?</Paragraph>
            <Paragraph
              color="#1192E5"
              cursor="pointer"
              onClick={() =>
                signIn(undefined, { callbackUrl: window.location.href })
              }
            >
              Sign in
            </Paragraph>
          </Box>
        )} */}
      </Container>
    </Content>
  );
};
