import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const AddLoanOfficerInfo = () => {
  const { coreEventData, logLeadSubmissionEvent, onUpdateSnapshot } =
    useLeadSubmissionContext();
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    loan_officer_name,
    loan_officer_email,
    loan_officer_phone,
    save_loan_officer_info,
    finance_type,
  } = watch();

  const handleNext = () => {
    const payload = {
      loan_officer_name: loan_officer_name ?? '',
      loan_officer_email: loan_officer_email ?? '',
      loan_officer_phone: loan_officer_phone ?? '',
      save_loan_officer_info: save_loan_officer_info ?? false,
    };
    logLeadSubmissionEvent({
      event_action: 'add_loan_officer_info',
      event_payload: {
        ...coreEventData,
        ...payload,
      },
    });
    onUpdateSnapshot(payload);
  };

  const someFieldsHaveErrors: boolean = [
    errors?.loan_officer_name?.message,
    errors?.loan_officer_email?.message,
    errors?.loan_officer_phone?.message,
  ].some(Boolean);

  const areAnyFieldsTouched: boolean = [
    loan_officer_name,
    loan_officer_email,
    loan_officer_phone,
    save_loan_officer_info,
  ].some(Boolean);

  const areAllReqInputsValid: boolean = [
    loan_officer_name,
    loan_officer_email,
    loan_officer_phone,
  ].every(Boolean);

  const isFormRequired = finance_type !== 'cash';

  // the conditions to disable the form when it should be optional
  const disableOptionalForm =
    someFieldsHaveErrors || (areAnyFieldsTouched && !areAllReqInputsValid);

  const disable = isFormRequired ? !areAllReqInputsValid : disableOptionalForm;

  const subtitle =
    finance_type === 'cash' || finance_type === 'unsure'
      ? '(Optional) You can also add it after completing the client’s application.'
      : '';

  return (
    <>
      <Content>
        <Container title="Add a Loan Officer" subtitle={subtitle}>
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Inputs.Text
                name="loan_officer_name"
                label="Full name"
                placeholder="Enter loan officer’s full name"
                mode="onBlur"
              />
              <Inputs.Email
                name="loan_officer_email"
                label="Email Address"
                placeholder="loan-officer@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name="loan_officer_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                mode="onBlur"
              />
            </Box>
          </Box>
          <Alert
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
            mt={{ xs: '24px', md: '40px' }}
          >
            We will include the team member in email communications so they can
            assist with the transaction.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disable} />
      </Footer>
    </>
  );
};
