import { Box, Paragraph } from '@hl-portals/ui';

import { BbysSidebarLogo } from './BbysSidebarLogo';

const ClientInformationSidebar = () => {
  return (
    <>
      <BbysSidebarLogo />
      <Box mt="16px" height="100%">
        <Box
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          gap="16px"
          flexDirection="column"
          mt="15%"
        >
          <Box
            as="img"
            src="/illustrations/bbys-lead-submission/messages.png"
            maxWidth="260px"
          />
          <Paragraph
            variant="text-small"
            textTransform="uppercase"
            color="coolGray3"
          >
            Client(s) Info
          </Paragraph>
        </Box>
      </Box>
    </>
  );
};

export default ClientInformationSidebar;
