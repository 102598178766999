import { Box, BoxTypes, useBrandTheme } from '@hl-portals/ui';

import { Colors, theme } from '@hl-portals/constants';

export type ProgressBarProps = {
  progress: number;
  progressColor?: Colors;
} & BoxTypes;

export const ProgressBar = ({
  progress,
  progressColor = 'electricBlue',
  ...props
}: ProgressBarProps) => {
  const { colors } = useBrandTheme();

  return (
    <Box
      height="6px"
      bgcolor={theme.colors.coolGray5}
      key="progress-bar"
      {...props}
    >
      <Box
        width={`${progress}%`}
        transition="all .2s ease-out"
        bgcolor={colors.primary || theme.colors[progressColor]}
      />
    </Box>
  );
};
