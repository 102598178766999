import React from 'react';

import { NextRouter } from 'next/router';

export const getLenderFlow = (router: NextRouter) => {
  const defaultFlow = 'lenderSubmission';

  if (router && router.query.partnerSlug) {
    switch (router.query.partnerSlug) {
      case 'lennar':
        return 'lennarSubmission';
      case 'mtgandbeyondagents':
        return 'agentSubmission';
      default:
        return defaultFlow;
    }
  }

  return defaultFlow;
};

export const getFlow = (router: NextRouter) => {
  const defaultFlow = 'lenderSubmission';

  if (router && router.query.partnerSlug) {
    return getLenderFlow(router);
  } else if (router && router.query.flow) {
    switch (router.query.flow) {
      case 'agentSubmission':
        return 'agentSubmission';
      default:
        return defaultFlow;
    }
  }

  return defaultFlow;
};

export type Step = {
  key: string;
  title: string;
  footerTitle?: string;
  sidebarContent?: () => React.ReactElement;
  showProgress?: boolean;
  showSidebar?: boolean;
  skipUpdate?: boolean;
  hideStep?: boolean; // for use in the "Next Step" footer display
  skipStep?: (formState: any) => boolean;
};

const leadInfoStep: Step = {
  key: 'lender_information',
  title: 'Lead Information',
  showProgress: false,
  showSidebar: true,
  skipUpdate: true,
};

const departingResidenceStep: Step = {
  key: 'departing_residence_info',
  title: 'Departing Residence',
  showProgress: true,
  showSidebar: true,
};

const eligibilityCriteriaStep: Step = {
  key: 'program_eligibility_check',
  title: 'Eligibility Criteria',
  footerTitle: 'Eligibility Check',
  showProgress: true,
  showSidebar: true,
};

const calculateEquityUnlockStep: Step = {
  key: 'calculating_equity_unlock',
  title: 'Calculating Equity Unlock',
  showProgress: false,
  showSidebar: false,
  skipUpdate: true,
  hideStep: true,
};

const equityUnlockAmountStep: Step = {
  key: 'equity_unlock_estimation',
  title: 'Equity Unlock Amount',
  footerTitle: 'Equity Unlock Estimation',
  showProgress: true,
  showSidebar: true,
};

const financialInfoStep: Step = {
  key: 'clients_financial_details',
  title: 'Financial Information',
  footerTitle: 'Financial Info',
  showProgress: true,
  showSidebar: true,
};

const financeTypeStep: Step = {
  key: 'finance_type',
  title: 'Finance Type',
  showProgress: true,
  showSidebar: true,
};

const clientInfoStep: Step = {
  key: 'clients_information',
  title: 'Client(s) Information',
  footerTitle: 'Client Info',
  showProgress: true,
  showSidebar: true,
};

const agentInfoStep: Step = {
  key: 'agent_information',
  title: 'Agent Information',
  footerTitle: 'Agent Info',
  showProgress: true,
  showSidebar: true,
};

const agentFinanceInfoStep: Step = {
  key: 'agent_financial_information',
  title: 'Financial Information',
  footerTitle: 'Financial Info',
  showProgress: true,
  showSidebar: true,
};

const agentPreferredLender: Step = {
  key: 'agent_preferred_lender',
  title: 'Lender Information',
  footerTitle: 'Lender Information',
  showProgress: true,
  showSidebar: true,
  hideStep: true,
  skipStep: (formState) => {
    return (
      (formState.flow === 'agentSubmission' &&
        formState.lender_present === 'yes') ||
      formState.finance_type === 'cash'
    );
  },
};

const consultantInfoStep: Step = {
  key: 'consultant_information',
  title: 'Lennar New Home Consultant',
  footerTitle: 'Consultant Info',
  showProgress: true,
  showSidebar: true,
};

const confirmationStep: Step = {
  key: 'confirmation',
  title: 'Review and Submit',
  showProgress: true,
  showSidebar: false,
};

const successStep: Step = {
  key: 'success',
  title: 'Success',
  showProgress: false,
  showSidebar: false,
  hideStep: true,
};

export const lenderSubmissionSteps: Step[] = [
  leadInfoStep,
  departingResidenceStep,
  eligibilityCriteriaStep,
  calculateEquityUnlockStep,
  equityUnlockAmountStep,
  clientInfoStep,
  financialInfoStep,
  agentInfoStep,
  confirmationStep,
  successStep,
];

const lennarLeadSubmissionSteps: Step[] = [
  leadInfoStep,
  departingResidenceStep,
  eligibilityCriteriaStep,
  calculateEquityUnlockStep,
  equityUnlockAmountStep,
  clientInfoStep,
  financialInfoStep,
  agentInfoStep,
  consultantInfoStep,
  confirmationStep,
  successStep,
];

export const agentSubmissionSteps: Step[] = [
  leadInfoStep,
  departingResidenceStep,
  eligibilityCriteriaStep,
  calculateEquityUnlockStep,
  equityUnlockAmountStep,
  clientInfoStep,
  financeTypeStep,
  agentFinanceInfoStep,
  agentPreferredLender,
  confirmationStep,
  successStep,
];

export const externalLeadSubmitterSteps: Step[] = [
  leadInfoStep,
  departingResidenceStep,
  eligibilityCriteriaStep,
  calculateEquityUnlockStep,
  equityUnlockAmountStep,
  clientInfoStep,
  financeTypeStep,
  agentFinanceInfoStep,
  agentInfoStep,
  confirmationStep,
  successStep,
];

export const FLOW_STEPS = {
  lenderSubmission: lenderSubmissionSteps,
  lennarSubmission: lennarLeadSubmissionSteps,
  agentSubmission: agentSubmissionSteps,
  externalLeadSubmitter: externalLeadSubmitterSteps,
};

export const leadSource = {
  externalLeadSubmitter: 'NHC',
  agentSubmission: 'Agent',
  LenderSubmission: 'Lender',
  LennarSubmission: 'Lender',
};

export const getStepInfo = (currentStep: string, flow: string): Step | void => {
  const steps = FLOW_STEPS[flow] as Record<symbol, Step>;

  if (!steps) return;

  const step = Object.values(steps).find(
    (flowStep: Step) => flowStep.title === currentStep
  );

  if (step) return step as Step;
};

export const getNextStepInfo = (
  currentStep: string,
  flow: string
): Step | void => {
  const steps = FLOW_STEPS[flow] as Step[];

  if (!steps) return;

  const currentStepIndex = steps.findIndex(
    (step) => step.title === currentStep
  );

  if (steps[currentStepIndex + 1]) return steps[currentStepIndex + 1];

  return steps[currentStepIndex];
};

export const getStepInfoByKey = (
  currentStepKey: string,
  flow: string
): Step | void => {
  const steps = FLOW_STEPS[flow] as Record<symbol, Step>;

  if (!steps) return;

  const step = Object.values(steps).find(
    (flowStep: Step) => flowStep.key === currentStepKey
  );

  if (step) return step as Step;
};

// TODO: This is currently maintained in two spots, we should consolidate
//      - https://github.com/homelight/sales-app/blob/main/src/lookups/lookupOptions/lookupOptions.ts#700
export const LENNAR_DIVISIONS = [
  'AL - Gulf Coast',
  'AL - North Alabama',
  'AZ - Arizona Sales Center',
  'AZ - Phoenix',
  'AZ - Tucson',
  'CA - Bay Area-Concord',
  'CA - Central Valley',
  'CA - Coastal',
  'CA - Inland Empire',
  'CA - Sacramento',
  'CA - San Diego',
  'CO - Englewood',
  'FL - Jacksonville',
  'FL - Mid-Florida',
  'FL - Orlando',
  'FL - Palm Atlantic',
  'FL - SE FL South',
  'FL - SW Florida',
  'FL - Tampa',
  'GA - Atlanta',
  'ID - Boise',
  'IL - Chicago',
  'IN - Indianapolis',
  'Maryland',
  'MN - Minneapolis',
  'NC - Charlotte',
  'NC - Charlotte Sales Center',
  'NC - Raleigh',
  'NV - Las Vegas',
  'NV - Reno',
  'NY - New Jersey',
  'OR - Portland',
  'PA - Philadelphia Metro',
  'SC - Charleston',
  'TN - Nashville',
  'TX - Austin',
  'TX - Dallas',
  'TX - Houston',
  'TX - San Antonio',
  'Utah',
  'Virginia',
  'WA - Seattle',
  'Other',
];
