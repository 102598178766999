import { PropsWithChildren, useEffect } from 'react';

import {
  Box,
  BoxTypes,
  FadeIn,
  Icon,
  Paragraph,
  Spinner,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { getStepInfo } from '../constants';
import { useStepUtils } from '../hooks';

type StepProps = {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  note?: string;
  loading?: boolean;
  loadingAnimation?: React.ReactNode;
  banner?: {
    text: string;
    type?: 'success' | 'warning' | 'error';
  };
} & PropsWithChildren &
  BoxTypes;

const Step = ({
  title,
  description,
  loading,
  loadingAnimation,
  children,
  note,
  banner,
  ...props
}: StepProps): React.ReactElement => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const {
    multiStep: { currentStep },
    formState,
  } = useStepUtils();
  const stepInfo = getStepInfo(currentStep, formState.flow);

  const shouldSkipStep =
    stepInfo && stepInfo.skipStep && stepInfo.skipStep(formState);

  return (
    <FadeIn
      animationDelay=".2s"
      flexDirection="column"
      gap={{ xs: '24px', md: note ? '24px' : '32px' }}
      alignItems="flex-start"
      maxWidth={{ xs: '520px', md: '346px', lg: '520px' }}
      m="0 auto"
      width="100%"
      {...props}
    >
      {loading || shouldSkipStep ? (
        <Box width="100%" justifyContent="center">
          {loadingAnimation ? loadingAnimation : <Spinner xl m="80px auto" />}
        </Box>
      ) : (
        <>
          {banner && (
            <Box
              bgcolor={theme.colors.green100}
              borderRadius="6px"
              p="12px"
              gap="8px"
            >
              <Box
                bgcolor={theme.colors.green600}
                borderRadius="50%"
                width="16px"
                height="16px"
                minWidth="16px"
                minHeight="16px"
                justifyContent="center"
                alignItems="center"
              >
                <Icon type="check" size={10} color={theme.colors.white} />
              </Box>
              <Paragraph variant="text-small" color="green600">
                {banner.text}
              </Paragraph>
            </Box>
          )}
          <Box display={{ xs: 'none', md: 'flex' }} width="100%">
            {' '}
            {typeof title === 'string' ? (
              <Paragraph variant="heading-4">{title}</Paragraph>
            ) : (
              title
            )}
          </Box>
          {note && (
            <Paragraph variant="text-small" color="gray600">
              {note}
            </Paragraph>
          )}
          {description && <Paragraph variant="text">{description}</Paragraph>}
          {children}
        </>
      )}
    </FadeIn>
  );
};

export default Step;
