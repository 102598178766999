import { useFlags } from 'flagsmith/react';

import { useEffect, useState } from 'react';

import { Box, Spinner } from '@hl-portals/ui';

import { useStepUtils } from '../hooks';
import { ExpressApprovalSuccess } from './ExpressApprovalSuccess';
import { GlobalSuccess } from './GlobalSuccess';
import { InstantApprovalSuccess } from './InstantApprovalSuccess';
import { LightApprovalSuccess } from './LightApprovalSuccess';

export const Success = (): React.ReactElement => {
  const { formState } = useStepUtils();
  const [loading, setLoading] = useState(true);

  const {
    'bbys-agent-sourced-leads': { enabled: agentSourcedLeadSubmissionEnabled },
  } = useFlags(['bbys-agent-sourced-leads']);

  useEffect(() => {
    if (formState?.submit_response) {
      setLoading(false);
    }
  }, [formState]);

  if (loading)
    return (
      <Box width="100%" justifyContent="center">
        <Spinner xl mt="100px" />
      </Box>
    );

  if (agentSourcedLeadSubmissionEnabled) {
    return <GlobalSuccess />;
  }

  if (formState.submit_response?.instant_approval_success)
    return <InstantApprovalSuccess />;

  if (formState.submit_response?.light_approval_success)
    return <LightApprovalSuccess />;

  return <ExpressApprovalSuccess />;
};
