import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  CASH,
  FINANCE_OPTIONS,
  FinanceOptions,
  MORTGAGE,
  MORTGAGE_FHA,
  MORTGAGE_VA,
  UNSURE,
} from '../../../metadata/constants';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

type ClientFinanceEvent = {
  finance_type: FinanceOptions;
  is_mortgage_va_selected: boolean;
  is_mortgage_fha_selected: boolean;
};

export const ClientFinance = () => {
  const { logLeadSubmissionEvent, onUpdateSnapshot, coreEventData } =
    useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const {
    finance_type,
    is_mortgage_va_selected,
    is_mortgage_fha_selected,
    has_accepted_va_fha_finance_type_modal,
  } = watch();

  const logEvent = (clientFinanceEvent: ClientFinanceEvent) => {
    logLeadSubmissionEvent({
      event_action: 'finance_type',
      event_payload: {
        ...coreEventData,
        ...clientFinanceEvent,
      },
    });
  };

  const handleSelect = (selection: FinanceOptions) => {
    const isMortgageType =
      selection === MORTGAGE_FHA || selection === MORTGAGE_VA;
    const isConventionalMortgage = selection === MORTGAGE;

    const nonMortgageType = selection === CASH || selection === UNSURE;

    setValue('is_mortgage_va_selected', selection === MORTGAGE_VA);
    setValue('is_mortgage_fha_selected', selection === MORTGAGE_FHA);
    setValue('finance_type', selection);

    if (nonMortgageType) {
      const nonMortgageTypeEvent = {
        finance_type: selection,
        is_mortgage_va_selected: false,
        is_mortgage_fha_selected: false,
      };
      logEvent(nonMortgageTypeEvent);
      onUpdateSnapshot(nonMortgageTypeEvent);
      return;
    }

    if (isConventionalMortgage) {
      const isConventionalMortgageEvent = {
        finance_type: selection,
        is_mortgage_va_selected: false,
        is_mortgage_fha_selected: false,
      };
      logEvent(isConventionalMortgageEvent);
      onUpdateSnapshot(isConventionalMortgageEvent);
      return;
    }

    if (has_accepted_va_fha_finance_type_modal && isMortgageType) {
      const hasAcceptedVaFhaFinanceTypeEvent = {
        finance_type: selection,
        is_mortgage_va_selected: selection === MORTGAGE_VA,
        is_mortgage_fha_selected: selection === MORTGAGE_FHA,
      };
      logEvent(hasAcceptedVaFhaFinanceTypeEvent);
      onUpdateSnapshot(hasAcceptedVaFhaFinanceTypeEvent);
      return;
    }
  };

  useEffect(() => {
    if (has_accepted_va_fha_finance_type_modal) {
      return;
    }

    const isVaOrFhaTypeMortgage =
      finance_type === MORTGAGE_FHA || finance_type === MORTGAGE_VA;

    if (isVaOrFhaTypeMortgage && !isModalOpened) {
      const clientFinanceSelection =
        finance_type === MORTGAGE_FHA ? 'FHA' : 'VA';

      openModal(
        <ReviewEligibilityStatusModal
          onConfirm={() => {
            setValue('has_accepted_va_fha_finance_type_modal', true);
            logLeadSubmissionEvent({
              event_action: 'warning_modal_debt_to_income_affected',
              event_payload: {
                ...coreEventData,
                message: `Because your client is using ${clientFinanceSelection} the existing mortgage will need to be included in the Debt to Income calculation.`,
              },
            });
            onUpdateSnapshot({
              finance_type: MORTGAGE,
              is_mortgage_va_selected: finance_type === MORTGAGE_VA,
              is_mortgage_fha_selected: finance_type === MORTGAGE_FHA,
            });
            closeModal({ clearAll: true });
          }}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Debt to Income affected
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              Because your client is using {clientFinanceSelection} the existing
              mortgage will need to be included in the Debt to Income
              calculation.
            </Paragraph>
          }
          renderFootnote
        />,
        { hideCloseIcon: true, enableClose: false }
      );
    }
  }, [finance_type, has_accepted_va_fha_finance_type_modal]);

  return (
    <>
      <Content>
        <Container title="How is your client financing their new home?">
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              {FINANCE_OPTIONS.map(({ type, label }) => {
                const isFhaActive =
                  finance_type === MORTGAGE &&
                  type === MORTGAGE_FHA &&
                  is_mortgage_fha_selected &&
                  !is_mortgage_va_selected;

                const isVaActive =
                  finance_type === MORTGAGE &&
                  type === MORTGAGE_VA &&
                  is_mortgage_va_selected &&
                  !is_mortgage_fha_selected;

                const isConventionalMortgageActive =
                  finance_type === MORTGAGE &&
                  type === MORTGAGE &&
                  !is_mortgage_va_selected &&
                  !is_mortgage_fha_selected;

                const isNonMortgageTypeActive =
                  finance_type === type &&
                  !is_mortgage_va_selected &&
                  !is_mortgage_fha_selected;

                return (
                  <CardRadio
                    key={type}
                    title={label}
                    onClick={() => handleSelect(type)}
                    isActive={
                      isVaActive ||
                      isFhaActive ||
                      isConventionalMortgageActive ||
                      isNonMortgageTypeActive
                    }
                  />
                );
              })}
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            It's important for us to know about the financing of the incoming
            residence because it can affect the program.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => {
            const payload = {
              finance_type,
              is_mortgage_va_selected,
              is_mortgage_fha_selected,
            };
            logEvent(payload);
            onUpdateSnapshot(payload);
          }}
          disabled={finance_type === undefined}
        />
      </Footer>
    </>
  );
};
