import { createGlobalStyle } from 'styled-components';

import { Box, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

export const RolesModalStyles = createGlobalStyle`
  .roles-modal__wrapper {
    padding: 0px;
  }
`;

const Role = ({ title, description }) => (
  <Box flexDirection="column" gap="4px">
    <Paragraph variant="text-bold">{title}</Paragraph>
    <Paragraph variant="text">{description}</Paragraph>
  </Box>
);

const ROLES = [
  {
    title: 'Loan Officer',
    description:
      "Provides financial details and is the client's main point of contact during the transaction",
  },
  {
    title: 'Real Estate Agent',
    description:
      "Helps the client to find their new home and is responsible for listing the client's old home on time and on budget",
  },
  {
    title: 'HomeLight',
    description:
      'Provides the Equity Unlock loan to the client and ensures the departing residence is sold on time',
  },
];

export const RolesModal = () => (
  <>
    <RolesModalStyles />
    <Box>
      <Box
        bgcolor={theme.colors.lightBlue}
        maxWidth="325px"
        borderRadius="12px 0 0 12px"
        alignItems="center"
        justifyContent="center"
        display={{ xs: 'none', md: 'flex' }}
        p="0px 18px"
      >
        <Box
          as="img"
          src="/illustrations/bbys-lead-submission/contact_cards_three.png"
          width="285px"
          height="212px"
        />
      </Box>
      <Box
        flexDirection="column"
        gap="32px"
        p="40px 40px 60px"
        maxWidth="520px"
      >
        <Box flexDirection="column" gap="12px">
          <Paragraph variant="heading-2">Roles</Paragraph>
          <Paragraph variant="text">
            A successful Buy Before You Sell transaction comes from the Real
            Estate Agent, the Loan Officer and HomeLight working together as a
            team
          </Paragraph>
        </Box>
        <Box flexDirection="column" gap="24px">
          {ROLES.map((role) => (
            <Role {...role} key={role.title} />
          ))}
        </Box>
      </Box>
    </Box>
  </>
);
