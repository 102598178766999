import { Inputs } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

export const LenderInformationInputs = () => (
  <>
    <Inputs.Text
      mode="onBlur"
      name="lender_company_name"
      label="Lender (Company)"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter lender company"
      borderColor={theme.colors.coolGray4}
      data-test="lender_company_name-input"
    />

    <Inputs.Text
      mode="onBlur"
      name="loan_officer_name"
      label="Full Name"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter loan officer name"
      borderColor={theme.colors.coolGray4}
      data-test="loan_officer_name-input"
    />

    <Inputs.Email
      mode="onBlur"
      name="loan_officer_email"
      label="Email Address"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter loan officer email address"
      borderColor={theme.colors.coolGray4}
      data-test="loan_officer_email-input"
    />

    <Inputs.Phone
      mode="onBlur"
      name="loan_officer_phone"
      label="Phone Number"
      containerProps={{ flex: '1', width: '100%' }}
      placeholder="Enter loan officer phone number"
      borderColor={theme.colors.coolGray4}
      data-test="loan_officer_phone-input"
    />
  </>
);
