import { useFieldArray } from 'react-hook-form';

import {
  Box,
  Icon,
  InputGroup,
  Inputs,
  Paragraph,
  RadioTabs,
  Select,
  SimpleLink,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useStepUtils } from '../../hooks';

const LIEN_TYPE_OPTIONS = [
  {
    value: 'heloc',
    text: 'HELOC',
  },
  {
    value: 'solar_lien',
    text: 'Solar Lien',
  },
  {
    value: 'reverse_mortgage',
    text: 'Reverse Mortgage',
  },
  {
    value: 'other',
    text: 'other',
  },
];

const YES_NO_UNSURE_OPTIONS = [
  {
    value: 'yes',
    label: 'Yes',
    maxWidth: { xs: '100%', md: '170px' },
  },
  {
    value: 'no',
    label: 'No',
    maxWidth: { xs: '100%', md: '170px' },
  },
  {
    value: 'unsure',
    label: 'Unsure',
    maxWidth: { xs: '100%', md: '170px' },
  },
];

const NUM_LIENS_MAX = 3;

export const AdditionalLiens = (): React.ReactElement => {
  const {
    formContext: { setValue },
    formState,
  } = useStepUtils();

  const { fields, append, remove } = useFieldArray({
    name: 'additional_liens',
  });

  return (
    <>
      <Box
        flexDirection="column"
        gap="16px"
        width="100%"
        data-test="additional-liens-group"
      >
        <Box gap="6px" flexDirection={{ xs: 'column', md: 'row' }}>
          <Paragraph variant="text-small">
            Does the client have additional liens on the property?
          </Paragraph>
          <Paragraph variant="text-small" color="gray600">
            (e.g. HELOC)
          </Paragraph>
        </Box>
        <Box flexDirection="column" gap="8px" width="100%">
          <RadioTabs
            maxWidth="100%"
            name="additional_liens_exist"
            onChange={(option) => {
              if (option === 'yes') append({});
              else remove();
              setValue('additional_liens_exist', option);
            }}
            tabs={YES_NO_UNSURE_OPTIONS}
            maxRowLength={3}
            selected={formState.additional_liens_exist}
          />
        </Box>
        {formState.additional_liens_exist === 'yes' && (
          <Box flexDirection="column" gap={{ xs: '24px', md: '8px' }}>
            {fields.map(({ id }, i) => (
              <>
                <Box
                  key={id}
                  gap="16px"
                  flexDirection={{ xs: 'column', md: 'row' }}
                >
                  <InputGroup label="Specify lien type" flex="1">
                    <Select
                      width="100%"
                      customSelectedItemCss={{
                        minWidth: '100% !important',
                      }}
                      onChange={(value) =>
                        setValue(`additional_liens.${i}.lien_type`, value)
                      }
                      selectedValue={
                        formState.additional_liens[i].lien_type || ''
                      }
                      height="48px"
                      variant={{ xs: 'top', md: 'bottom' }}
                      options={LIEN_TYPE_OPTIONS}
                      placeholder="Select Option"
                      data-test="lien_type-select"
                    />
                  </InputGroup>
                  <Inputs.Currency
                    label="Lien balance"
                    name={`additional_liens.${i}.lien_balance`}
                    height="48px"
                    testId="lien_balance-input"
                    containerProps={{
                      flex: '1',
                    }}
                  />
                </Box>
                {i > 0 && (
                  <SimpleLink
                    color={theme.colors.aaBlue}
                    onClick={() => remove(i)}
                    textAlign="right"
                  >
                    Remove
                  </SimpleLink>
                )}
              </>
            ))}
            {formState.additional_liens?.length < NUM_LIENS_MAX && (
              <SimpleLink
                onClick={() => append({})}
                display="flex"
                alignItems="center"
                textAlign="right"
                justifyContent="flex-end"
                color={theme.colors.aaBlue}
              >
                <Icon
                  type="plus"
                  fill="electricBlue"
                  display="inline-block"
                  size={12}
                  mr="4px"
                />
                Add additional lien
              </SimpleLink>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
