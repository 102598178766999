import { Box, Paragraph } from '@hl-portals/ui';

import { getStepInfo } from '../../constants';
import { useStepUtils } from '../../hooks';
import { BbysSidebarLogo } from './BbysSidebarLogo';

const ClientFinancialInfoSidebar = () => {
  const { multiStep, formState } = useStepUtils();
  const stepInfo = getStepInfo(multiStep.currentStep, formState.flow);

  return (
    <>
      <BbysSidebarLogo />
      <Box mt="16px" height="100%">
        <Box
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          gap="16px"
          flexDirection="column"
          mt="15%"
        >
          <Box
            as="img"
            src="/illustrations/bbys-lead-submission/bank_money.png"
            maxWidth="260px"
          />
          <Paragraph
            variant="text-small"
            textTransform="uppercase"
            color="coolGray3"
          >
            {stepInfo ? stepInfo.title : "Client's Financial Info"}
          </Paragraph>
        </Box>
      </Box>
    </>
  );
};

export default ClientFinancialInfoSidebar;
