import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

type AdditionalTitleHolderModalProp = {
  onClose: () => void;
  onConfirm: () => void;
};

export const AdditionalTitleHolderModal = ({
  onClose,
  onConfirm,
}: AdditionalTitleHolderModalProp) => {
  return (
    <Box
      maxWidth={{ xs: '100%', md: '520px' }}
      p="8px 16px"
      flexDirection="column"
    >
      <Box
        pb="16px"
        alignItems="center"
        gap="12px"
        borderBottom="1px solid #DBDFE6"
      >
        <Box
          width="40px"
          height="40px"
          justifyContent="center"
          alignItems="center"
          borderRadius="4px"
          bgcolor="#FFECD1"
        >
          <Icon type="triangleInfo" />
        </Box>
        <Paragraph variant="heading-4">
          The property has additional title holders
        </Paragraph>
      </Box>

      <Box p="24px 4px" flexDirection="column" gap="24px">
        <Paragraph>
          Please include all title holders to prevent delays in the approval
          process.
        </Paragraph>
        <Box gap="16px" justifyContent="flex-end">
          <Button color="tertiary" size="large" onClick={onClose}>
            Add later
          </Button>
          <Button size="large" onClick={onConfirm} isLoading={false}>
            Add title holder
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
