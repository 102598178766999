/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseMutationOptions, useMutation } from 'react-query';

import { useRouter } from 'next/router';

import { http } from '@hl-portals/libs/http';

import { useMultiStepContext, useSnapshotContext } from '@hl-portals/ui';

import {
  SUBMIT_LENDER_BBYS_ALL_CASH_LEAD,
  SUBMIT_LENDER_BBYS_LEAD,
  SUBMIT_LENDER_BBYS_PRE_LEAD,
} from '@hl-portals/constants';

import { usePublicEvent } from '@hl-portals/hooks';

import { getStepInfo } from './constants';
import {
  BbysUuidSubmissionParams,
  LenderBBYSLeadSubmissionParams,
} from './helpers';

export const FAQ_LINK =
  'https://help.homelight.com/lender-frequently-asked-questions';

export const useQueryFormState = (form, keySets = []) => {
  const router = useRouter();
  const formState = form.watch();

  useEffect(() => {
    keySets.forEach((keySet) => {
      // eslint-disable-next-line prefer-const
      let [queryKey, formKey] = keySet;
      formKey ||= queryKey;

      if (
        router.query[queryKey] &&
        router.query[queryKey] !== formState[formKey]
      ) {
        form.setValue(formKey, router.query[queryKey]);
      }
    });
  }, [formState, router.query]);
};

export const useFAQ = (currentStep) => {
  const { recordPublicEvent } = usePublicEvent();
  const router = useRouter();

  const event_payload = {
    last_seen_page: currentStep,
    uuid: router.query.uuid,
  } as EventPayload;

  if (router.query.partnerSlug) {
    event_payload.slug = router.query.partnerSlug as string;
  }

  const openFAQ = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    recordPublicEvent({
      event_action: 'bbys_public_lead_submission_faq_link',
      source: 'bbys_public_lead_submission_lender',
      event_payload,
    });

    window.open(FAQ_LINK, '_blank');
  };

  return {
    openFAQ,
  };
};

type EventPayload = {
  last_seen_page?: string;
  uuid: string;
  slug?: string;
  lead_id?: string;
};

type GoNextParams = {
  replace?: boolean;
};

export const usePublicPageView = (page: string, leadId?: number) => {
  const { recordPublicEvent } = usePublicEvent();
  const router = useRouter();
  const [recorded, setRecorded] = useState(false);
  const uuid = router.query.uuid as string;

  const event_payload: EventPayload = {
    last_seen_page: page,
    uuid,
    ...(leadId && { lead_id: `${leadId}` }),
  };

  if (router.query.partnerSlug) {
    event_payload.slug = router.query.partnerSlug as string;
  }

  useEffect(() => {
    if (page && uuid && !recorded) {
      setRecorded(true);
      recordPublicEvent({
        event_action: 'bbys_public_lead_submission_page_view',
        source: 'bbys_public_lead_submission_lender',
        event_payload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, page, recorded]);
};

export const useStepUtils = (page?: string) => {
  const formContext = useFormContext();
  const formState = formContext.watch();
  const multiStep = useMultiStepContext();
  const router = useRouter();
  const { snapshot, update } = useSnapshotContext();
  const leadId = formState['submit_response']?.lead_id;

  usePublicPageView(page, leadId);

  const validate = (relevantKeys = []) => {
    return relevantKeys.every(
      (key) => !!formState[key] && !formContext.formState.errors[key]
    );
  };

  const goToStep = (step: string, replace = false) => {
    router.query.step = step;
    if (replace) {
      router.replace({
        pathname: router.pathname,
        query: router.query,
      });
    } else {
      router.push({
        pathname: router.pathname,
        query: router.query,
      });
    }
  };

  const goBack = () => {
    let prevStep;

    if (multiStep.currentStep === 'Equity Unlock Amount') {
      const step = multiStep.getPrevStep(multiStep.currentStep);

      if (step) {
        prevStep = multiStep.getPrevStep(step);
      }
    } else {
      prevStep = multiStep.getPrevStep(multiStep.currentStep);
    }

    const prevStepInfo = getStepInfo(prevStep, formState.flow);

    if (prevStepInfo) {
      if (prevStepInfo.skipStep && prevStepInfo.skipStep(formState)) {
        goToStep(multiStep.getPrevStep(prevStep), false);
      } else {
        goToStep(prevStep, false);
      }
    }
  };

  const DEFAULTS = { replace: false };
  const goNext = (params?: GoNextParams) => {
    const { replace } = {
      ...DEFAULTS,
      ...params,
    };

    const nextStep = multiStep.getNextStep(multiStep.currentStep);
    const nextStepInfo = getStepInfo(nextStep, formState.flow);

    if (nextStepInfo) {
      if (nextStepInfo.skipStep && nextStepInfo.skipStep(formState)) {
        goToStep(multiStep.getNextStep(nextStep), replace);
      } else {
        goToStep(nextStep, replace);
      }
    }
  };

  return {
    formState,
    formContext,
    multiStep,
    snapshot,
    updateSnapshot: update,
    usePublicPageView,
    validate,
    goBack,
    goNext,
    goToStep,
  };
};

type LenderBBYSLeadSubmissionResponse = {
  data: {
    data: {
      attributes: {
        agent_estimated_loan_payoff: string;
        agent_fee: number;
        agent_valuation: number;
        bbys_fee: number;
        closing_cost: number;
        departing_property_full_address: string;
        eligibility_checks: {
          age_restricted_community_check: boolean;
          credit_score_check: boolean;
          primary_residence_check: boolean;
          va_fha_requirement_check: boolean;
        };
        equity_unlock_threshold: number;
        estimated_loan_payoff: number;
        estimated_mortgage_balance: number;
        fallback: boolean;
        high_end_range: number;
        id: string;
        inspection_cost: number;
        instant_approval_success: boolean;
        lead_id: number;
        lender_company: string;
        lender_email: string;
        lender_name: string;
        lender_phone: string;
        light_approval_success: boolean;
        low_end_range: number;
        photos_upload_task_id: string;
        property_questionnaire_task_id: string;
        property_type: string;
        target_unlock_amount: number;
        token: string;
        valid_equity: boolean;
      };
    };
  };
};

export const useSubmitBbysLead = ({
  onSuccess,
  onError,
}: UseMutationOptions<
  LenderBBYSLeadSubmissionResponse,
  unknown,
  LenderBBYSLeadSubmissionParams
>) => {
  return useMutation({
    mutationFn: (params) => http.public.post(SUBMIT_LENDER_BBYS_LEAD, params),
    onSuccess,
    onError,
  });
};

export const useSubmitPreBbysLead = ({
  onSuccess,
  onError,
}: UseMutationOptions<
  LenderBBYSLeadSubmissionResponse,
  unknown,
  BbysUuidSubmissionParams
>) => {
  return useMutation({
    mutationFn: (params) =>
      http.public.post(SUBMIT_LENDER_BBYS_PRE_LEAD, params),
    onSuccess,
    onError,
  });
};

export const useSubmitCashOrderBbysLead = ({
  onSuccess,
  onError,
}: UseMutationOptions<
  LenderBBYSLeadSubmissionResponse,
  unknown,
  BbysUuidSubmissionParams
>) => {
  return useMutation({
    mutationFn: (params) =>
      http.public.post(SUBMIT_LENDER_BBYS_ALL_CASH_LEAD, params),
    onSuccess,
    onError,
  });
};
