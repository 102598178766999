import { z } from 'zod';

import {
  Button,
  CardSelect,
  CardSelectOption,
  IconTypeProp,
} from '@hl-portals/ui';

import { useStepUtils } from '../../hooks';
import { FloatingFAQButton } from '../../layout/FloatingFAQButton';
import Footer from '../../layout/Footer';
import Step from '../../layout/Step';

export const financeTypeSchema = {
  finance_type: z.enum(['mortgage', 'cash', 'unsure']),
};

const OPTIONS = [
  {
    value: 'mortgage',
    label: 'Mortgage',
    description: 'My client requires a mortgage to finance the new home.',
    icon: 'bank' as IconTypeProp,
    iconProps: {
      size: 24,
    },
  },
  {
    value: 'cash',
    label: 'All cash purchase',
    description: 'My client will pay in cash the new home.',
    icon: 'cashOffer' as IconTypeProp,
    iconProps: {
      size: 24,
    },
  },
  {
    value: 'unsure',
    label: 'Unsure',
    description:
      'My client has not decided how they will finance their next home.',
  },
] as CardSelectOption[];

export const FinanceType = (): React.ReactElement => {
  const {
    validate,
    goNext,
    formState,
    formContext: { setValue },
  } = useStepUtils('finance_type');

  const isValid = validate(['finance_type']);

  return (
    <>
      <Step
        title="Financial Type"
        description="How will your client finance their new property?"
      >
        <CardSelect
          onChange={(value) => setValue('finance_type', value)}
          options={OPTIONS}
          selected={formState.finance_type}
        />
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
