import { CurrentFlow, Flow } from '../../metadata/types';

export const getFlowFromPartnerAndCurrentFlow = (
  partner: string,
  currentFlow: CurrentFlow
): Flow => {
  switch (partner) {
    case 'lennar':
      if (currentFlow === 'lender') return 'lennarSubmission';
      return 'externalLeadSubmitter';

    default:
      if (currentFlow === 'lender') return 'lenderSubmission';
      return 'agentSubmission';
  }
};
