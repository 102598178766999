import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const AddAgentInfo = () => {
  const { coreEventData, logLeadSubmissionEvent, onUpdateSnapshot } =
    useLeadSubmissionContext();
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const { agent_name, agent_email, agent_phone } = watch();

  const handleNext = () => {
    const payload = {
      agent_name: agent_name ?? '',
      agent_email: agent_email ?? '',
      agent_phone: agent_phone ?? '',
    };
    logLeadSubmissionEvent({
      event_action: 'add_agent_info',
      event_payload: {
        ...coreEventData,
        ...payload,
      },
    });
    onUpdateSnapshot(payload);
  };

  const someFieldsHaveErrors: boolean = [
    errors?.agent_name?.message,
    errors?.agent_email?.message,
    errors?.agent_phone?.message,
  ].some(Boolean);

  const areAnyFieldsTouched: boolean = [
    agent_name,
    agent_email,
    agent_phone,
  ].some(Boolean);

  const areAllReqInputsValid: boolean = [
    agent_name,
    agent_email,
    agent_phone,
  ].every(Boolean);

  const disable =
    someFieldsHaveErrors || (areAnyFieldsTouched && !areAllReqInputsValid);

  return (
    <>
      <Content>
        <Container
          title="Add a departing residence agent"
          subtitle="(Optional) You can also add it after completing the client’s application. "
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Inputs.Text
                name="agent_name"
                label="Full name"
                placeholder="Enter agent’s full name"
                mode="onBlur"
              />
              <Inputs.Email
                name="agent_email"
                label="Email Address"
                placeholder="agent@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name="agent_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                mode="onBlur"
              />
            </Box>
          </Box>
          <Alert
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
            mt={{ xs: '24px', md: '40px' }}
          >
            We require an agent to coordinate the listing and sale of the
            departing residence.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disable} />
      </Footer>
    </>
  );
};
