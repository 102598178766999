import { captureException } from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { z } from 'zod';

import { PropsWithChildren, useState } from 'react';
import { toast } from 'react-toastify';

import { useRouter } from 'next/router';

import {
  Box,
  BoxTypes,
  Button,
  Icon,
  InputGroup,
  Inputs,
  Paragraph,
  SimpleLink,
  TextArea,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { formatCurrency, getFullAddressFromForm } from '@hl-portals/helpers';

import { useModal } from '@hl-portals/hooks';

import { financeTypeMap, getSubmitParams } from '../helpers';
import {
  useStepUtils,
  useSubmitBbysLead,
  useSubmitCashOrderBbysLead,
  useSubmitPreBbysLead,
} from '../hooks';
import Divider from '../layout/Divider';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';

export const confirmationSchema = {
  client_token: z.string(),
};

export const ConfirmationItem = ({
  label,
  children,
  ...rest
}: { label: string } & PropsWithChildren & BoxTypes) => (
  <Box
    justifyContent="space-between"
    flexDirection={{ xs: 'column', md: 'row' }}
    p={{ xs: '16px', md: '0' }}
    gap={{ xs: '0px', md: '8px' }}
    {...rest}
  >
    <Paragraph variant="text">{label}:</Paragraph>
    <Paragraph variant="text" fontWeight="600">
      {children}
    </Paragraph>
  </Box>
);

export const ConfirmationSection = ({
  label,
  children,
  goToStep,
  ...rest
}: {
  label: string;
  goToStep?: () => void;
} & PropsWithChildren &
  BoxTypes) => (
  <Box
    flexDirection="column"
    gap={{ xs: '0px', md: '24px' }}
    width="100%"
    {...rest}
  >
    <Box
      justifyContent="space-between"
      alignItems="center"
      p={{ xs: '16px', md: '0' }}
      borderTop={{ xs: `1px solid ${theme.colors.coolGray4}`, md: 'none' }}
      borderLeft={{ xs: `1px solid ${theme.colors.coolGray4}`, md: 'none' }}
      borderRight={{ xs: `1px solid ${theme.colors.coolGray4}`, md: 'none' }}
      borderRadius="8px 8px 0px 0px"
    >
      <Paragraph variant="text" fontWeight="700">
        {label}
      </Paragraph>
      {goToStep && <SimpleLink onClick={goToStep}>Edit</SimpleLink>}
    </Box>
    <Box
      flexDirection="column"
      gap={{ xs: '0px', md: '16px' }}
      width="100%"
      border={{
        xs: `1px solid ${theme.colors.coolGray4}`,
        md: 'none',
      }}
      borderRadius="0px 0px 8px 8px"
    >
      {children}
    </Box>
  </Box>
);

export const MobileNoteModal = ({ initialValue = '', onClose }) => {
  const [notes, setNotes] = useState(initialValue);

  return (
    <Box minHeight="90vh" flexDirection="column" position="relative">
      <Box
        position="absolute"
        right="0px"
        top="0px"
        onClick={() => onClose(initialValue)}
      >
        <Icon type="times" />
      </Box>
      <Paragraph variant="text-bold" textAlign="center" mb="40px">
        Add note (optional)
      </Paragraph>

      <InputGroup label="Add note for HomeLight">
        <TextArea
          placeholder="E.g., “The property has three title holders”"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          autoFocus
        />
      </InputGroup>

      <Box position="absolute" bottom="24px" width="100%">
        <Button width="100%" height="48px" onClick={() => onClose(notes)}>
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export const ConfirmationData = ({
  formState,
  goToStep,
  ...props
}: {
  formState: any;
  goToStep?: (step: string) => void;
} & BoxTypes): React.ReactElement => (
  <Box
    flexDirection="column"
    flex="1"
    maxWidth="680px"
    gap="24px"
    width="100%"
    m="0 auto"
    {...props}
  >
    <ConfirmationSection label="Departing Residence Information">
      <ConfirmationItem label="Address">
        {getFullAddressFromForm(formState)}
      </ConfirmationItem>
      <Divider />
      <ConfirmationItem label="Estimated Home Value">
        {!formState.estimated_home_value ||
        parseInt(formState.estimated_home_value) === 0
          ? '$0, home is owned free and clear'
          : formatCurrency({
              value: formState.estimated_home_value || '',
            })}
      </ConfirmationItem>
      <Divider />
      <ConfirmationItem label="Mortgage Balance">
        {formatCurrency({
          value:
            formState.confirm_mortgage_balance ||
            formState.mortgage_balance ||
            formState.equity_unlock_result?.estimated_loan_payoff ||
            '',
        })}
      </ConfirmationItem>
      <Divider />

      <ConfirmationItem label="Equity Unlock Amount">
        {' '}
        {formatCurrency({
          value: formState.equity_unlock_result?.low_end_range || '',
        })}{' '}
        -{' '}
        {formatCurrency({
          value: formState.equity_unlock_result?.high_end_range || '',
        })}
      </ConfirmationItem>
      <Divider />

      <ConfirmationItem label="Target Equity Unlock">
        {formState.target_equity_unlock
          ? formatCurrency({
              value: formState.target_equity_unlock || '',
            })
          : 'Target Equity Unlock not provided'}
      </ConfirmationItem>
    </ConfirmationSection>
    <Divider />
    <ConfirmationSection
      label="Client(s) Information"
      goToStep={goToStep ? () => goToStep('Client(s) Information') : undefined}
    >
      <ConfirmationItem label="Primary Title Holder">
        {formState.primary_client_name}
      </ConfirmationItem>
      <Divider />
      <ConfirmationItem label="Email Address">
        {formState.primary_client_email}
      </ConfirmationItem>
      {formState.primary_client_phone && (
        <>
          <Divider />
          <ConfirmationItem label="Phone Number">
            {formState.primary_client_phone}
          </ConfirmationItem>
        </>
      )}
      <Divider />
      {formState.additional_client_name && (
        <>
          <ConfirmationItem label="Additional Title Holder">
            {formState.additional_client_name}
          </ConfirmationItem>
          <Divider />
        </>
      )}
      {formState.additional_client_email && (
        <>
          <ConfirmationItem label="Email Address">
            {formState.additional_client_email}
          </ConfirmationItem>
          <Divider />
        </>
      )}
      {formState.additional_client_phone && (
        <>
          <ConfirmationItem label="Phone Number">
            {formState.additional_client_phone}
          </ConfirmationItem>
          <Divider />
        </>
      )}
    </ConfirmationSection>
    {['agentSubmission', 'externalLeadSubmitter'].includes(formState.flow) && (
      <ConfirmationSection
        label="Finance Information"
        goToStep={goToStep ? () => goToStep('Finance Type') : undefined}
      >
        <ConfirmationItem label="Finance type">
          {financeTypeMap[formState.finance_type]}
        </ConfirmationItem>
        <Divider />
        {formState.loan_officer_name && (
          <>
            <ConfirmationItem label="Loan Officer">
              {formState.loan_officer_name}
            </ConfirmationItem>
            <Divider />
            <ConfirmationItem label="Company">
              {formState.lender_company_name}
            </ConfirmationItem>
            <Divider />
            <ConfirmationItem label="Email Address">
              {formState.loan_officer_email}
            </ConfirmationItem>
            <Divider />
            <ConfirmationItem label="Phone Number">
              {formState.loan_officer_phone}
            </ConfirmationItem>
            <Divider />
          </>
        )}
      </ConfirmationSection>
    )}
    {!['agentSubmission'].includes(formState.flow) && (
      <ConfirmationSection
        label="Agent Information"
        goToStep={goToStep ? () => goToStep('Agent Information') : undefined}
      >
        <ConfirmationItem label="Departing Residence Agent">
          {formState.agent_name || 'No Agent Added'}
        </ConfirmationItem>
        <Divider />
        {formState.agent_email && (
          <>
            <ConfirmationItem label="Email Address">
              {formState.agent_email}
            </ConfirmationItem>
            <Divider />
          </>
        )}
        {formState.agent_phone && (
          <>
            <ConfirmationItem label="Phone Number">
              {formState.agent_phone}
            </ConfirmationItem>
            <Divider />
          </>
        )}
      </ConfirmationSection>
    )}
    {formState.flow === 'lennarSubmission' && (
      <ConfirmationSection
        label="Lennar New Home Consultant"
        goToStep={
          goToStep ? () => goToStep('Lennar New Home Consultant') : undefined
        }
      >
        <ConfirmationItem label="New Home Consultant">
          {formState.sales_consultant_name}
        </ConfirmationItem>
        <Divider />
        <ConfirmationItem label="Email Address">
          {formState.sales_consultant_email}
        </ConfirmationItem>
        <Divider />
        <ConfirmationItem label="Phone Number">
          {formState.sales_consultant_phone}
        </ConfirmationItem>
      </ConfirmationSection>
    )}
  </Box>
);

export const Confirmation = (): React.ReactElement => {
  const {
    goToStep,
    goNext,
    formState,
    formContext: { setValue },
  } = useStepUtils('confirmation');
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);

  const router = useRouter();

  const onSuccess = (type) => (res) => {
    if (type === 'bbys') {
      setValue('submit_response', res.data.data.attributes);

      toast('Success! A confirmation email has been sent to you.', {
        type: 'success',
        position: 'bottom-center',
      });

      goNext();
    } else if (type === 'all_cash_bbys') {
      setValue('submit_response', {
        success: true,
        type,
        ...res.data.data.attributes,
      });

      goNext();
    } else if (res?.status === 200) {
      setValue('submit_response', { success: true, type });

      goNext();
    }
  };

  const onError = (e) => {
    captureException(e);
    toast('There was an error submitting your response', { type: 'error' });
    setLoading(false);
  };

  const { isLoading: isSubmitLoading, mutate: submitLead } = useSubmitBbysLead({
    onSuccess: onSuccess('bbys'),
    onError: (e: AxiosError) => {
      captureException(e);

      const humanReadableErrors = e.response?.data?.errors?.filter((err) =>
        err.includes("can't be empty")
      );

      if (humanReadableErrors.length) {
        toast(humanReadableErrors.join('\n'), { type: 'error' });
      } else {
        toast('There was an error submitting your response', { type: 'error' });
      }

      setLoading(false);
    },
  });

  const { isLoading: isSubmitCashLoading, mutate: submitAllCashLead } =
    useSubmitCashOrderBbysLead({
      onSuccess: onSuccess('all_cash_bbys'),
      onError,
    });

  const { isLoading: isSubmitPreLeadLoading, mutate: submitPreLead } =
    useSubmitPreBbysLead({
      onSuccess: onSuccess('pre_lead'),
      onError,
    });

  const isLoading =
    isSubmitLoading || isSubmitCashLoading || isSubmitPreLeadLoading;

  const submit = () => {
    setLoading(true);

    if (['agentSubmission', 'externalLeadSubmitter'].includes(formState.flow)) {
      let source = 'web';
      let source_form = 'public_agent_lead_submission_page';

      if (formState.flow === 'externalLeadSubmitter') {
        source_form = 'public_external_lead_submission_page';
      }

      const params = {
        uuid: formState.snapshot_uuid,
        source: source,
        source_form: source_form,
        source_page_type: 'equity_app',
      };

      if (formState.finance_type === 'cash') {
        submitAllCashLead(params);
      } else {
        submitPreLead(params);
      }
    } else {
      const params = getSubmitParams({
        ...formState,
        partner_slug: router.query.partnerSlug as string,
      });

      submitLead(params);
    }
  };

  return (
    <>
      <Step
        title="Review and submit"
        loading={loading || isLoading}
        maxWidth="1080px"
        m={{ xs: '0 auto 80px', md: '0 auto' }}
      >
        <Box
          width="100%"
          gap={{ xs: '32px', md: '66px' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <ConfirmationData formState={formState} goToStep={goToStep} />
          <Box
            flexDirection="column"
            flex=".5"
            gap="6px"
            width="100%"
            display={{ xs: 'none', md: 'flex' }}
          >
            <Box gap="4px">
              <Paragraph variant="text">Notes for HomeLight</Paragraph>
              <Paragraph variant="text" color="gray600">
                (Optional)
              </Paragraph>
            </Box>
            <Inputs.TextArea
              name="notes"
              minHeight="175px"
              placeholder="E.g., “The property has three title holders”"
            />
          </Box>
        </Box>
      </Step>
      <FloatingFAQButton />
      <Footer key="step-footer" backButton>
        {!(isLoading || loading) && (
          <Box
            display={{ xs: 'flex', md: 'none' }}
            gap="16px"
            p="16px"
            bgcolor={theme.colors.gray50}
            justifyContent="space-between"
            borderRadius="12px"
            width="100%"
            onClick={() =>
              openModal(
                <MobileNoteModal
                  initialValue={formState.notes}
                  onClose={(val) => {
                    setValue('notes', val);
                    closeModal();
                  }}
                />,
                { asDrawer: true, hideCloseIcon: true }
              )
            }
          >
            <Box gap="16px">
              <Icon type="editDocument" color="black" />
              <Paragraph variant="text-bold">
                Add Note
                <span
                  style={{
                    color: theme.colors.gray500,
                    fontWeight: 'normal',
                    marginLeft: '6px',
                  }}
                >
                  (optional)
                </span>
              </Paragraph>
            </Box>
            <Icon type="angleRight" color="gray500" />
          </Box>
        )}
        <Button
          height="48px"
          disabled={loading || isLoading}
          onClick={submit}
          fontSize="16px"
          padding="0 24px !important"
          width={{ xs: '100%', md: 'auto' }}
          data-test="submit-button"
        >
          Submit
        </Button>
      </Footer>
    </>
  );
};
