/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useRouter } from 'next/router';

import {
  Box,
  MultiStep,
  Spinner,
  useMultiStepContext,
  useSnapshotContext,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import { FLOW_STEPS, Step } from './constants';
import { getStepInfo, getStepInfoByKey } from './constants';
import { ProgressBar } from './layout/ProgressBar';
import { StepComponentMap } from './steps';

const BbysLeadSubmissionStep = () => {
  const router = useRouter();

  const multiStep = useMultiStepContext();
  const { watch, setValue } = useFormContext();
  const formState = watch();

  const currentStepInfo = getStepInfo(
    multiStep.currentStep,
    formState.flow
  ) as Step;

  const SidebarContentComponent =
    StepComponentMap[currentStepInfo?.key]?.sidebar;

  const showSidebar =
    currentStepInfo && currentStepInfo?.showSidebar && SidebarContentComponent;

  const { snapshot, update } = useSnapshotContext();

  const snapshotLoaded = useMemo(
    () => Object.keys(snapshot).length,
    [snapshot]
  );

  useEffect(() => {
    if (
      formState.submit_response &&
      multiStep.currentStep !== 'Success' &&
      config.isProduction
    ) {
      multiStep.goToStep('Success');
    }
  }, [formState]);

  const initializeStep = (stepKey = 'lender_information') => {
    const step = getStepInfoByKey(stepKey, formState.flow) as Step;
    if (!step) return;

    router.query.step = step.title;
    multiStep.goToStep(step.title);

    router.push({
      pathname: router.pathname,
      query: router.query,
    });
  };

  const navigateStep = () => {
    const stepInfo = getStepInfo(router.query.step as string, formState.flow);

    if (stepInfo) {
      setValue('submitted_step', stepInfo.key);

      if (!stepInfo.skipUpdate) {
        update({
          ...snapshot,
          ...formState,
          submitted_step: stepInfo.key,
        });
      }
      multiStep.goToStep(router.query.step as string);
    }
  };

  useEffect(() => {
    // wait for snapshot
    if (!snapshotLoaded) return;

    if (!router.query.step) {
      initializeStep(snapshot.submitted_step || 'lender_information');
    } else if (multiStep.currentStep !== router.query.step) {
      navigateStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.step, snapshot]);

  const flowSteps = FLOW_STEPS[formState.flow];

  return (
    <Box
      flexDirection="column"
      overflowX="hidden"
      bgcolor={
        multiStep.isLastStep ? theme.colors.coolGray6 : theme.colors.white
      }
      flex="1"
      p={{ xs: '24px', md: multiStep.isLastStep ? '56px' : '80px' }}
      width="100%"
      minHeight={
        multiStep.isFirstStep
          ? '100vh'
          : `calc(100vh - ${multiStep.isLastStep ? '85px' : '52px'})`
      }
      position="relative"
      marginLeft={{
        xs: '0',
        md: showSidebar ? '380px' : '0px',
        lg: showSidebar ? '406px' : '0px',
      }}
      overflow="hidden"
      transition="all .2s ease-out"
    >
      <Box
        m="0 auto"
        mb={multiStep.isFirstStep || multiStep.isLastStep ? '0px' : '100px'}
        width="100%"
      >
        {snapshotLoaded && flowSteps?.length ? (
          <>
            {flowSteps.map(({ title, key }, idx) => {
              // we don't want to re-render the first step of the flow,
              // so the key for the first step will remain the same across flows
              const Component = StepComponentMap[key]?.component;

              return Component ? (
                <MultiStep.Step
                  key={`${idx === 0 ? idx : formState.flow}-${title}`}
                  name={title}
                >
                  <Component />
                </MultiStep.Step>
              ) : null;
            })}
            {currentStepInfo && currentStepInfo.showProgress && (
              <ProgressBar
                key="progress-bar"
                position="fixed"
                bottom="96px"
                left={{
                  md: currentStepInfo.showSidebar ? '380px' : '0px',
                  lg: currentStepInfo.showSidebar ? '406px' : '0px',
                }}
                width={{
                  md: currentStepInfo.showSidebar
                    ? 'calc(100% - 380px)'
                    : '100%',
                  lg: currentStepInfo.showSidebar
                    ? 'calc(100% - 406px)'
                    : '100%',
                }}
                progress={multiStep.percentProgress}
                progressColor="electricBlue"
                display={{ xs: 'none', md: 'flex' }}
              />
            )}
          </>
        ) : (
          <Spinner xl m="100px auto" />
        )}
      </Box>
    </Box>
  );
};

export default BbysLeadSubmissionStep;
