import AgentInformationSidebar from './AgentInformationSidebar';
import ClientFinancialInfoSidebar from './ClientFinancialInfoSidebar';
import ClientInformationSidebar from './ClientInformationSidebar';
import ConsultantInformationSidebar from './ConsultantInformationSidebar';
import DepartingResidenceInfoSidebar from './DepartingResidenceInfoSidebar';
import EquityUnlockEstimationSidebar from './EquityUnlockEstimationSidebar';
import LeadInformationSidebar from './LeadInformationSidebar';
import ProgramEligibilityCheckSidebar from './ProgramEligibilityCheckSidebar';

export default {
  AgentInformation: AgentInformationSidebar,
  ClientFinancialDetails: ClientFinancialInfoSidebar,
  ClientInformation: ClientInformationSidebar,
  ConsultantInformation: ConsultantInformationSidebar,
  DepartingResidenceInfo: DepartingResidenceInfoSidebar,
  EquityUnlockEstimation: EquityUnlockEstimationSidebar,
  LeadInformation: LeadInformationSidebar,
  ProgramEligibilityCheck: ProgramEligibilityCheckSidebar,
};
