import { useEffect } from 'react';

import { Box, Button, Inputs, Paragraph } from '@hl-portals/ui';

import { formatCurrency } from '@hl-portals/helpers';

import { useStepUtils } from '../../hooks';
import Divider from '../../layout/Divider';
import { FloatingFAQButton } from '../../layout/FloatingFAQButton';
import Footer from '../../layout/Footer';
import Step from '../../layout/Step';
import { AdditionalLiens } from '../InputSets';

export const CashPurchaseInfo = (): React.ReactElement => {
  const {
    validate,
    goNext,
    formContext: { setValue },
    formState,
    snapshot,
  } = useStepUtils('cash_purchase_info');

  const isValid = validate([
    'target_equity_unlock',
    'confirm_mortgage_balance',
    'additional_liens_exist',
  ]);

  useEffect(() => {
    setValue(
      'confirm_mortgage_balance',
      formatCurrency({
        value: formState?.equity_unlock_result?.estimated_loan_payoff || '',
        raw: true,
      }),
      { shouldValidate: false }
    );

    setValue(
      'mortgage_balance',
      formatCurrency({
        value: formState?.equity_unlock_result?.estimated_loan_payoff || '',
        raw: true,
      }),
      { shouldValidate: false }
    );
  }, [setValue, formState?.equity_unlock_result?.estimated_loan_payoff]);

  const flowSource =
    formState.flow === 'externalLeadSubmitter' ? 'NHC' : 'Agent';

  return (
    <>
      <Step title="Financial Information">
        <Box flexDirection="column" gap="32px">
          <Box gap="16px" flexDirection="column">
            <Paragraph variant="text-bold">Target equity amount</Paragraph>
            <Inputs.Currency
              name="target_equity_unlock"
              label={
                <Paragraph variant="text-small">
                  How much equity your client needs to close on their new
                  property?
                </Paragraph>
              }
              placeholder="Enter target amount"
              testId="target_equity_unlock-input"
            />
          </Box>
          <Divider />
          <Box gap="16px" flexDirection="column">
            <Paragraph variant="text-bold">Liens</Paragraph>
            <AdditionalLiens />
          </Box>
          <Divider />
          <Box flexDirection="column" gap="16px" width="100%">
            <Paragraph variant="text-bold">Mortgage Balance</Paragraph>
            <Box flexDirection="column" gap="8px" width="100%">
              <Inputs.Currency
                name="confirm_mortgage_balance"
                data-test="confirm_mortgage_balance-input"
                onBlur={(e) => {
                  setValue('mortgage_balance', e.target.value);
                }}
              />
              <Paragraph variant="text-xsmall" color="gray600">
                Value provided by{' '}
                {snapshot?.mortgage_balance !== ''
                  ? flowSource
                  : 'our data services'}
                .
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
