import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph, SlideDown } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { AdditionalTitleHolderModal } from './modal';

const PHONE_MASK = '(___) ___-____';

export const AddClientInformation = () => {
  const { logLeadSubmissionEvent, onUpdateSnapshot, coreEventData } =
    useLeadSubmissionContext();
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const [renderModal, setRenderModal] = useState(false);

  const {
    add_client,
    add_additional_clients_later,
    primary_client_name,
    primary_client_email,
    primary_client_phone,
    additional_client_name,
    additional_client_email,
    additional_client_phone,
    trust,
  } = watch();

  const buttonText = !add_client ? 'Add client' : 'Remove';

  // primary fields
  const areAllPrimaryFieldsFilled = [
    primary_client_name,
    primary_client_email,
    primary_client_phone,
  ].every(Boolean);

  const areAnyPrimaryFieldsEmpty = [
    !primary_client_name,
    !primary_client_email,
    !primary_client_phone,
  ].some(Boolean);

  // additional fields
  const areAllAdditionalFieldsEmpty = [
    !additional_client_name,
    !additional_client_email,
    !additional_client_phone,
  ].every(Boolean);

  const areAnyAdditionalFieldsEmpty = [
    !additional_client_name,
    !additional_client_email,
    !additional_client_phone,
  ].some(Boolean);

  const areAnyAdditionalFieldsFilled = [
    additional_client_name,
    additional_client_email,
    additional_client_phone,
  ].some(Boolean);

  const allAdditionalFieldsHasErrors = [
    errors?.additional_client_name?.message,
    errors?.additional_client_email?.message,
    errors?.additional_client_phone?.message,
  ].every(Boolean);

  const anyPhoneFieldsAreMasked = [
    primary_client_phone === PHONE_MASK,
    additional_client_phone === PHONE_MASK,
  ].some(Boolean);

  const formHasErrors = [
    errors?.primary_client_name?.message,
    errors?.primary_client_email?.message,
    errors?.primary_client_phone?.message,
    errors?.additional_client_name?.message,
    errors?.additional_client_email?.message,
    errors?.additional_client_phone?.message,
  ].some((errorMessage) => typeof errorMessage === 'string');

  const hasCoTitleHolder = trust?.owner_names.length >= 2;

  // Disable if any fields has errors or
  // if any primary fields are empty or
  // if any additional fields are filled but some are empty
  const disabled =
    formHasErrors ||
    areAnyPrimaryFieldsEmpty ||
    anyPhoneFieldsAreMasked ||
    (add_client && areAnyAdditionalFieldsEmpty && areAnyAdditionalFieldsFilled);

  const clearAdditionalClientFields = () => {
    clearErrors('additional_client_name');
    clearErrors('additional_client_email');
    clearErrors('additional_client_phone');
    setValue('additional_client_name', '');
    setValue('additional_client_email', '');
    setValue('additional_client_phone', '');
  };

  const goNext = () => {
    const payload = {
      add_additional_clients_later,
      primary_client_name,
      primary_client_email,
      primary_client_phone,
      additional_client_name,
      additional_client_email,
      additional_client_phone,
    };
    logLeadSubmissionEvent({
      event_action: 'add_client_information',
      event_payload: {
        ...coreEventData,
        ...payload,
      },
    });
    onUpdateSnapshot(payload);
  };

  const handleNext = () => {
    if (
      hasCoTitleHolder &&
      add_additional_clients_later === undefined &&
      areAnyAdditionalFieldsEmpty
    ) {
      setRenderModal(true);
      return;
    } else {
      goNext();
      return;
    }
  };

  const handleClick = () => {
    if (!add_client) {
      setValue('add_client', true);
    } else {
      clearAdditionalClientFields();
      setValue('add_client', false);
    }
  };

  useEffect(() => {
    if (renderModal && !isModalOpened) {
      openModal(
        <AdditionalTitleHolderModal
          onClose={() => {
            setValue('add_additional_clients_later', true);
            closeModal({ clearAll: true });
            if (areAllPrimaryFieldsFilled && !formHasErrors) {
              goNext();
            }
          }}
          onConfirm={() => {
            setValue('add_client', true);
            setValue('add_additional_clients_later', false);
            logLeadSubmissionEvent({
              event_action:
                'warning_modal_additional_title_holders_not_supplied',
              event_payload: {
                ...coreEventData,
                message: 'The property has additional title holders',
              },
            });
            closeModal({ clearAll: true });
          }}
        />,
        { hideCloseIcon: true, enableClose: false }
      );
    }
  }, [renderModal]);

  useEffect(() => {
    if (
      areAllAdditionalFieldsEmpty &&
      errors?.additional_client_name?.message
    ) {
      clearErrors('additional_client_name');
    }

    if (allAdditionalFieldsHasErrors) {
      clearErrors('additional_client_name');
      clearErrors('additional_client_email');
      clearErrors('additional_client_phone');
    }
  }, [
    areAllAdditionalFieldsEmpty,
    allAdditionalFieldsHasErrors,
    errors,
    clearErrors,
  ]);

  const mode =
    !additional_client_name &&
    (errors?.additional_client_email?.message ||
      errors?.additional_client_phone?.message);

  return (
    <>
      <Content>
        <Container
          title="Add client information"
          subtitle="This should include all title holders of the departing residence."
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Paragraph variant="heading-5">Client</Paragraph>
              <Inputs.Text
                name="primary_client_name"
                label="Full name"
                placeholder="Enter client’s full name"
                mode="onBlur"
              />
              <Inputs.Email
                name="primary_client_email"
                label="Email Address"
                placeholder="client1@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name="primary_client_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                mode="onBlur"
              />
              <Box flexDirection="column">
                <Box
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={{ xs: '24px', md: '40px' }}
                >
                  <Paragraph
                    variant="heading-5"
                    display="inline-block"
                    visibility={!add_client ? 'hidden' : 'visible'}
                  >
                    Client 2
                  </Paragraph>
                  <Paragraph
                    display="inline-block"
                    color="#1192E5"
                    cursor="pointer"
                    onClick={() => handleClick()}
                  >
                    {buttonText}
                  </Paragraph>
                </Box>
                <SlideDown
                  display={add_client ? 'block' : 'none'}
                  gap="32px"
                  mb={{ xs: '24px', md: '40px' }}
                >
                  <Box flexDirection="column" gap="32px">
                    <Inputs.Text
                      autofocus={add_client}
                      name="additional_client_name"
                      label="Full Name"
                      placeholder="Enter client’s full name"
                      mode={mode ? 'onBlur' : 'onChange'}
                    />
                    <Inputs.Email
                      name="additional_client_email"
                      label="Email Address"
                      placeholder="client2@example.com"
                      mode="onBlur"
                    />
                    <Inputs.Phone
                      name="additional_client_phone"
                      label="Phone Number"
                      placeholder="555-555-5555"
                      mode="onBlur"
                    />
                  </Box>
                </SlideDown>
              </Box>
            </Box>
          </Box>
          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            We are unable to issue a program agreement without contact
            information for all title holders.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disabled} />
      </Footer>
    </>
  );
};
