import {
  Box,
  Button,
  Icon,
  Paragraph,
  RadioTabs,
  SimpleLink,
  useBrandTheme,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useModal } from '@hl-portals/hooks';

import { useStepUtils } from '../../hooks';
import { FloatingFAQButton } from '../../layout/FloatingFAQButton';
import Footer from '../../layout/Footer';
import Step from '../../layout/Step';
import { LenderInformationInputs } from '../InputSets';
import { RolesModal } from './RolesModal';

const YES_NO_OPTIONS = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const PreferredLender = (): React.ReactElement => {
  const {
    formState,
    validate,
    formContext: { setValue },
    goNext,
  } = useStepUtils('agent_preferred_lender');
  const brandTheme = useBrandTheme();

  const { openModal } = useModal();

  const isValid = validate(
    formState.agent_lender_present === 'yes'
      ? [
          'lender_company_name',
          'loan_officer_name',
          'loan_officer_email',
          'loan_officer_phone',
        ]
      : ['agent_lender_present']
  );

  const showModal = () => {
    openModal(<RolesModal />, {
      wrapperClassName: 'roles-modal__wrapper',
      asDrawer: true,
    });
  };

  const onChangeOption = (option) => {
    setValue('lender_company_name', null);
    setValue('loan_officer_name', null);
    setValue('loan_officer_email', null);
    setValue('loan_officer_phone', null);

    setValue('agent_lender_present', option);
  };

  return (
    <>
      <Step
        title="Loan Officer Info"
        description="Do you have a preferred loan officer you would like to work with?"
      >
        <Box gap="16px" flexDirection="column" width="100%">
          <RadioTabs
            name="agent_lender_present"
            onChange={onChangeOption}
            tabs={YES_NO_OPTIONS}
            selected={formState.agent_lender_present}
          />
          {formState.agent_lender_present === 'no' && (
            <Box
              bgcolor={theme.colors.gray50}
              p="20px"
              gap="16px"
              width="100%"
              alignItems="flex-start"
              borderRadius="12px"
            >
              <Box
                width="56px"
                height="56px"
                minWidth="56px"
                minHeight="56px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bgcolor={theme.colors.white}
              >
                <Icon
                  type="handshakeAngled"
                  size={28}
                  color={brandTheme?.colors?.primary || 'orange500'}
                  mt="4px"
                />
              </Box>
              <Box flexDirection="column" gap="8px">
                <Paragraph variant="text-bold">
                  We&apos;ve got you covered
                </Paragraph>
                <Paragraph variant="text">
                  We&apos;ll guide you and your client through the program and,
                  if desired, connect you with a lender expert in Buy Before You
                  Sell financing
                </Paragraph>
              </Box>
            </Box>
          )}
          {formState.agent_lender_present === 'yes' && (
            <Box flexDirection="column" gap="32px">
              <LenderInformationInputs />
            </Box>
          )}
          <SimpleLink
            color={theme.colors.aaBlue}
            display="flex"
            onClick={showModal}
          >
            Why do I need a loan officer?
            <Icon type="info" fill="aaBlue" ml="4px" />
          </SimpleLink>
        </Box>
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
        progressColor={'electricBlue'}
      >
        <Button
          height="48px"
          disabled={!isValid}
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          tooltipProps={{ width: '100%' }}
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
