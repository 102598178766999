import { CurrentFlow } from '../../metadata/types';

export function getSourceForm(currentFlow: CurrentFlow, partnerSlug?: string) {
  if (currentFlow === 'agent' && partnerSlug === 'lennar') {
    return 'public_external_lead_submitter_page';
  }
  if (currentFlow === 'agent') {
    return 'public_agent_lead_submission_page';
  }
  return 'public_lead_submission_page';
}
