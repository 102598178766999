import { Box, Button, Paragraph } from '@hl-portals/ui';

const MinusCircle = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C17.5242 2 22 6.47581 22 12C22 17.5242 17.5242 22 12 22C6.47581 22 2 17.5242 2 12C2 6.47581 6.47581 2 12 2Z"
      fill="#72757D"
    />
    <path
      d="M17.8064 13.129C17.8064 13.3951 17.5887 13.6128 17.3225 13.6128H6.67736C6.41123 13.6128 6.19349 13.3951 6.19349 13.129V10.8709C6.19349 10.6048 6.41123 10.387 6.67736 10.387H17.3225C17.5887 10.387 17.8064 10.6048 17.8064 10.8709V13.129Z"
      fill="white"
    />
  </svg>
);

type EquityCalculationModalProps = {
  issues: { title?: string; description: string }[];
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
};

export const EquityCalculationModal = ({
  issues,
  onClose,
  onConfirm,
  isLoading,
}: EquityCalculationModalProps) => {
  return (
    <Box
      maxWidth={{ xs: '100%', md: '520px' }}
      p="8px 16px"
      flexDirection="column"
    >
      <Box
        pb="16px"
        alignItems="center"
        gap="12px"
        borderBottom="1px solid #DBDFE6"
      >
        <Box
          width="40px"
          height="40px"
          justifyContent="center"
          alignItems="center"
          borderRadius="4px"
          bgcolor="#F5F6F9"
        >
          <MinusCircle />
        </Box>
        <Paragraph variant="heading-4">Equity Unlock Loan is limited</Paragraph>
      </Box>

      <Box p="24px 4px" flexDirection="column" gap="24px">
        <Paragraph>
          The following factors will affect the final equity unlock loan amount
        </Paragraph>

        <Box
          maxHeight="300px"
          overflowY="auto"
          flexDirection="column"
          gap="24px"
        >
          {issues.map(({ title, description }) => (
            <Box key={title}>
              <Box
                flex="0 0 4px"
                borderRadius="8px 0px 0px 8px"
                bgcolor="#D9D9D9"
              />
              <Box p="12px" flexDirection="column" gap="4px" bgcolor="#F8F8FB">
                {title && <Paragraph fontWeight="600">{title}</Paragraph>}
                <Paragraph>{description}</Paragraph>
              </Box>
            </Box>
          ))}
        </Box>

        <Paragraph>
          The final equity calculation will be determined after our team reviews
          the application.
        </Paragraph>

        <Box gap="16px" justifyContent="flex-end">
          <Button color="tertiary" size="large" onClick={onClose}>
            Cancel submission
          </Button>
          <Button size="large" onClick={onConfirm} isLoading={isLoading}>
            Got it
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
