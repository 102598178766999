/* eslint-disable react/no-unescaped-entities */
import { useMemo } from 'react';

import {
  Box,
  FadeIn,
  Paragraph,
  SuccessIcon,
  TransformedBox,
} from '@hl-portals/ui';

import { useStepUtils } from '../../hooks';
import { FloatingFAQButton } from '../../layout/FloatingFAQButton';
import ApplicationSummary from './ApplicationSummary';
import NextSteps from './NextSteps';

export const GlobalSuccess = (): React.ReactElement => {
  const { formState } = useStepUtils('success');

  const clientFirstName = formState.primary_client_name?.split(' ')[0];

  const type = useMemo(() => {
    if (['agentSubmission', 'externalLeadSubmitter'].includes(formState.flow)) {
      switch (formState.finance_type) {
        case 'unsure': // fall through
        case 'mortgage':
          return 'mortgage';
        case 'cash':
          return 'cash';
        default: // fall through
      }
    }

    if (formState.submit_response?.instant_approval_success) return 'instant';
    if (formState.submit_response?.light_approval_success) return 'light';

    return 'express';
  }, [formState]);

  const confirmationEmail = () => {
    if (formState.flow === 'agentSubmission') {
      return formState.agent_email;
    } else if (formState.flow === 'externalLeadSubmitter') {
      return formState.sales_consultant_email;
    } else {
      return formState.loan_officer_email;
    }
  };

  return (
    <>
      <FloatingFAQButton />
      <FadeIn
        flexDirection="column"
        maxWidth="1280px"
        margin="0 auto"
        gap="56px"
        alignItems="center"
        animationDelay="0s"
      >
        <Box
          flexDirection="column"
          gap="32px"
          alignItems="center"
          position="relative"
        >
          <SuccessIcon />
          <Box flexDirection="column" gap="16px">
            <Paragraph variant="heading-2" textAlign="center">
              Application Received
            </Paragraph>
            <Paragraph variant="text" textAlign="center">
              We sent a confirmation email to{' '}
              <strong>{confirmationEmail()}</strong>
            </Paragraph>
          </Box>
        </Box>
        <Box flexDirection="column" gap="32px" width="100%">
          <NextSteps type={type} />
          <ApplicationSummary />
        </Box>
        {type === 'instant' && (
          <>
            <TransformedBox
              transform="rotate(20deg)"
              as="img"
              position="absolute"
              top="-100px"
              right="-275px"
              pointerEvents="none"
              src="/illustrations/bbys-lead-submission/confetti.png"
              display={{ xs: 'none', md: 'block' }}
            />
            <Box
              as="img"
              position="absolute"
              top="-100px"
              left="-325px"
              pointerEvents="none"
              src="/illustrations/bbys-lead-submission/confetti.png"
              display={{ xs: 'none', md: 'block' }}
            />
          </>
        )}
      </FadeIn>
    </>
  );
};
