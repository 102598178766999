import { z } from 'zod';

import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Button, Inputs, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { isFullName, isValidEmail, isValidPhone } from '@hl-portals/helpers';

import { useDropoutsCheck } from '@hl-portals/hooks';

import { ERRORS } from '../helpers';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Step from '../layout/Step';
import { LennarInformationInputs } from './InputSets';

export const lenderInformationSchema = {
  lender_company_name: z.string({
    required_error: ERRORS.required('company name'),
  }),
  loan_officer_name: z
    .string({
      required_error: ERRORS.required('name'),
    })
    .refine((value) => isFullName(value), {
      message: ERRORS.required('full name'),
    }),
  loan_officer_email: z
    .string({
      required_error: ERRORS.required('email'),
    })
    .refine((value) => isValidEmail(value), ERRORS.valid('email')),
  loan_officer_phone: z
    .string({
      required_error: ERRORS.required('phone number'),
    })
    .refine((value) => isValidPhone(value), {
      message: ERRORS.valid('phone number'),
    }),
};

const LOCAL_STORAGE_LO_KEY = 'hl-bbys-lender-info-history';

export const LenderInformation = (): React.ReactElement => {
  const {
    goNext,
    validate,
    formState,
    formContext: { setValue },
  } = useStepUtils('lender_information');

  const router = useRouter();
  const { postDropoutCheck } = useDropoutsCheck(() => {
    setValue('dropout_check', true);
  });

  const isValid = validate(
    [
      'lender_company_name',
      'loan_officer_name',
      'loan_officer_email',
      'loan_officer_phone',
      router.query.partnerSlug === 'lennar' ? 'division' : '',
    ].filter(Boolean)
  );

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_LO_KEY)) {
      const info = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LO_KEY));
      setValue('lender_company_name', info.lender_company_name);
      setValue('loan_officer_name', info.loan_officer_name);
      setValue('loan_officer_email', info.loan_officer_email);
      setValue('loan_officer_phone', info.loan_officer_phone);
      setValue('remember_lo_info', true);
    }
  }, []);

  const startApplication = () => {
    if (formState.remember_lo_info) {
      localStorage.setItem(
        LOCAL_STORAGE_LO_KEY,
        JSON.stringify({
          lender_company_name: formState.lender_company_name,
          loan_officer_name: formState.loan_officer_name,
          loan_officer_email: formState.loan_officer_email,
          loan_officer_phone: formState.loan_officer_phone,
        })
      );
    } else {
      localStorage.removeItem(LOCAL_STORAGE_LO_KEY);
    }

    if (!formState.dropout_check) {
      postDropoutCheck(router.query.uuid);
    }

    goNext();
  };

  return (
    <>
      <Step
        title="First, tell us about you"
        description="We'll need your contact information to send notifications and transaction documents."
      >
        {router.query.partnerSlug === 'lennar' ? (
          <LennarInformationInputs />
        ) : (
          <>
            <Inputs.Text
              mode="onBlur"
              name="lender_company_name"
              label="Lender (Company Name)"
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter your company name"
              borderColor={theme.colors.coolGray4}
              data-test="lender_company_name-input"
            />

            <Inputs.Text
              mode="onBlur"
              name="loan_officer_name"
              label="Loan Officer Full Name"
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter your full name"
              borderColor={theme.colors.coolGray4}
              data-test="loan_officer_name-input"
            />

            <Inputs.Email
              mode="onBlur"
              name="loan_officer_email"
              label="Loan Officer Email Address"
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter your email address"
              borderColor={theme.colors.coolGray4}
              data-test="loan_officer_email-input"
            />

            <Inputs.Phone
              mode="onBlur"
              name="loan_officer_phone"
              label="Loan Officer Phone Number"
              containerProps={{ flex: '1', width: '100%' }}
              placeholder="Enter your phone number"
              borderColor={theme.colors.coolGray4}
              data-test="loan_officer_phone-input"
            />

            <Inputs.Checkbox name="remember_lo_info">
              <Paragraph variant="text" textAlign="left">
                Save my information for future use
              </Paragraph>
            </Inputs.Checkbox>
          </>
        )}

        <Button
          height="48px"
          disabled={!isValid}
          onClick={startApplication}
          tooltipProps={{ width: '100%' }}
          fontSize="16px"
          padding="0 24px !important"
          tooltip={isValid ? '' : 'Complete the required fields to continue'}
          width={{ xs: '100%', md: 'auto' }}
          data-test="start-application-button"
        >
          Start Application
        </Button>
      </Step>
      <FloatingFAQButton />
    </>
  );
};
