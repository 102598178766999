import { InputGroup, Inputs, Paragraph, Select } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { LENNAR_DIVISIONS } from '../../constants';
import { useStepUtils } from '../../hooks';

export const LennarInformationInputs = () => {
  const {
    formState,
    formContext: { setValue },
  } = useStepUtils();

  return (
    <>
      <Inputs.Text
        mode="onBlur"
        name="lender_company_name"
        label="Lender (Company Name)"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="Enter your company name"
        borderColor={theme.colors.coolGray4}
        data-test="lender_company_name-input"
      />
      <Inputs.Text
        mode="onBlur"
        name="loan_officer_name"
        label="Loan Officer Full Name"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="Enter your full name"
        borderColor={theme.colors.coolGray4}
        data-test="loan_officer_name-input"
      />
      <Inputs.Email
        mode="onBlur"
        name="loan_officer_email"
        label="Loan Officer Email Address"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="Enter your email address"
        borderColor={theme.colors.coolGray4}
        data-test="loan_officer_email-input"
      />
      <Inputs.Phone
        mode="onBlur"
        name="loan_officer_phone"
        label="Loan Officer Phone Number"
        containerProps={{ flex: '1', width: '100%' }}
        placeholder="Enter your phone number"
        borderColor={theme.colors.coolGray4}
        data-test="loan_officer_phone-input"
      />

      <InputGroup label="Division" width="100%">
        <Select
          width="100%"
          customSelectedItemCss={{
            minWidth: '100% !important',
            borderRadius: '6px !important',
            border: '1px solid #DBDFE6 !important',
          }}
          onChange={(value) => setValue('division', value)}
          selectedValue={formState.division}
          height="48px"
          variant={{ xs: 'top', md: 'bottom' }}
          options={LENNAR_DIVISIONS.map((option) => ({
            value: option,
            text: option,
          }))}
          placeholder="Select Division"
          data-test="division-select"
        />
      </InputGroup>

      <Inputs.Checkbox name="remember_info">
        <Paragraph variant="text" textAlign="left">
          Save my information for future use
        </Paragraph>
      </Inputs.Checkbox>
    </>
  );
};
