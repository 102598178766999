import {
  AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_BLOCKERS_LABELS_STRING as BLOCKERS_LABELS,
  AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS as CHECKS_LABELS,
  AUTOMATED_BBYS_PROPERTY_WARNING_CHECKS_LABELS as WARNING_LABELS,
} from '@hl-portals/hooks';

import { FormData } from '../../metadata/forms';

export function getCalculationIssuesLabels(formData: FormData) {
  const issues =
    formData.property_eligibility_issues?.map((issue) => ({
      description: CHECKS_LABELS[issue],
    })) || [];

  const blockers =
    formData.property_eligibility_blockers?.map((blocker) => {
      const [title, description] = BLOCKERS_LABELS[blocker];
      return { title, description };
    }) || [];

  const warnings =
    formData.property_eligibility_warnings?.map((warning) => {
      const [title, description] = WARNING_LABELS[warning];
      return { title, description };
    }) || [];

  return [...issues, ...blockers, ...warnings];
}
